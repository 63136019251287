import { useEffect, useMemo, useState } from "react";

import { MenuItem, Typography, styled } from "@mui/material";
import { isEqual } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";

import { AddButton } from "components/scenarios/MappingEditor/components/AddButton";
import { ColumnSelector } from "components/scenarios/MappingEditor/components/ColumnSelector";
import { Expander } from "components/scenarios/MappingEditor/components/Expander";
import { SectionErrorMessage } from "components/scenarios/MappingEditor/components/SectionErrorMessage";
import { SectionWrap } from "components/scenarios/MappingEditor/components/SectionWrap";
import { DeleteButton } from "components/ui/DeleteButton";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { SelectorInput } from "components/ui/form/SelectorInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { useExpanded } from "hooks/useExpanded";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledItemWrapper = styled("div")(({ theme }) => ({
  alignItems: "flex-start",
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  height: "auto",
  marginLeft: "10px",
  maxHeight: "200px",
  minHeight: "50px",
  overflow: "auto",
  overflowX: "hidden",
  paddingTop: "10px",
  width: "100%",
  transition: "max-height 400ms ease",

  "&.expanded": {
    maxHeight: "600px",
  },

  "& .divider": {
    display: "flex",
    placeItems: "center",
    minWidth: "25px",
    paddingBottom: "13px",
    fontStyle: "italic",
    color: theme.palette.scenarioThumbnail.main,
  },

  "& .singleItemWrapperTextField": {
    "& fieldset": {
      height: "100%",
      marginBottom: "0",
    },
    "& input": {
      height: "56px",
    },
    "& .MuiFormHelperText-contained": {
      marginTop: "0px",
    },
    "& .MuiInputBase-root": {
      width: "200px",
    },
  },
}));

const numerics = ["<", "<=", ">", ">="];

const FilteringItemRow = ({
  dataKey,
  index,
  item,
  namePrefix,
  onDelete,
  showDivider,
  emptyOpSupport,
  numericOpSupport,
}) => {
  const strings = useLocalizedStrings();

  const filteringOperators = useMemo(() => {
    const baseOperators = [
      {
        label:
          strings.sourcedialog_mapping_editor_filtering_tab_filtering_operators_equals,
        op: "=",
      },
      {
        label:
          strings.sourcedialog_mapping_editor_filtering_tab_filtering_operators_not_equal,
        op: "!=",
      },
      {
        label:
          strings.sourcedialog_mapping_editor_filtering_tab_filtering_operators_contains,
        op: "in",
      },
      {
        label:
          strings.sourcedialog_mapping_editor_filtering_tab_filtering_operators_not_contains,
        op: "!in",
      },
      {
        label:
          strings.sourcedialog_mapping_editor_filtering_tab_filtering_operators_one_of,
        op: "oneof",
      },
      {
        label:
          strings.sourcedialog_mapping_editor_filtering_tab_filtering_operators_not_one_of,
        op: "!oneof",
      },
    ];
    const emptyOperators = [
      {
        label:
          strings.sourcedialog_mapping_editor_filtering_tab_filtering_operators_empty,
        op: "empty",
      },
      {
        label:
          strings.sourcedialog_mapping_editor_filtering_tab_filtering_operators_not_empty,
        op: "!empty",
      },
    ];
    const numericOperators = numerics.map((op) => ({
      label: op,
      op,
    }));

    if (emptyOpSupport) {
      baseOperators.push(...emptyOperators);
    }
    if (numericOpSupport) {
      baseOperators.push(...numericOperators);
    }

    return baseOperators;
  }, [strings, emptyOpSupport, numericOpSupport]);

  const filteringOperatorValues = useMemo(
    () => filteringOperators.map((item) => item.op),
    [filteringOperators]
  );

  const { setValue, watch } = useFormContext();

  const watchedOperator = watch(
    `${namePrefix}.${dataKey}.and_rules.${index}.op`
  );

  useEffect(() => {
    if (watchedOperator === "empty" || watchedOperator === "!empty") {
      setValue(`${namePrefix}.${dataKey}.and_rules.${index}.value`, "");
    }
  }, [dataKey, index, namePrefix, setValue, watchedOperator]);

  return (
    <div
      style={{
        display: "flex",
        paddingLeft: "20px",
        justifyItems: "end",
        gap: "1em",
      }}
    >
      <span className="divider">
        {showDivider && <Typography>and</Typography>}
      </span>
      <ColumnSelector
        name={`${namePrefix}.${dataKey}.and_rules.${index}.input_field`}
        defaultValue={item.input_field || ""}
      />
      <SelectorInput
        defaultValue={item["op"] || ""}
        name={`${namePrefix}.${dataKey}.and_rules.${index}.op`}
        style={{
          height: "51px",
          width: "200px",
        }}
        label={strings.sourcedialog_mapping_editor_filtering_tab_label_operator}
        rules={{
          required: true,
          validate: (value) =>
            filteringOperatorValues.includes(value) ||
            strings.sourcedialog_mapping_editor_filtering_tab_operator_error_message,
        }}
      >
        {filteringOperators.map((operator) => {
          return (
            <MenuItem key={operator.op} value={operator.op}>
              <span>{operator.label}</span>
            </MenuItem>
          );
        })}
      </SelectorInput>
      {watchedOperator !== "empty" && watchedOperator !== "!empty" && (
        <TextFieldInput
          defaultValue={item["value"] || ""}
          name={`${namePrefix}.${dataKey}.and_rules.${index}.value`}
          required
          rules={{
            pattern: {
              value: numerics.includes(watchedOperator)
                ? /^-?(0|[1-9]\d*)(\.\d+)?$/
                : /(?!\s*$).+/, // regex doesn't allow value to be empty or whitespace values ('  ')
              message: strings.invalid_input,
            },
          }}
          className="singleItemWrapperTextField"
          label={strings.sourcedialog_mapping_editor_filtering_tab_label_value}
          style={{ minWidth: "156px" }}
        />
      )}

      <DeleteButton
        style={{ height: "50px" }}
        onClick={() => onDelete(index)}
      />
    </div>
  );
};

const FilteringTabItemComponent = ({
  dataKey,
  onDelete,
  emptyOpSupport,
  numericOpSupport,
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const strings = useLocalizedStrings();

  const {
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();
  const namePrefix = "options.internal_config.filtering.entries";
  const fieldArrayName = `${namePrefix}.${dataKey}.and_rules`;

  const { fields, append, remove } = useFieldArray({
    name: fieldArrayName,
  });

  useEffect(() => {
    if (fields?.length === 0) {
      setError(`${namePrefix}.${dataKey}`, {
        customRule: {
          type: "custom",
          message: "Each filter must have at least one valid condition!",
        },
      });
      return;
    }
    if (
      !!errors?.options?.internal_config?.filtering?.["entries"]?.[dataKey]?.[
        "customRule"
      ]
    ) {
      clearErrors([`${namePrefix}.${dataKey}`]);
    }
  }, [setError, clearErrors, fields?.length, errors, dataKey]);

  const { expandedState, isExpandingDisabled, handleExpand } = useExpanded(
    fields?.length
  );

  const defaultAppendValues = { input_field: "", op: "=", value: "" };

  const handleConfirmationAction = () => {
    remove(deleteIndex);
  };

  const handleDelete = (index) => {
    const values = getValues(`${fieldArrayName}.${index}`);

    if (isEqual(values, defaultAppendValues)) {
      remove(index);
      return;
    }

    setConfirmDeleteOpen(true);
    setDeleteIndex(index);
  };

  const errs =
    !!errors?.options?.internal_config?.filtering?.["entries"]?.[dataKey];

  const checkForErrorClasses = () => {
    if (!!errs || !!errs?.and_rules?.root) {
      return " customError";
    }
    if (!!errs?.and_rules) {
      return " errorSection";
    }
    return "";
  };

  return (
    <>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <DeleteButton onClick={() => onDelete(dataKey)} />
        <SectionWrap className={checkForErrorClasses()}>
          <StyledItemWrapper className={expandedState ? "expanded" : ""}>
            {fields.map((item, index) => {
              const showDivider = fields.length > 1 && index !== 0;
              return (
                <FilteringItemRow
                  dataKey={dataKey}
                  key={item.id}
                  index={index}
                  item={item}
                  namePrefix={namePrefix}
                  onDelete={handleDelete}
                  emptyOpSupport={emptyOpSupport}
                  numericOpSupport={numericOpSupport}
                  showDivider={showDivider}
                />
              );
            })}
            <div
              style={{
                bottom: 0,
                left: 12,
                position: "absolute",
              }}
            >
              <AddButton onClick={() => append(defaultAppendValues)} />
            </div>
          </StyledItemWrapper>

          <Expander
            isExpandingDisabled={isExpandingDisabled}
            expandedState={expandedState}
            handleExpand={handleExpand}
          />
        </SectionWrap>

        <MultipleOptionsDialog
          onConfirm={handleConfirmationAction}
          open={confirmDeleteOpen}
          setOpen={setConfirmDeleteOpen}
          text={
            strings.sourcedialog_mapping_editor_filtering_tab_delete_item_question
          }
          title={
            strings.sourcedialog_mapping_editor_filtering_tab_delete_item_title
          }
        />
      </div>
      {!!errors?.options?.internal_config?.filtering?.["entries"]?.[dataKey]
        ?.customRule && (
        <SectionErrorMessage>
          {
            errors?.options?.internal_config?.filtering?.["entries"]?.[dataKey]
              ?.customRule?.message
          }
        </SectionErrorMessage>
      )}
    </>
  );
};

export default FilteringTabItemComponent;
