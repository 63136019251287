import { omit } from "lodash-es";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

export const useOutputFieldsStore = create(
  subscribeWithSelector((set) => ({
    outputFields: {},
    duplicateOutputFields: [],

    clearOutputFields: () => set({ outputField: {} }),
    removeOutputField: (name) =>
      set((state) => {
        const newOutputFields = omit(state.outputFields, name);
        return { outputFields: newOutputFields };
      }),
    setOutputFields: (outputField, outputValue) =>
      set((state) => ({
        outputFields: { ...state.outputFields, [outputField]: outputValue },
      })),
  }))
);
