import { useMessagesStore } from "stores/useMessagesStore";

export const useMessages = () => {
  const pushMessage = useMessagesStore((state) => state.pushMessage);
  const messages = useMessagesStore((state) => state.messages);
  const deleteMessage = useMessagesStore((state) => state.deleteMessage);

  return {
    pushMessage,
    messages,
    deleteMessage,
  };
};
