import { useCallback, useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";

import { useVersionQuery } from "api/metadata";
import { queryFetch } from "api/query";
import SchedulerAPI from "api/scheduler";

import { BackendEventsContext } from "contexts/BackendEventsContext";
import { BrowserNotificationsContext } from "contexts/BrowserNotificationsContext";

import { useJobStore } from "stores/useJobStore";

import { useCurrentUserSettings } from "./currentUserSettings";
import { useDeletedScenario } from "./useDeletedScenario";
import { useJobPolling } from "./useJobPolling";
import { useMaintenanceMode } from "./useMaintenanceMode";
import { useMessages } from "./useMessage";

function useAllJobStates() {
  const { socket } = useContext(BackendEventsContext);
  const { pushNotification } = useContext(BrowserNotificationsContext);
  const { pushMessage } = useMessages();
  const { isDeletedScenario } = useDeletedScenario();
  const setScenarioJobs = useJobStore((state) => state.setScenarioJobs);
  const history = useHistory();

  useJobPolling();

  const listener = useCallback(
    async ({ scenario, success, scenario_name, state, run_id }) => {
      if (!scenario) {
        return;
      }

      try {
        if (isDeletedScenario(scenario)) {
          return;
        }

        const rows = await queryFetch(SchedulerAPI.getJobStatusUrl(scenario));

        if (rows?.auth_url) {
          history.push("/?expired=1");
          return;
        }

        const scenarioJobs = rows;

        setScenarioJobs(scenario, scenarioJobs);

        const isDataAnalysis = scenarioJobs.filter(
          (job) => job.run_id === run_id && job.type === "data_analysis"
        ).length;

        const scenarioName = scenario_name.toUpperCase();
        if (success === false) {
          pushMessage("error", `Job for scenario ${scenarioName} failed`);
          return;
        }

        if (state === "start") {
          pushNotification(
            `Analysis job for scenario '${scenarioName}' has started at ${new Date().toLocaleString()}.`
          );
          if (isDataAnalysis) {
            pushMessage(
              "success",
              `STARTED: Data analysis for ${scenarioName}`
            );
          }
        }
        if (state === "end") {
          pushNotification(
            `Analysis job for scenario '${scenarioName}' has ended at ${new Date().toLocaleString()}.`
          );
          if (isDataAnalysis) {
            pushMessage(
              "success",
              `COMPLETED: Data analysis for ${scenarioName}`
            );
          }
        }
      } catch {}
    },
    [isDeletedScenario, setScenarioJobs, history, pushMessage, pushNotification]
  );

  useEffect(() => {
    socket?.on("jobs", listener);
    return () => socket?.off("jobs", listener);
  }, [listener, socket]);
}

function clearFiltersAndSorts() {
  for (const k in localStorage) {
    const n = k?.toLowerCase();
    if (n.endsWith(".orderby") || n.endsWith(".filters")) {
      localStorage.removeItem(k);
    }
  }
}

function useVerionChanges() {
  const [version, setVersion] = useCurrentUserSettings("version", null);
  const { pushNotification } = useContext(BrowserNotificationsContext);
  const { maintenanceModeFinished } = useMaintenanceMode();
  const { data } = useVersionQuery({ refetchInterval: 1000 * 60 * 30 });

  useEffect(() => {
    if (maintenanceModeFinished === "true") {
      location.reload();
    }
  }, [maintenanceModeFinished]);

  useEffect(() => {
    const v = data?.long_version;
    if (v && v !== version) {
      setVersion(v);
      pushNotification(`TrackerIQ version is now '${v}'!`);
      clearFiltersAndSorts();
      location.reload();
    }
  }, [pushNotification, setVersion, version, data?.long_version]);
}

export const BrowserNotificationCenter = ({ children }) => {
  useAllJobStates();
  useVerionChanges();

  return <>{children}</>;
};
