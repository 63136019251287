import { Suspense, useCallback, useState } from "react";

import { Box, Divider } from "@mui/material";

import ErrorBoundary from "components/ErrorBoundary";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";

import { MostRelevantActions } from "./RelevantActionsTable";
import { StoryTimeline } from "./StoryTimeline";
import { UserInfoBox } from "./UserInfoBox";
import { UserSessionHistory } from "./UserSessionHistory";

const useSelectedAction = () => {
  const [selectedActions, setSelectedActions] = useState(new Set());

  const handleToggleAction = useCallback((action) => {
    setSelectedActions((prevActions) => {
      const newActions = new Set(prevActions);
      if (newActions.has(action)) {
        newActions.delete(action);
      } else {
        newActions.add(action);
      }
      return newActions;
    });
  }, []);

  return { selectedActions, handleToggleAction };
};

const SessionStoryContent = ({ scenario, session }) => {
  const { selectedActions, handleToggleAction } = useSelectedAction();

  return (
    <Box sx={{ display: "flex" }}>
      <UserInfoBox />
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", height: "185px", width: "100%" }}>
          <MostRelevantActions
            selectedActions={selectedActions}
            handleToggleAction={handleToggleAction}
            session={session}
            scenario={scenario}
          />
          <UserSessionHistory scenario={scenario} />
        </Box>
        <Divider sx={{ pt: 1 }} textAlign="left">
          {"Journey Details"}
        </Divider>
        <Box
          sx={{
            height: "calc(100vh - 380px)",
            overflowY: "auto",
          }}
        >
          <StoryTimeline
            scenario={scenario}
            session={session}
            selectedActions={selectedActions}
          />
        </Box>
      </Box>
    </Box>
  );
};

const StoryPageSpinner = () => (
  <Box sx={{ width: "100%", height: "50vh" }}>
    <PageLoadingSpinner />
  </Box>
);

export const SessionStoryPage = ({ scenario, session }) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<StoryPageSpinner />}>
        <SessionStoryContent scenario={scenario} session={session} />
      </Suspense>
    </ErrorBoundary>
  );
};
