import { forwardRef } from "react";

import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import { IconButton } from "@mui/material";
import { Badge } from "@mui/material";
import { useHistory } from "react-router-dom";

import { useDiagnosticsGetMessagesForUrlQuery } from "api/diagnostics";

const ScenarioNewMessagesButton = forwardRef((props, ref) => {
  const { scenarioId, inView, ...rest } = props;
  const { push } = useHistory();
  const defaultMessageCount = 100;
  const { data: messages } = useDiagnosticsGetMessagesForUrlQuery(
    {
      scenarioId,
      count: defaultMessageCount,
    },
    {
      enabled: !!scenarioId && inView,
    }
  );

  const handleClick = () => {
    push("/settings/collectors/diagnostics");
  };

  return (
    <IconButton ref={ref} size="small" onClick={handleClick} {...rest}>
      <Badge
        badgeContent={messages?.length}
        color="error"
        overlap="circular"
        max={100}
      >
        <FeedbackOutlinedIcon style={{ fontSize: "1.4rem" }} />
      </Badge>
    </IconButton>
  );
});

export default ScenarioNewMessagesButton;
