import { useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton, InputAdornment } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { TextFieldInput } from "./TextFieldInput";

export const SecretOptionsInput = (props) => {
  const [showSecret, setShowSecret] = useState(false);
  const { setValue, watch } = useFormContext();

  const fieldValue = watch(props.name);

  return (
    <TextFieldInput
      autoComplete="off"
      {...props}
      sx={{
        "& .MuiInputBase-input": {
          WebkitTextSecurity: showSecret ? "none" : "disc",
        },
      }}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end" style={{ height: "0.01em" }}>
              {fieldValue && (
                <>
                  <IconButton
                    aria-label="reset secret"
                    onClick={() =>
                      setValue(props.name, null, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                    edge="end"
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>

                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowSecret((s) => !s)}
                    edge="end"
                    size="large"
                  >
                    {showSecret ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </>
              )}
            </InputAdornment>
          ),
        },
      }}
    />
  );
};
