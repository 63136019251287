import { styled } from "@mui/material";

const StyledTabHeader = styled("div")`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  font-family: inherit;
  .tab-title {
    font-size: 2rem;
    text-transform: capitalize;
  }
  .collector-name {
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.4;
  }
  .collector-id {
    letter-spacing: 2px;
    opacity: 0.4;
  }
`;
const StyledTabHeaderCollectorInfo = styled("div")`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;

const TabHeader = (props) => {
  const { collector, name, ...rest } = props;
  return (
    <StyledTabHeader className="tab-header" {...rest}>
      <span className="tab-title">{name}</span>
      <StyledTabHeaderCollectorInfo>
        <span className="collector-name">{collector?.name || ""}</span>
        <span className="collector-id">{collector?.id}</span>
      </StyledTabHeaderCollectorInfo>
    </StyledTabHeader>
  );
};

export default TabHeader;
