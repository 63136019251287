import { useCallback, useEffect } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useBackendEvents from "hooks/useBackendEvents";

import API from "./apibase";
import { queryFetch } from "./query";

class SchedulerAPI extends API {
  static runJobNow({ scenarioId, params = null }) {
    let url = `/api/scheduler/v1/${scenarioId}/jobs`;
    if (params) {
      const queryParams = new URLSearchParams(params).toString();
      url += `?${queryParams}`;
    }

    return {
      url: url,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    };
  }

  static getAllJobsUrl() {
    return { url: "/api/scheduler/v1/jobs" };
  }

  static getJobStatusUrl(scenarioID) {
    return { url: `/api/scheduler/v1/${scenarioID}/jobs` };
  }
  static getJobCronUrl(scenarioID) {
    return { url: `/api/scheduler/v1/${scenarioID}/jobs/cronjob` };
  }

  static createCronjob(scenarioID, info) {
    const url = SchedulerAPI.getJobCronUrl(scenarioID).url;
    return {
      url,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(info),
      },
    };
  }

  static updateCronjob(scenarioID, info) {
    const url = SchedulerAPI.getJobCronUrl(scenarioID).url;
    return {
      url,
      options: {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(info),
      },
    };
  }

  static rescoreJob(scenarioID) {
    return {
      url: `/api/scheduler/v1/${scenarioID}/jobs?job_type=rescore`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    };
  }

  static reclusterJob(scenarioID) {
    return {
      url: `/api/scheduler/v1/${scenarioID}/jobs?job_type=recluster`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    };
  }

  static recalculateJob(scenarioID) {
    return {
      url: `/api/scheduler/v1/${scenarioID}/jobs?job_type=rescore_black_and_white`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    };
  }

  static abortJob(scenarioID) {
    return {
      url: `/api/scheduler/v1/${scenarioID}/jobs`,
      options: {
        method: "DELETE",
      },
    };
  }

  static sendTestAlert(scenario) {
    return {
      url: `/api/scheduler/v1/${scenario}/jobs?job_type=testalert`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    };
  }

  static dataAnalysisJob({ collectorId, fileIds, explorationMode, filtering }) {
    const query = new URLSearchParams({
      job_type: "data_analysis",
      data_analysis_ids:
        explorationMode === "sample" ? Array.from(fileIds).join(",") : "all",
      filtering: !!filtering,
    });

    return {
      url: `/api/scheduler/v1/${collectorId}/jobs?${query.toString()}`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    };
  }

  static dataAnalysisColumnJob({
    collectorId,
    columnIds,
    explorationMode,
    filtering,
  }) {
    const query = new URLSearchParams({
      job_type: "data_analysis",
      column_analysis_ids: columnIds.join(","),
      filtering: !!filtering,
    });
    if (explorationMode !== "sample") {
      query.append("data_analysis_ids", "all");
    }

    return {
      url: `/api/scheduler/v1/${collectorId}/jobs?${query.toString()}`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    };
  }
}

export default SchedulerAPI;

const backendEventsOfInterest = ["schedules"];
const baseKey = ["scheduler"];
const schedulerKeys = {
  jobsList: () => [baseKey, "jobs", "list"],
  jobStatus: ({ scenarioId }) => [baseKey, "jobs", scenarioId, "status"],
  cronjob: ({ scenarioId }) => [baseKey, "jobs", scenarioId, "cronjob"],
};

export const useRunJobMutation = () => {
  return useMutation({
    mutationFn: ({ scenarioId, params }) =>
      queryFetch(SchedulerAPI.runJobNow({ scenarioId, params })),
  });
};

export const useJobsQuery = (opts = {}) => {
  return useQuery({
    queryKey: schedulerKeys.jobsList(),
    queryFn: () => queryFetch(SchedulerAPI.getAllJobsUrl()),
    ...opts,
  });
};

export const useGetJobStatusQuery = ({ scenarioId }) => {
  return useQuery({
    queryKey: schedulerKeys.jobStatus({ scenarioId }),
    queryFn: queryFetch(SchedulerAPI.getJobStatusUrl(scenarioId)),
  });
};

export const useGetJobCronQuery = ({ scenarioId, enabled = true }) => {
  const queryClient = useQueryClient();
  const [backendEvent] = useBackendEvents(backendEventsOfInterest, [
    scenarioId,
  ]);

  useEffect(() => {
    if (backendEvent) {
      queryClient.invalidateQueries({
        queryKey: schedulerKeys.cronjob({ scenarioId }),
      });
    }
  }, [backendEvent, queryClient, scenarioId]);

  return useQuery({
    queryKey: schedulerKeys.cronjob({ scenarioId }),
    queryFn: () => queryFetch(SchedulerAPI.getJobCronUrl(scenarioId)),
    select: useCallback((rows) => rows[0], []),
    enabled,
  });
};

export const useCreateCronJobMutation = () => {
  return useMutation({
    mutationFn: ({ scenarioId, info }) =>
      queryFetch(SchedulerAPI.createCronjob(scenarioId, info)),
  });
};

export const useUpdateCronJobMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ scenarioId, info }) =>
      queryFetch(SchedulerAPI.updateCronjob(scenarioId, info)),
    onMutate: async ({ scenarioId, info }) => {
      const queryKey = schedulerKeys.cronjob({ scenarioId });
      await queryClient.cancelQueries({ queryKey });
      const prevCronJob = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, [info]);
      return { scenarioId, prevCronJob, queryKey };
    },
    onError: (_, __, { prevCronJob, queryKey }) => {
      queryClient.setQueryData(queryKey, prevCronJob);
    },
  });
};

export const useRescoreJobMutation = () => {
  return useMutation({
    mutationFn: (scenarioId) => queryFetch(SchedulerAPI.rescoreJob(scenarioId)),
  });
};

export const useReclusterJobMutation = () => {
  return useMutation({
    mutationFn: (scenarioId) =>
      queryFetch(SchedulerAPI.reclusterJob(scenarioId)),
  });
};

export const useAbortJobMutation = () => {
  return useMutation({
    mutationFn: (scenarioId) => queryFetch(SchedulerAPI.abortJob(scenarioId)),
  });
};

export const useRecalculateJobMutation = () => {
  return useMutation({
    mutationFn: (scenarioId) =>
      queryFetch(SchedulerAPI.recalculateJob(scenarioId)),
  });
};

export const useSendTestAlertMutation = () => {
  return useMutation({
    mutationFn: (scenarioId) =>
      queryFetch(SchedulerAPI.sendTestAlert(scenarioId)),
  });
};

export const useDataAnalysisJobMutation = () => {
  return useMutation({
    mutationFn: ({ collectorId, fileIds, explorationMode, filtering }) =>
      queryFetch(
        SchedulerAPI.dataAnalysisJob({
          collectorId,
          fileIds,
          explorationMode,
          filtering,
        })
      ),
  });
};

export const useDataAnalysisColumnJobMutation = () => {
  return useMutation({
    mutationFn: ({ collectorId, columnIds, explorationMode, filtering }) =>
      queryFetch(
        SchedulerAPI.dataAnalysisColumnJob({
          collectorId,
          columnIds,
          explorationMode,
          filtering,
        })
      ),
  });
};
