import { useContext, useState } from "react";

import { styled } from "@mui/material";

import { ScenariosContext } from "contexts/ScenariosContext";

import Frame from "components/ui/Frame";
import FrameContent from "components/ui/FrameContent";
import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";
import FramesCollection from "components/ui/FramesCollection";
import NotAllowedMessage from "components/ui/NotAllowedMessage";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import useScenarioJobState from "hooks/useScenarioJobState";

import AuditListView from "./diagnostics/AuditListView";
import JobActions from "./diagnostics/DiagnosticJobActions";
import { default as JobProgressView } from "./diagnostics/JobProgress";
import MessagesList from "./diagnostics/MessageList";

const StyledDiagnostics = styled(FramesCollection)(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "min-content min-content min-content",
  height: "calc(100vh - var(--appbar-height) - 40px)",
  overflowX: "hidden",
  overflowY: "auto",
  padding: theme.spacing(2),
}));

export default function Diagnostics() {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  const [progressStarted, setProgressStarted] = useState(0);
  const caps = useCapabilities();
  const { isRunning } = useScenarioJobState(selectedScenario.id);

  if (!caps({ "acm.scenarios": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <StyledDiagnostics>
      <MessagesList selectedScenarioId={selectedScenario.id} />
      <div style={{ width: "100%", display: "flex" }}>
        <div style={{ width: "70%" }}>
          <Frame
            style={{
              gridTemplateRows: "min-content 40em min-content",
            }}
          >
            <FrameTitle>
              <FrameTitleText>
                {strings.scenariodiagnostics_jobprogress_title}
              </FrameTitleText>
            </FrameTitle>

            <FrameContent style={{ overflow: "auto" }}>
              <JobProgressView
                scenario={selectedScenario?.id}
                key={progressStarted}
              />
            </FrameContent>
          </Frame>
        </div>
        <div style={{ width: "30%" }}>
          <Frame
            style={{
              width: "100%",
              gridTemplateRows: "min-content 40em",
            }}
          >
            <JobActions
              isRunning={isRunning}
              onProgressStarted={() => setProgressStarted((prev) => prev + 1)}
            />
          </Frame>
        </div>
      </div>

      <Frame
        style={{
          width: "100%",
          gridTemplateRows: "min-content 25em",
        }}
      >
        <FrameTitle>
          <FrameTitleText>
            {strings.scenariodiagnostics_audit_title}
          </FrameTitleText>
        </FrameTitle>
        <FrameContent style={{ position: "relative" }}>
          <AuditListView
            scenario={selectedScenario?.id}
            style={{ height: "25em" }}
          />
        </FrameContent>
      </Frame>
    </StyledDiagnostics>
  );
}
