import { create } from "zustand";

import CollectorsAPI from "api/collectors";

import stdFetch from "utils/stdFetch";

export const useAnalysisColumnsStore = create((set) => ({
  analysisColumns: [],
  getAnalysisColumns: async (app, options) => {
    if (!app?.options?.default?.internal_connector) {
      if (options?.collector !== true) {
        return;
      }
    }
    const runs = await stdFetch(
      CollectorsAPI.getCollectorDataAnalysisRuns(app.id).url
    );
    if (!runs.length) {
      set({ analysisColumns: [] });
      return;
    }
    const run = runs[0];
    const colData = await stdFetch(
      CollectorsAPI.getCollectorDataAnalysisColumns(
        app.id,
        run.id,
        0,
        1000,
        {},
        { name: 1 }
      ).url
    );
    const analysisColumns = colData.map((col) => col.name);
    set({ analysisColumns });
  },
}));
