import { useContext, useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { Container, Divider, Grid, styled } from "@mui/material";
import { Link } from "react-router-dom";

import { useResetScenarioQuery, useScenarioQuery } from "api/scenarios";
import {
  useAbortJobMutation,
  useRecalculateJobMutation,
  useReclusterJobMutation,
  useRescoreJobMutation,
  useRunJobMutation,
  useSendTestAlertMutation,
} from "api/scheduler";
import { useSourcesQuery } from "api/sources";

import { ScenariosContext } from "contexts/ScenariosContext";

import FrameContent from "components/ui/FrameContent";
import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";
import HtmlTooltip from "components/ui/HtmlTooltip";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";

import useBackendEvents from "hooks/useBackendEvents";
import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import RunAction from "./RunAction";

const StyledAction = styled(Grid)(() => ({
  alignItems: "center",
  display: "flex",

  "& .hoverPointer": {
    cursor: "pointer",
  },
}));

export const Action = ({
  description,
  handler,
  disabled,
  buttonLabel,
  ButtonType = UiSecondaryButton,
  hasStatus,
  statusLabel,
}) => {
  return (
    <>
      <StyledAction item xl={8} xs={9} sx={{ justifyContent: "end" }}>
        <HtmlTooltip
          className="hoverPointer"
          title={disabled ? null : description}
          placement="left"
        >
          <span>
            <ButtonType
              onClick={handler}
              disabled={disabled}
              style={{ width: "12.5em" }}
            >
              {buttonLabel}
            </ButtonType>
          </span>
        </HtmlTooltip>
      </StyledAction>

      <StyledAction item xl={4} xs={3} sx={{ justifyContent: "start" }}>
        {hasStatus && statusLabel ? (
          <HtmlTooltip
            className="hoverPointer"
            title={statusLabel}
            placement="bottom"
          >
            <InfoIcon color="secondary" />
          </HtmlTooltip>
        ) : undefined}
      </StyledAction>
    </>
  );
};

const ConfirmableAction = ({
  description,
  handler,
  disabled,
  buttonLabel,
  ButtonType = UiPrimaryButton,
  hasStatus,
  statusLabel,
  dialogText,
  dialogTitle,
  confirmAction,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  return (
    <>
      <Action
        description={description}
        handler={handleOpen}
        disabled={disabled}
        buttonLabel={buttonLabel}
        ButtonType={ButtonType}
        hasStatus={hasStatus}
        statusLabel={statusLabel}
      />
      <MultipleOptionsDialog
        confirmText={confirmAction}
        onConfirm={handler}
        open={open}
        setOpen={setOpen}
        text={dialogText}
        title={dialogTitle}
      />
    </>
  );
};

const backendEventsOfInterest = ["jobs", "scenarios"];
function JobActions({ isRunning, onProgressStarted }) {
  const strings = useLocalizedStrings();
  const caps = useCapabilities();
  const { pushMessage } = useMessages();
  const { selectedScenario } = useContext(ScenariosContext);

  const { data: sources } = useSourcesQuery({
    scenarioId: selectedScenario?.id,
  });
  const { data: scenario, refetch } = useScenarioQuery({
    scenarioId: selectedScenario?.id,
    includeLogs: false,
  });

  const { mutate: runJob } = useRunJobMutation();
  const { mutate: rescoreJob } = useRescoreJobMutation();
  const { mutate: reclusterJob } = useReclusterJobMutation();
  const { mutate: abortJob } = useAbortJobMutation();
  const { mutate: recalculateJob } = useRecalculateJobMutation();
  const { mutate: sendTestAlert } = useSendTestAlertMutation();
  const { mutate: resetScenario } = useResetScenarioQuery();

  const [backendNotification] = useBackendEvents(backendEventsOfInterest, [
    scenario?.id,
  ]);

  useEffect(() => {
    const refreshScenario = async () => {
      await refetch();
    };
    refreshScenario();
  }, [backendNotification, refetch]);

  const flags = {
    rescore_required: scenario?.rescore_required,
    reset_required: scenario?.reset_required,
    recluster_required: scenario?.recluster_required,
  };

  const handleRun = (params) => {
    return runJob(
      { scenarioId: scenario?.id, params },
      { onSuccess: () => onProgressStarted() }
    );
  };

  const handleRescore = () =>
    rescoreJob(scenario.id, { onSuccess: () => onProgressStarted() });

  const handleRecluster = () => {
    reclusterJob(scenario.id, { onSuccess: () => onProgressStarted() });
  };

  const handleAbort = () => {
    abortJob(scenario.id, {
      onSuccess: () =>
        pushMessage(
          "success",
          strings.scenariodiagnostics_jobprogress_abortrequested
        ),
    });
  };

  const handleSendTestAlert = () => {
    sendTestAlert(scenario.id);
  };

  const handleReset = () => {
    resetScenario(scenario.id, {
      onSuccess: () => {
        pushMessage(
          "success",
          strings.formatString(
            strings.scenariosettings_reset_success,
            scenario?.name || ""
          )
        );
        onProgressStarted();
      },
    });
  };

  const handleRecalculate = () => {
    recalculateJob(scenario.id, { onSuccess: () => onProgressStarted() });
  };

  return (
    <>
      <FrameTitle>
        <FrameTitleText>
          {strings.scenariodiagnostics_actions_title}
        </FrameTitleText>
      </FrameTitle>
      <FrameContent p={12}>
        <Container disableGutters sx={{ marginTop: "30px" }}>
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={3}
          >
            <RunAction
              scenario={scenario}
              sources={sources}
              handler={handleRun}
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
            />
            <Action
              description={
                <>
                  {strings.scenariodiagnostics_testalerts_explanation}
                  <br />
                  <Link
                    to={{
                      pathname: `/scenarios/${scenario?.id}/settings`,
                      state: { alertersOpen: true },
                    }}
                    style={{
                      color: "steelblue",
                      textDecoration: "none",
                      fontSize: "inherit",
                    }}
                  >
                    {strings.scenariodiagnostics_testalerts_explanation2}
                  </Link>
                </>
              }
              handler={handleSendTestAlert}
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
              buttonLabel={strings.scenariodiagnostics_testalerts_test}
            />
            <ConfirmableAction
              ButtonType={UiSecondaryButton}
              dialogTitle={
                strings.scenariodiagnostics_actions_rescore_dialog_title
              }
              dialogText={strings.formatString(
                strings.scenariodiagnostics_actions_rescore_dialog_text,
                scenario?.name || ""
              )}
              handler={handleRescore}
              buttonLabel={strings.scenariodiagnostics_actions_rescore_button}
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
              hasStatus={flags?.rescore_required}
              statusLabel={
                strings.scenariodiagnostics_actions_rescore_status_label
              }
              confirmAction={strings.button_rescore}
            />
            <ConfirmableAction
              handler={handleRecluster}
              buttonLabel={strings.scenariodiagnostics_actions_recluster_button}
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
              hasStatus={flags?.recluster_required}
              statusLabel={
                strings.scenariodiagnostics_actions_recluster_status_label
              }
              ButtonType={UiSecondaryButton}
              dialogTitle={
                strings.scenariodiagnostics_actions_recluster_dialog_title
              }
              dialogText={strings.formatString(
                strings.scenariodiagnostics_actions_recluster_dialog_text,
                scenario?.name || ""
              )}
              confirmAction={strings.button_recluster}
            />
            <Action
              handler={handleRecalculate}
              buttonLabel={strings.scenariodiagnostics_actions_recalc_button}
              description={
                <pre>
                  {strings.scenariodiagnostics_actions_recalc_description}
                </pre>
              }
              disabled={
                isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
            />
            <Grid item xs={10}>
              <Divider />
            </Grid>
            <ConfirmableAction
              handler={handleReset}
              buttonLabel={strings.button_reset}
              disabled={
                isRunning || !caps({ "acm.scenarios": { write: true } })
              }
              hasStatus={flags?.reset_required}
              statusLabel={
                strings.scenariodiagnostics_actions_reset_status_label
              }
              ButtonType={UiSecondaryButton}
              dialogTitle={
                strings.scenariodiagnostics_actions_reset_dialog_title
              }
              dialogText={strings.formatString(
                strings.scenariodiagnostics_actions_reset_dialog_text,
                scenario?.name || ""
              )}
              confirmAction={strings.button_reset}
            />
            <ConfirmableAction
              description={strings.scenariodiagnostics_actions_abort_title}
              handler={handleAbort}
              buttonLabel={strings.scenariodiagnostics_jobprogress_abort}
              disabled={
                !isRunning || !caps({ "scheduler.jobs": { write: true } })
              }
              ButtonType={UiSecondaryButton}
              dialogTitle={
                strings.scenariodiagnostics_actions_abort_dialog_title
              }
              dialogText={strings.scenariodiagnostics_actions_abort_dialog_text}
              confirmAction={strings.button_abort}
            />
          </Grid>
        </Container>
      </FrameContent>
    </>
  );
}

export default JobActions;
