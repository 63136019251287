import { useMemo } from "react";

import { useFormContext, useWatch } from "react-hook-form";

import MappingTabItemComponent from "./MappingTabItemComponent";

const MappingTabComponent = () => {
  const { formState } = useFormContext();

  const watchedBucketing = useWatch({
    name: "options.internal_config.bucketing",
  });

  const watchedTransformations = useWatch({
    name: "options.internal_config.transformations",
  });

  const mappingItems = useMemo(() => {
    const initialInternalConfigMapping = {
      annotation: [],
      appuser: [],
      device: [],
      location: [],
    };
    const mappingColumns = ["annotation", "appuser", "device", "location"];
    const getInternalConfig = (data) => {
      data &&
        Object.keys(data).forEach((item) => {
          data[item]?.entries?.forEach((entry, index) => {
            if (mappingColumns.includes(entry.output_column)) {
              initialInternalConfigMapping[entry.output_column].push({
                name: `options.internal_config.${item}.entries.${index}.output_field`,
                output_field: entry.output_field,
                source: item,
              });
            }
          });
        });
      return initialInternalConfigMapping;
    };

    const additionalData = {
      bucketing: watchedBucketing,
      transformations: watchedTransformations,
    };

    const additionalEntriesData = getInternalConfig(additionalData);

    const internal_config = {
      ...initialInternalConfigMapping,
      ...formState.defaultValues?.options?.internal_config?.mapping,
    };

    return Object.entries(internal_config)
      .sort()
      .map(([key]) => {
        return (
          <MappingTabItemComponent
            additionalEntries={additionalEntriesData[key]}
            dataKey={key}
            key={key}
          />
        );
      });
  }, [
    formState.defaultValues?.options?.internal_config?.mapping,
    watchedBucketing,
    watchedTransformations,
  ]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      {mappingItems}
    </div>
  );
};

export default MappingTabComponent;
