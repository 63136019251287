import { createContext, useEffect, useRef } from "react";

import LocalizedStrings from "react-localization";

import localizedStringsJSON from "assets/strings/localization.json";

import { useCurrentUserSettings } from "hooks/currentUserSettings";

export const LocalizedStringsContext = createContext();

export function LocalizedStringsContextProvider({ children }) {
  const [language, setLanguage] = useCurrentUserSettings("language", "en");
  const strings = useRef(new LocalizedStrings(localizedStringsJSON));
  const languages = useRef(Object.keys(localizedStringsJSON));

  // apply selected language
  useEffect(() => {
    if (language) {
      strings.current.setLanguage(language);
    }
  }, [language]);

  return (
    <LocalizedStringsContext.Provider
      value={{
        strings: strings.current,
        setLanguage,
        language,
        availableLanguages: languages.current,
      }}
    >
      {children}
    </LocalizedStringsContext.Provider>
  );
}
