import { useCallback } from "react";

import { FormControl, FormGroup, styled } from "@mui/material";
import { TextField } from "@mui/material";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
import Typography from "@mui/material/Typography";

import Flexbox from "components/ui/Flexbox";

const StyledFreeSearchStrip = styled(FormGroup)(({ theme }) => ({
  display: "block",
  marginBottom: theme.spacing(1),
  width: "100%",
  "& .MuiInputBase-formControl": {
    backgroundColor: "white",
    height: "3em",
  },
  "& .filterTarget": {
    color: "white",
    textTransform: "uppercase",
  },
  "& .filterOperator": {
    flexGrow: 0,
    marginRight: theme.spacing(1),
    minWidth: "10em",
    padding: 0,
  },
  "& .filterOperand": {
    flexGrow: 1,
    minWidth: "20em",
  },
}));

export default function SearchFilter({
  target,
  schema,
  filterParams,
  setFilterParams,
}) {
  const handleOperatorChanged = useCallback(
    (e) => {
      setFilterParams(target, { ...filterParams, operator: e.target.value });
    },
    [setFilterParams, filterParams, target]
  );

  const handleOperandChanged = useCallback(
    (e) => {
      setFilterParams(target, { ...filterParams, operand: e.target.value });
    },
    [setFilterParams, filterParams, target]
  );
  const handleSelectTypeChanged = useCallback(
    (e, schema) => {
      const targetObject = schema.dropdown_options.find(
        (o) => o.value === e.target.value
      );

      const op =
        schema.dropdown_options.filter(
          (option) => option.id == targetObject.id
        )[0]?.operator?.op || schema.operators[0].op;

      if (e.target.value === "All") {
        setFilterParams(target, undefined);
      } else {
        setFilterParams(target, {
          ...filterParams,
          operator: op,
          operand: e.target.value,
        });
      }
    },
    [setFilterParams, filterParams, target]
  );

  return (
    <StyledFreeSearchStrip>
      <Typography variant="subtitle1">{schema.label}</Typography>
      <Flexbox width={1}>
        {schema.type === "select" ? (
          <FormControl>
            <Select
              className="filterOperator"
              name={target}
              id={target}
              variant="outlined"
              value={filterParams?.operand || schema?.default_value || ""}
              renderValue={(item) => {
                const targetObject = schema.dropdown_options.find(
                  (o) => o.value === item
                );
                return targetObject.label;
              }}
              onChange={(e) => handleSelectTypeChanged(e, schema)}
            >
              {schema.dropdown_options.map((o) => (
                <MenuItem key={o.id} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            <FormControl>
              <Select
                className="filterOperator"
                variant="outlined"
                name={target}
                id={target}
                value={filterParams?.operator || ""}
                onChange={handleOperatorChanged}
              >
                {schema.operators.map((o) => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              className="filterOperand"
              variant="outlined"
              name={target + "_value"}
              id={target + "_value"}
              value={
                filterParams?.operand !== undefined
                  ? filterParams?.operand || ""
                  : ""
              }
              disabled={!filterParams?.operator}
              type={schema.type || "text"}
              onChange={handleOperandChanged}
              slotProps={{
                input: {
                  inputProps: {
                    max:
                      schema.type === "datetime-local"
                        ? "9999-12-12T00:00:00.00"
                        : "",
                    ...schema?.inputProps,
                  },
                },
              }}
            />
          </>
        )}
      </Flexbox>
    </StyledFreeSearchStrip>
  );
}
