import { useEffect, useState } from "react";

import { styled } from "@mui/material";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledLoadingMessageWrapper = styled("div")(() => ({
  alignItems: "center",
  height: "300px",
  animation: "slidein 0.7s ease-in-out forwards",
  bottom: "0",
  display: "flex",
  justifyContent: "center",
  left: "0",
  position: "absolute",
  right: "0",
  top: "25%",

  "@keyframes slidein": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

const StyledLoadingMessage = styled("div")(() => ({
  animation: "blink 1s ease-in-out 3s alternate infinite",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  boxShadow: "0px 0px 15px 3px rgba(0, 0, 0, 0.1)",
  color: "rgba(0, 0, 0, 0.6)",
  fontFamily: "inherit",
  fontSize: "1rem",
  fontWeight: "bold",
  letterSpacing: "0.2em",
  opacity: "1",
  padding: "1.5em 2.5em",
  textTransform: "uppercase",
  "@keyframes blink": {
    "0%": {
      transform: "rotate(-5deg)",
    },
    "100%": {
      transform: "rotate(5deg)",
    },
  },
}));

export default function TableFooterLoadingMessage({ timeout = 500 }) {
  const strings = useLocalizedStrings();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const tid = setTimeout(() => {
      setShow(true);
    }, timeout);
    return () => clearTimeout(tid);
  }, [timeout]);

  if (!show) {
    return null;
  }

  return (
    <StyledLoadingMessageWrapper>
      <StyledLoadingMessage>
        {`${strings.table_footer_loading}...`}
      </StyledLoadingMessage>
    </StyledLoadingMessageWrapper>
  );
}
