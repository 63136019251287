import { useCallback } from "react";

import { useQuery } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

export default class ScenarioParametersAPI extends API {
  static get(scenario, paramKey) {
    return {
      url: `/api/acm/v1/scenarios/key/${scenario}/parameter?name=${paramKey}`,
    };
  }
}

const baseKey = ["scenario-parameters"];
const scenarioParametersKeys = {
  list: ({ scenarioId, paramKey }) => [baseKey, scenarioId, paramKey],
};

export const useScenarioParameterKeyQuery = (
  { scenarioId, paramKey },
  opts
) => {
  return useQuery({
    queryKey: scenarioParametersKeys.list({ scenarioId, paramKey }),
    queryFn: () => queryFetch(ScenarioParametersAPI.get(scenarioId, paramKey)),
    select: useCallback((data) => data[0]?.values, []),
    ...opts,
  });
};
