import { useMemo } from "react";

export const useFieldInsights = (sessionData) => {
  return useMemo(
    () =>
      sessionData?.insights?.reduce((a, curr) => {
        const tag = curr?.tag;
        const value = curr?.value;
        const type = curr?.type;

        a[tag] ??= {};

        if (!value) {
          a[tag][type] ??= [];
          a[tag][type].push(curr);
        } else {
          const tagObject = sessionData?.facts?.rows.find(
            (row) => row.tag === tag
          );

          const valueData = tagObject?.tag_values?.find(
            (v) => v.name === value
          );

          a[tag][type] ??= {};
          a[tag][type][value] ??= [];
          a[tag][type][value].push({ ...curr, valueData });
        }

        return a;
      }, {}),
    [sessionData?.facts?.rows, sessionData?.insights]
  );
};
