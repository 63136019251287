import { PriorityHigh } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const ThreatDisplay = ({ threat }) => {
  const strings = useLocalizedStrings();
  return (
    <Box display="flex" flexDirection="column" gap="5px">
      <Typography>
        <span style={{ fontStyle: "italic" }}>
          {strings.threat_tactic_label}
        </span>{" "}
        {threat.tactic}
      </Typography>
      {threat.technique && (
        <Typography>
          <span style={{ fontStyle: "italic" }}>
            {strings.threat_technique_label}
          </span>{" "}
          {threat.technique}
        </Typography>
      )}
      {threat.sub_technique && (
        <Typography>
          <span style={{ fontStyle: "italic" }}>
            {strings.threat_sub_technique_label}
          </span>{" "}
          {threat.sub_technique}
        </Typography>
      )}
      {threat.tid && (
        <Typography>
          <span style={{ fontStyle: "italic" }}>
            {strings.threat_tid_label}
          </span>{" "}
          {threat.tid}
        </Typography>
      )}
    </Box>
  );
};

const ThreatList = ({ threats }) => {
  return (
    <Box display="flex" maxWidth="500px" flexDirection="column" gap={"2em"}>
      {threats.map((t, i) => (
        <ThreatDisplay key={i} threat={t} />
      ))}
    </Box>
  );
};

export const ThreatIndicator = ({ threats }) => {
  if (!threats?.length) {
    return null;
  }

  return (
    <HtmlTooltip title={<ThreatList threats={threats} />}>
      <PriorityHigh fontSize="small" />
    </HtmlTooltip>
  );
};
