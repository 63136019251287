import { useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton } from "@mui/material";
import { isEqual } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";

import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import BucketingTabItemComponent from "./BucketingTabItemComponent";

const defaultAppendValues = {
  default_label: "",
  no_value_label: "",
  input_field: "",
  label_rules: [
    {
      condition: "",
      label: "",
    },
  ],
  output_column: "location",
  output_field: "",
  type: "string",
};

const BucketingTabComponent = () => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const strings = useLocalizedStrings();
  const { trigger, getValues } = useFormContext();

  const name = "options.internal_config.bucketing.entries";
  const { fields, append, remove } = useFieldArray({ name });

  const handleAppend = () => {
    append(defaultAppendValues);
    trigger("options.internal_config.mapping");
  };

  const handleDelete = (index) => {
    const values = getValues(`${name}.${index}`);

    if (isEqual(values, defaultAppendValues)) {
      remove(index);
      return;
    }

    setDeleteIndex(index);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmationAction = () => {
    remove(deleteIndex);
    trigger("options.internal_config.mapping");
  };

  return (
    <div>
      {fields &&
        fields.map((item, index) => (
          <BucketingTabItemComponent
            data={item}
            dataKey={index}
            key={item.id}
            onDelete={handleDelete}
          />
        ))}
      <IconButton onClick={handleAppend} size="small">
        <AddCircleOutlineIcon />
      </IconButton>

      <MultipleOptionsDialog
        onConfirm={handleConfirmationAction}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        text={
          strings.sourcedialog_mapping_editor_bucketing_tab_delete_entry_question
        }
        title={
          strings.sourcedialog_mapping_editor_bucketing_tab_delete_entry_title
        }
      />
    </div>
  );
};

export default BucketingTabComponent;
