import { useEffect, useMemo, useRef, useState } from "react";

import { styled } from "@mui/material";
import { Chart, registerables } from "chart.js";

import SessionsAPI from "api/sessions";

import { RISK_CLASSES } from "utils/riskClassification";

import { useReportedFetch } from "hooks/http";
import useBackendEvents from "hooks/useBackendEvents";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledSelect = styled("select")`
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 100vh;
  width: 10em;
  outline: none !important;
  margin-left: auto;
  font-size: 9pt;
`;

const StyledDailyAlertsContainer = styled("div")`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .chart-grid {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 3em;
  }

  canvas {
    position: absolute;
  }
`;

Chart.register(...registerables);

const dailyAlertsBackendEvents = ["jobs"];

export const DailyAlerts = (props) => {
  const { scenario, DownloadComponent } = props;
  const [backendEvent] = useBackendEvents(dailyAlertsBackendEvents, [scenario]);
  const [period, setPeriod] = useState(30);
  const canvasRef = useRef();
  const chart = useRef();
  const strings = useLocalizedStrings();

  const [dailyHigh, , isLoadingHigh] = useReportedFetch(
    SessionsAPI.getDailyAlerts({
      maxRisk: RISK_CLASSES.high[1],
      minRisk: RISK_CLASSES.high[0],
      numDays: period,
      scenario,
    }).url,
    [scenario, period, backendEvent]
  );

  const processed = useMemo(() => {
    if (isLoadingHigh) {
      return null;
    }

    const preProcess = (dataset) =>
      dataset?.map((d) => ({
        date: d.date.replace(/T.+/, ""),
        num_alerts: +d.num_alerts,
      }));

    const pDailyHigh = preProcess(dailyHigh);

    // merge labels from 3 datasets
    const labels = Array.from(
      new Set([
        // ...pDailyLow.map(d => d.date),
        // ...pDailyMedium.map(d => d.date),
        ...pDailyHigh.map((d) => d.date),
      ])
    ).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return {
      labels,
      data: pDailyHigh.map((d) => ({ x: d.date, y: d.num_alerts })),
      // pDailyLow.map(d => ({x:d.date, y:d.num_alerts})),
      // pDailyMedium.map(d => ({x:d.date, y:d.num_alerts})),
    };
  }, [
    /*dailyLow, dailyMedium,*/ dailyHigh,
    /*isLoadingLow, isLoadingMed,*/ isLoadingHigh,
  ]);

  useEffect(() => {
    if (!processed) {
      return;
    }
    chart.current = new Chart(canvasRef.current, {
      type: "bar",
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x: {
            display: true,
            stacked: true,
            title: {
              display: true,
              text: strings.dashboard_daily_alerts_x_title,
              align: "center",
              font: {
                size: 14,
              },
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
          y: {
            display: true,
            // type: 'logarithmic',
            stacked: true,
            title: {
              display: true,
              text: strings.dashboard_daily_alerts_y_title,
              align: "center",
              font: {
                size: 14,
              },
            },
            suggestedMax: 5,
            ticks: {
              stepSize: 1,
            },
            min: 0,
          },
        },
        // events: ['click'],
        // onClick: onBarClicked,
      },
      data: {
        labels: processed?.labels || [],
        datasets: [
          // {
          // 	label: 'Low',
          // 	backgroundColor: 'gold',
          // 	data: processed?.data?.[0] || [],
          // },
          // {
          // 	label: 'Medium',
          // 	backgroundColor: 'orange',
          // 	data: processed?.data?.[1] || [],
          // },
          {
            label: "High",
            backgroundColor: "#f00a",
            data: processed?.data || [],
          },
        ],
      },
    });
    return () => {
      chart.current?.destroy();
    };
  }, [processed, strings]);

  if (/*isLoadingLow || isLoadingMed ||*/ isLoadingHigh) {
    return <DownloadComponent />;
  }

  return (
    <StyledDailyAlertsContainer {...props}>
      <div className="chart-grid">
        <div className="chart-wrapper">
          <canvas ref={canvasRef} />
        </div>
        <StyledSelect
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          <option value="30">
            {strings.formatString(
              strings.dashboard_daily_alerts_last_x_days,
              30
            )}
          </option>
          <option value="60">
            {strings.formatString(
              strings.dashboard_daily_alerts_last_x_days,
              60
            )}
          </option>
          <option value="365">
            {strings.formatString(
              strings.dashboard_daily_alerts_last_x_days,
              365
            )}
          </option>
        </StyledSelect>
      </div>
    </StyledDailyAlertsContainer>
  );
};
