import { useCallback, useEffect, useMemo, useState } from "react";

import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { styled } from "@mui/material";
import { useHistory } from "react-router-dom";

import {
  useCollectorUpdateRankMutation,
  useCollectorsDeleteMutation,
  useCollectorsGetFileCatalogQuery,
} from "api/collectors";
import { useScenarioCloneMutation } from "api/scenarios";

import { ScenarioImporter } from "components/scenarios/ScenarioImporter";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { UiPrimaryButton } from "components/ui/StyledButtons";
import TextSearchInput from "components/ui/TextSearchInput";

import { useCapabilities } from "hooks/useCapabilities";
import { useDeletedScenario } from "hooks/useDeletedScenario";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import CollectorCloneDialog from "./CollectorCloneDialog";
import { CollectorThumbnail } from "./CollectorThumbnail";

const StyledCollectorsSidebar = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.primary.purple,
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "min-content min-content 1fr",
  height: "calc(100% - 30px)",
  zIndex: "9999",
  "& .sidebar-toolbar": {
    alignItems: "center",
    color: "inherit",
    display: "flex",
    height: "32px",
    justifyContent: "space-between",
    padding: "5px",
    "*": {
      backgroundColor: "inherit",
    },
  },
  "& .sidebar-list-wrapper": {
    direction: "rtl",
    marginTop: "5px",
    marginBottom: "5px",
    overflowX: "hidden",
    overflowY: "auto",
    "& .sidebar-collectors-list": {
      alignItems: "stretch",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "100%",
    },
  },
}));

const f = {
  status: { operator: "eq", operand: "ok" },
  type: { operator: "in", operand: "raw,flat" },
};
const useCollectorHasFilesQuery = ({
  collector,
  triggerInvalidate,
  supportDelete,
}) => {
  const { data, refetch } = useCollectorsGetFileCatalogQuery({
    id: supportDelete && collector?.id,
    offset: 0,
    limit: 1,
    f,
  });

  useEffect(() => {
    if (triggerInvalidate && supportDelete) {
      refetch();
    }
  }, [triggerInvalidate, supportDelete, refetch]);

  return { data };
};

const SortableCollectorsList = ({
  collectors,
  setSelectedCollector,
  selectedCollector,
  handleDelete,
  onActive,
  draggingDisabled,
  setDialogOpen,
  apps,
}) => {
  const { pushMessage } = useMessages();
  const [isDragging, setIsDragging] = useState(false);
  const [sortedCollectors, setSortedCollectors] = useState(() => collectors);
  const updateRankMutation = useCollectorUpdateRankMutation();

  useEffect(() => {
    setSortedCollectors(collectors);
  }, [collectors]);

  const handleDragStart = (event) => {
    setIsDragging(true);
    const { active } = event;
    setSelectedCollector(active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const newRank = sortedCollectors.find(({ id }) => id === over.id).rank;
      const oldIndex = sortedCollectors.findIndex(({ id }) => id === active.id);
      const newIndex = sortedCollectors.findIndex(({ id }) => id === over.id);
      const newArray = arrayMove(sortedCollectors, oldIndex, newIndex);
      setSortedCollectors(newArray);

      updateRankMutation.mutate(
        {
          id: active.id,
          newRank: newRank,
          newArray,
        },
        {
          onError: (error) => {
            pushMessage("error", error.message);
          },
          onSettled: () => setIsDragging(false),
        }
      );
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToFirstScrollableAncestor]}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      sensors={sensors}
    >
      <SortableContext items={sortedCollectors}>
        {sortedCollectors.map((d) => (
          <CollectorThumbnail
            key={d.id}
            collectorInfo={d}
            selected={d.id === selectedCollector}
            setSelected={setSelectedCollector}
            setDialogOpen={setDialogOpen}
            onDelete={handleDelete}
            onActive={onActive}
            apps={apps}
            someoneIsDragging={isDragging}
            draggingDisabled={draggingDisabled}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export const CollectorsSidebar = ({
  collectors,
  apps,
  selectedCollector,
  setSelectedCollector,
  onActive,
}) => {
  const { pushMessage } = useMessages();
  const strings = useLocalizedStrings();
  const [search, setSearch] = useState("");
  const history = useHistory();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteContext, setDeleteContext] = useState();
  const caps = useCapabilities();
  const writeAllowed = caps({ "acm.collectors": { write: true } });
  const [dialogOpen, setDialogOpen] = useState(false);
  const { markDeletedScenario, unmarkDeletedScenario } = useDeletedScenario();
  const deleteScenario = useCollectorsDeleteMutation();
  const cloneScenario = useScenarioCloneMutation();

  const resolvedCollectors = useMemo(() => {
    if (!collectors) {
      return [];
    }
    const s = search?.toLowerCase();

    if (!s) {
      return collectors;
    }

    return collectors.filter(
      (c) =>
        c.name.toLowerCase().includes(s) ||
        c.description.toLowerCase().includes(s) ||
        c.connector.toLowerCase().includes(s)
    );
  }, [collectors, search]);

  const selectedCollectorInfo = useMemo(
    () =>
      resolvedCollectors.find(
        (collector) => collector.id === selectedCollector
      ),
    [resolvedCollectors, selectedCollector]
  );
  const capabilities = apps?.find(
    (a) => a.id === selectedCollectorInfo?.connector
  )?.capabilities;

  const { data: collectorFiles } = useCollectorHasFilesQuery({
    collector: deleteContext,
    triggerInvalidate: openConfirmDelete,
    supportDelete: capabilities?.delete_all_flat,
  });

  // on new set of collectors, set default selection to the first one or keep the old selection if still valid
  useEffect(() => {
    setSelectedCollector((prev) => {
      if (prev === null) {
        return null;
      } //preserve new collector mode
      return resolvedCollectors.find((c) => c.id === prev)
        ? prev
        : resolvedCollectors?.[0]?.id;
    });
  }, [resolvedCollectors, setSelectedCollector]);

  const handleNewCollector = useCallback(() => {
    history.push("/settings/collectors/configure");
    setSelectedCollector(null);
  }, [setSelectedCollector, history]);

  const handleDelete = useCallback((collector) => {
    setDeleteContext(collector);
    setOpenConfirmDelete(true);
  }, []);

  const deleteDialogText = strings.formatString(
    capabilities?.delete_all_flat
      ? collectorFiles && collectorFiles.length !== 0
        ? strings.collectors_delete_confirmation_message_has_files
        : strings.collectors_delete_confirmation_message_no_files
      : strings.collectors_delete_confirmation_message,
    deleteContext?.name.toUpperCase() || "???",
    <span style={{ textDecoration: "underline" }}>before</span>
  );

  const handleCloneCollector = ({ description, name }) => {
    cloneScenario.mutate(
      { id: selectedCollector, name, description },
      {
        onSuccess: (data) => {
          pushMessage(
            "success",
            strings.formatString(
              strings.collectors_config_cloned_successfully,
              selectedCollectorInfo?.name
            )
          );
          setSelectedCollector(data.id);
          setDialogOpen(false);
        },
      }
    );
  };

  const handleConfirmDelete = useCallback(() => {
    markDeletedScenario(deleteContext.id);
    deleteScenario.mutate(
      { id: deleteContext.id },
      {
        onSuccess: () => {
          setOpenConfirmDelete(false);
          setDeleteContext();
          history.push("/settings/collectors/diagnostics");
        },
        onError: (error) => {
          unmarkDeletedScenario(deleteContext.id);
          pushMessage("error", error.message);
        },
      }
    );
  }, [
    deleteContext,
    deleteScenario,
    markDeletedScenario,
    unmarkDeletedScenario,
    pushMessage,
    history,
  ]);

  return (
    <StyledCollectorsSidebar>
      <div className="sidebar-toolbar">
        <UiPrimaryButton
          startIcon={<AddCircleIcon />}
          onClick={handleNewCollector}
          disabled={!writeAllowed}
          variant="text"
          style={{ padding: "10px 8px" }}
          sx={{ color: "primary.contrastText" }}
        >
          {strings.collectors_sidebar_new}
        </UiPrimaryButton>
        <ScenarioImporter
          collector
          title={strings.sidebar_import_scenario_button}
        />
      </div>

      <TextSearchInput
        searchPattern={search}
        setSearchPattern={setSearch}
        timeout={300}
        style={{ borderRadius: "5px", marginLeft: "8px", marginRight: "8px" }}
      />

      <div className="sidebar-list-wrapper">
        <div className="sidebar-collectors-list">
          <SortableCollectorsList
            collectors={resolvedCollectors}
            setSelectedCollector={setSelectedCollector}
            selectedCollector={selectedCollector}
            handleDelete={handleDelete}
            onActive={onActive}
            draggingDisabled={search !== ""}
            setDialogOpen={setDialogOpen}
            apps={apps}
          />
        </div>
      </div>
      <CollectorCloneDialog
        open={dialogOpen}
        key={dialogOpen}
        collector={selectedCollectorInfo}
        onCancel={() => {
          setDialogOpen(false);
        }}
        onAccept={handleCloneCollector}
      />

      <MultipleOptionsDialog
        onConfirm={handleConfirmDelete}
        open={openConfirmDelete}
        setOpen={setOpenConfirmDelete}
        title={strings.collectors_delete_confirmation_title}
        text={deleteDialogText}
      />
    </StyledCollectorsSidebar>
  );
};
