import { useCallback } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { makeUrlState } from "utils/urlState";

import API from "./apibase";
import { DEFAULT_STALE_TIME, queryFetch } from "./query";

export default class AdminsAPI extends API {
  static getSelf(accountName) {
    if (accountName) {
      return {
        url: `/api/acm/v1/users/self?state=${makeUrlState(accountName)}`,
      };
    }
    return { url: `/api/acm/v1/users/self` };
  }

  static refresh() {
    return { url: `/api/acm/v1/users/self/refresh` };
  }

  static updateSelfExtraSettings(extra, partial = true) {
    return {
      url: `/api/acm/v1/users/self?partial=${partial}`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ extra }),
      },
    };
  }

  static logout(account) {
    return {
      url: `/api/acm/v1/logout?state=${makeUrlState(account)}`,
    };
  }

  static getUser(uid) {
    return {
      url: `/api/acm/v1/users/key/${encodeURIComponent(uid)}`,
    };
  }

  static getall() {
    return { url: "/api/acm/v1/users" };
  }

  static add(userDetails) {
    return {
      url: "/api/acm/v1/users",
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(userDetails),
      },
    };
  }

  static update(uid, userDetails) {
    return {
      url: `/api/acm/v1/users/key/${uid}`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(userDetails),
      },
    };
  }

  static remove(uid) {
    return {
      url: `/api/acm/v1/users/key/${uid}`,
      options: {
        method: "DELETE",
      },
    };
  }

  static currentAccount() {
    return { url: "/api/acm/v1/accounts/self" };
  }
}

const baseKey = ["admins"];
const adminKeys = {
  self: ({ accountName }) => [baseKey, "self", accountName],
  selfAll: () => [baseKey, "self"],
  account: () => [baseKey, "account"],
  user: ({ uid }) => [baseKey, "user", uid],
  all: () => [baseKey, "all"],
};

export const useSelfQuery = (accountName, opts) => {
  return useQuery({
    queryKey: adminKeys.self({ accountName }),
    queryFn: () => queryFetch(AdminsAPI.getSelf(accountName)),
    refetchOnWindowFocus: true,
    ...opts,
  });
};

export const useRefreshJwtMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => queryFetch(AdminsAPI.refresh()),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: adminKeys.selfAll() }),
  });
};

export const useAccountQuery = (opts) => {
  return useQuery({
    queryKey: adminKeys.account(),
    queryFn: () => queryFetch(AdminsAPI.currentAccount()),
    select: useCallback((rows) => rows[0], []),
    ...opts,
  });
};

export const useAdminsGetAdminQuery = ({ uid }, opts) => {
  return useQuery({
    queryKey: adminKeys.user({ uid }),
    queryFn: () => queryFetch(AdminsAPI.getUser(uid)),
    select: useCallback((rows) => rows[0], []),
    ...opts,
  });
};

export const useAdminsQuery = (opts) => {
  return useQuery({
    queryKey: adminKeys.all(),
    queryFn: () => queryFetch(AdminsAPI.getall()),
    staleTime: DEFAULT_STALE_TIME,
    ...opts,
  });
};

export const useAdminsUpdateSelfExtraSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ extra, partial }) =>
      queryFetch(AdminsAPI.updateSelfExtraSettings(extra, partial)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: baseKey }),
  });
};

export const useAdminsCreateAdminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (userDetails) => queryFetch(AdminsAPI.add(userDetails)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: baseKey }),
  });
};

export const useAdminsUpdateAdminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ uid, userDetails }) =>
      queryFetch(AdminsAPI.update(uid, userDetails)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: baseKey }),
  });
};

export const useAdminsDeleteAdminMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uid) => queryFetch(AdminsAPI.remove(uid)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: baseKey }),
  });
};
