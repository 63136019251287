import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import { TableColumnHeaders } from "components/ui/tables/TableColumnHeader";

const GeneralizedTable = ({
  columns,
  items = [],
  strings,
  caps,
  actions,
  getCellClass,
  compactView = false,
  boldHeader = true,
}) => (
  <TableContainer className="table-wrapper">
    <Table stickyHeader>
      <TableColumnHeaders
        columns={columns}
        compactView={compactView}
        boldHeader={boldHeader}
      />
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.id}>
            {columns(strings).map((column) => (
              <TableCell
                key={column.id}
                className={getCellClass ? getCellClass(item) : undefined}
                style={{
                  padding: compactView ? "5px 10px" : undefined,
                  ...column.style,
                }}
              >
                {column.render && column.render(item, actions, caps)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default GeneralizedTable;
