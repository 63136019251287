import { useMemo, useState } from "react";

import { ArrowDropDown } from "@mui/icons-material";
import { Box, Collapse, IconButton, Typography, styled } from "@mui/material";
import { startCase } from "lodash-es";

import { useSessionDetails } from "components/sessions/SessionDetailsContext";
import { FieldTooltipContent } from "components/sessions/SessionTokens";
import { formatPeriod } from "components/sessions/relevance";
import HtmlTooltip from "components/ui/HtmlTooltip";
import RarityIndicator from "components/ui/RarityIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { useStoryContext } from "./StoryTimelineContext";

const StyledFields = styled("ul")`
  display: inline;
  line-height: 1.7em;
  list-style: none;
  li {
    margin-left: 1em;
    padding-left: 1em;
    border-left: 1px solid #aaa;
    word-break: break-all;
    & .tag-name {
      font-weight: bold;
      padding-right: 16px;
      min-width: 13em;
    }
    &.action .tag-name {
      color: steelblue;
    }
    :hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    & > * {
      display: inline-block;
    }
  }
`;

const FIELD_INSIGHT = "values_probability";
const VALUE_INSIGHT = "sessions_found";

const FieldName = ({ name }) => {
  const strings = useLocalizedStrings();
  const { fieldInsights } = useStoryContext();
  const { data: sessionData } = useSessionDetails();

  const tooltipContent = useMemo(() => {
    const insight = fieldInsights?.[name]?.[FIELD_INSIGHT]?.[0];

    if (!insight) {
      return;
    }

    const totalSessions = sessionData?.facts?.totals?.num_sessions;
    const tagData = sessionData?.facts?.rows?.find((r) => r.tag === name);

    return (
      <FieldTooltipContent
        title={strings.formatString(
          strings.sessiondetails_tags_key_tooltip,
          tagData.values_count ?? 0,
          tagData.avg_values_count ?? 0,
          totalSessions
        )}
        significance={insight?.significance?.value}
      />
    );
  }, [
    fieldInsights,
    name,
    sessionData?.facts?.rows,
    sessionData?.facts?.totals?.num_sessions,
    strings,
  ]);

  if (!tooltipContent) {
    return <span className="tag-name">{name}</span>;
  }

  return (
    <HtmlTooltip title={tooltipContent}>
      <span className="tag-name">
        <span style={{ border: "1px solid steelblue", padding: "1px" }}>
          {name}
        </span>
      </span>
    </HtmlTooltip>
  );
};

const FieldValue = ({ field, value }) => {
  const strings = useLocalizedStrings();
  const { fieldInsights } = useStoryContext();
  const { data: sessionData } = useSessionDetails();

  const tooltipContent = useMemo(() => {
    const insight = fieldInsights?.[field]?.[VALUE_INSIGHT]?.[value]?.[0];

    if (!insight) {
      return;
    }

    const significanceValue = insight.significance?.value;
    const inSessions = insight.valueData?.found_in_sessions || 0;

    const fromDate = sessionData?.facts?.totals?.from_date;
    const toDate = sessionData?.facts?.totals?.to_date;
    const totalSessions = sessionData?.facts?.totals?.num_sessions;

    let title = value;
    title += "\n";
    title += strings.formatString(
      strings.sessiondetails_tags_value_tooltip,
      inSessions,
      totalSessions,
      formatPeriod(fromDate, toDate, strings)
    );

    return (
      <>
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            top: 1,
            right: 5,
          }}
        >
          {startCase(significanceValue)}
        </Typography>
        <pre>{title}</pre>
      </>
    );
  }, [
    fieldInsights,
    field,
    value,
    sessionData?.facts?.totals?.from_date,
    sessionData?.facts?.totals?.to_date,
    sessionData?.facts?.totals?.num_sessions,
    strings,
  ]);

  let className = "tag_value";
  if (!tooltipContent) {
    return <span className={className}>{value}</span>;
  }

  return (
    <HtmlTooltip title={tooltipContent}>
      <span
        style={{ background: "steelblue", color: "white" }}
        className={className}
      >
        {value}
      </span>
    </HtmlTooltip>
  );
};

const ActionFields = ({ items }) => {
  const { actionKeys } = useStoryContext();

  const i = useMemo(
    () =>
      items.sort((a, b) =>
        a.key.localeCompare(b.key, undefined, { sensitivity: "accent" })
      ),
    [items]
  );

  return (
    <StyledFields>
      {i.map((d) => (
        <li
          className={actionKeys.has(d.key) ? "action" : "annotation"}
          key={d.key}
        >
          <FieldName name={d.key} />
          {d.value.map((v, i) => (
            <FieldValue key={i} field={d.key} value={v} />
          ))}
        </li>
      ))}
    </StyledFields>
  );
};

export const ActionDetails = ({ action }) => {
  const { fieldInsights } = useStoryContext();
  const [open, setOpen] = useState(false);

  const numberOfInsights = useMemo(() => {
    let n = 0;
    if (!fieldInsights) {
      return n;
    }
    for (let field of action.tokens_filtered) {
      const ki = fieldInsights[field.key];
      if (!ki) {
        continue;
      }
      if (ki[FIELD_INSIGHT]) {
        n++;
      }
      const vi = ki[VALUE_INSIGHT]?.[field.value];
      if (vi) {
        n += vi.length;
      }
    }
    return n;
  }, [fieldInsights, action?.tokens_filtered]);

  const actionDetails = useMemo(() => {
    return Object.entries({
      Sensitivity: <SensitivityIndicator value={action.sensitivity} />,
      Rarity: <RarityIndicator value={action.rarity_score} />,
      "Relevance level": (
        <Typography variant="body2">{action.relevance.level}</Typography>
      ),
      Insights: <Typography variant="body2">{numberOfInsights}</Typography>,
    });
  }, [action, numberOfInsights]);

  return (
    <>
      <Typography>{action.description || action.action}</Typography>
      <Box sx={{ display: "flex", alignItems: "self-start", gap: 2 }}>
        <IconButton
          sx={{
            border: 1,
            mt: "8px",
            borderColor: "lightgray",
          }}
          size="small"
          onClick={() => setOpen((open) => !open)}
        >
          <ArrowDropDown
            sx={{
              rotate: open ? "180deg" : "0deg",
            }}
          />
        </IconButton>
        <Box
          sx={{
            pt: 1,
            display: "flex",
            gap: 10,
            justifyContent: "flex-start",
            alignItems: "self-end",
          }}
        >
          {actionDetails.map(([label, value]) => (
            <Box
              key={label}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                gap: 1,
              }}
            >
              {value}
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {label}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Collapse in={open} unmountOnExit timeout={300}>
        <Box sx={{ maxWidth: "550px", pt: 1 }}>
          <ActionFields items={action.tokens_filtered} />
        </Box>
      </Collapse>
    </>
  );
};
