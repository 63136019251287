import { useCallback } from "react";

import { useQuery } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

export default class MetadataAPI extends API {
  static getVersion() {
    return { url: "/api/acm/v1/version" };
  }
}

const queryKey = ["metadata"];

export const useVersionQuery = (opts) => {
  return useQuery({
    queryKey,
    queryFn: () => queryFetch(MetadataAPI.getVersion()),
    select: useCallback((rows) => rows[0], []),
    ...opts,
  });
};
