import { useCallback, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton, Typography } from "@mui/material";
import { isEqual } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";

import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import FilteringTabItemComponent from "./FilteringTabItemComponent";

const defaultAppendValues = {
  and_rules: [{ input_field: "", op: "=", value: "" }],
};

const FilteringTabComponent = ({
  emptyOpSupport = true,
  numericOpSupport = true,
}) => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const strings = useLocalizedStrings();

  const { getValues } = useFormContext();

  const name = "options.internal_config.filtering.entries";
  const { fields, append, remove } = useFieldArray({ name });

  const handleDelete = useCallback(
    (index) => {
      const values = getValues(`${name}.${index}`);

      if (isEqual(values, defaultAppendValues)) {
        remove(index);
        return;
      }

      setDeleteIndex(index);
      setConfirmDeleteOpen(true);
    },
    [getValues, remove]
  );

  const handleConfirmationAction = () => {
    remove(deleteIndex);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
      <Typography pl={4}>
        {strings.sourcedialog_mapping_editor_filtering_tab_hint}
      </Typography>
      {fields &&
        fields.map((item, index) => {
          return (
            <FilteringTabItemComponent
              data={item}
              dataKey={index}
              key={item.id}
              onDelete={handleDelete}
              emptyOpSupport={emptyOpSupport}
              numericOpSupport={numericOpSupport}
            />
          );
        })}

      <MultipleOptionsDialog
        onConfirm={handleConfirmationAction}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        text={
          strings.sourcedialog_mapping_editor_filtering_tab_delete_entry_question
        }
        title={
          strings.sourcedialog_mapping_editor_filtering_tab_delete_entry_title
        }
      />

      <IconButton
        onClick={() => append(defaultAppendValues)}
        size="small"
        style={{ display: "flex", alignSelf: "baseline" }}
      >
        <AddCircleOutlineIcon />
      </IconButton>
    </div>
  );
};

export default FilteringTabComponent;
