import { useEffect, useMemo, useRef } from "react";

import { Box } from "@mui/material";
import { Chart } from "chart.js";

import { useSessionSuspenseQuery } from "api/sessions";

import { useSessionDetails } from "components/sessions/SessionDetailsContext";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";

import { parseRiskColor } from "utils/parseRiskColor";
import { inUtc } from "utils/time-fmt";

export const UserSessionHistory = ({ scenario }) => {
  const { data: sessionData, isPending } = useSessionDetails();
  const numDays = 14;
  const dayOffset = sessionData?.start;

  const queryOptions = useMemo(
    () => ({
      filters: {
        orig_app_user_id: {
          operator: "eq",
          operand: sessionData?.orig_app_user_id,
        },
        start: {
          operator: "ge",
          operand: inUtc(dayOffset)
            .subtract(numDays, "days")
            .startOf("day")
            .toISOString(),
        },
      },
      offset: 0,
      limit: 1000,
      orderBy: { start: -1 },
      scenario,
    }),
    [sessionData?.orig_app_user_id, dayOffset, scenario]
  );
  const { data } = useSessionSuspenseQuery(queryOptions);
  const chartRef = useRef(null);
  const chartCanvas = useRef(null);

  const datasets = useMemo(() => {
    const dayFormat = "MM-DD";
    const maxDayRiskMap = new Map();
    const riskDayMap = new Map();
    const journeyDayMap = new Map();
    for (let session of data) {
      let day = inUtc(session.start).format(dayFormat).toString();
      journeyDayMap.set(day, (journeyDayMap.get(day) ?? 0) + 1);
      if (session.whole_risk > 0) {
        riskDayMap.set(day, (riskDayMap.get(day) ?? 0) + 1);
      }
      let prevMaxRisk = maxDayRiskMap.get(day) || 0;
      if (session.whole_risk > prevMaxRisk) {
        maxDayRiskMap.set(day, session.whole_risk);
      }
    }

    const dailyJourneys = Array.from({ length: numDays }, (_, i) => {
      const date = inUtc(dayOffset).subtract(i, "days").format(dayFormat);
      return { x: date, y: journeyDayMap.get(date) || 0 };
    }).reverse();

    const riskyJourneys = Array.from({ length: numDays }, (_, i) => {
      const date = inUtc(dayOffset).subtract(i, "days").format(dayFormat);
      return { x: date, y: riskDayMap.get(date) || 0 };
    }).reverse();
    const riskyJourneysBg = riskyJourneys.map(({ y }) => parseRiskColor(y));

    const maxRiskByDay = Array.from({ length: numDays }, (_, i) => {
      const date = inUtc(dayOffset).subtract(i, "days").format(dayFormat);
      return { x: date, y: maxDayRiskMap.get(date) || 0 };
    }).reverse();
    const riskBackground = maxRiskByDay.map(({ y }) => parseRiskColor(y));

    return [
      {
        label: "#Journeys",
        data: dailyJourneys,
        backgroundColor: ["gray"],
        minBarLength: 5,
      },
      {
        label: "#Risky Journeys",
        data: riskyJourneys,
        backgroundColor: riskyJourneysBg,
        minBarLength: 5,
      },
      {
        label: "Max Risk",
        data: maxRiskByDay,
        backgroundColor: riskBackground,
        type: "line",
      },
    ];
  }, [data, dayOffset]);

  useEffect(() => {
    if (!datasets || isPending) {
      return;
    }

    chartRef.current = new Chart(chartCanvas.current, {
      type: "bar",
      options: {
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              position: "top",
              align: "start",
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
      },
      data: {
        datasets,
      },
    });

    return () => {
      chartRef?.current?.destroy();
    };
  }, [datasets, isPending]);

  if (isPending) {
    return <PageLoadingSpinner />;
  }

  return (
    <Box sx={{ width: "100%", height: "180px", position: "relative" }}>
      <canvas style={{ position: "absolute" }} ref={chartCanvas} />
    </Box>
  );
};
