import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as d3 from "d3";

import { parseRarityColor } from "utils/parseRarityColor";
import { parseSensitivityColor } from "utils/parseSensitivityColor";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useResize } from "hooks/useResize";

import D3Chart from "./styled/D3Chart.styled";

const padding = { left: 50, top: 45, right: 10, bottom: 40 };

export default function Timeline(props) {
  const {
    items,
    onBrushSelection,
    onEventClick,
    options,
    children,
    colorScheme,
    ...rest
  } = props;
  const strings = useLocalizedStrings();
  const barWidth = useMemo(() => options?.barWidth || 1, [options]);
  const lolipopRadius = useMemo(() => options?.lolipopRadius || 6, [options]);
  const dimmedOpacity = useMemo(() => options?.dimmedOpacity || 0, [options]);
  const zoomIndicator = useMemo(() => options?.zoomIndicator || 0, [options]);
  const transitionDuration = useMemo(
    () => options?.transitionDuration || 500,
    [options]
  );
  const [extent, containerRef] = useResize();
  const svgRef = useRef();
  const scales = useRef();
  const zoomRef = useRef();
  const brushRef = useRef();
  const [currTransform, setCurrTransform] = useState();
  const [brushSelection, setBrushSelection] = useState();
  const [mode, setMode] = useState("zoom");

  const time = useCallback((d) => d.time, []);

  const Z = useMemo(() => {
    return {
      sensitivity: {
        value: (d) => d.sensitivity,
        range: [0, 10],
        color: (d) => parseSensitivityColor(d.sensitivity),
        yAxisLabel: strings.sessiondetails_timeline_chart_axis_sensitivity,
      },
      rarity: {
        value: (d) => +d.rarity_score,
        range: [0, 3],
        color: (d) => parseRarityColor(+d.rarity_score),
        yAxisLabel: strings.sessiondetails_timeline_chart_axis_rarity,
      },
    }[colorScheme];
  }, [colorScheme, strings]);

  const handleBrushSelection = useCallback(
    (e) => {
      if (e.mode !== "handle") {
        return;
      }

      if (!e.selection) {
        setBrushSelection(null);
        return;
      }

      const x = scales.current.x;
      const r = {
        type: e.type,
        selection: [x.invert(e.selection[0]), x.invert(e.selection[1])],
      };
      setBrushSelection(r);
    },
    [scales]
  );

  const resetZoom = useCallback(() => {
    if (!zoomRef.current) {
      return;
    }
    setCurrTransform(null);
    d3.select(svgRef.current).call(zoomRef.current.transform, d3.zoomIdentity);
  }, [setCurrTransform, zoomRef]);

  const resetBrush = useCallback(() => {
    if (!brushRef.current || !svgRef.current) {
      return;
    }
    brushRef.current.move(d3.select(svgRef.current).select("g.brush"), [0, 0]);
  }, []);

  const handleEventClick = useCallback(
    (e, data) => {
      e.stopPropagation();
      onEventClick?.(data?.dimmed ? null : data);
    },
    [onEventClick]
  );

  useEffect(() => {
    if (brushSelection === undefined) {
      return;
    }
    onBrushSelection?.(brushSelection);
  }, [onBrushSelection, brushSelection]);

  useEffect(() => {
    resetBrush();
  }, [currTransform, resetBrush, mode]);

  useEffect(() => {
    resetZoom();
    resetBrush();
  }, [items, resetZoom, resetBrush]);

  useEffect(() => {
    if (!extent) {
      return;
    }

    scales.current = {
      x: d3
        .scaleTime()
        .domain(d3.extent(items, (d) => time(d)))
        .range([
          padding.left + lolipopRadius,
          extent.width - padding.right - lolipopRadius,
        ]),
      y: d3
        .scaleLinear()
        .domain(Z.range)
        .range([extent.height - padding.bottom, padding.top + lolipopRadius])
        .clamp(true),
      yC: d3
        .scaleLinear()
        .domain(Z.range)
        .range([
          extent.height - padding.bottom - lolipopRadius,
          padding.top + lolipopRadius,
        ])
        .clamp(true),
    };

    // rescale time scale if zoom is currently active
    if (currTransform) {
      scales.current.x = currTransform.rescaleX(scales.current?.x);
    }

    // set up zoom behavior
    const zoom = d3
      .zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([
        [0, 0],
        [extent.width, extent.height],
      ])
      .filter(() => !mode || mode === "zoom")
      .on("zoom", (e) => setCurrTransform(e.transform));
    d3.select(svgRef.current).call(zoom);
    zoomRef.current = zoom;

    // set up brush behavior
    const brush = d3
      .brushX()
      .extent(
        mode === "zoom"
          ? [
              [0, 0],
              [0, 0],
            ]
          : [
              [padding.left, padding.top - lolipopRadius - 2],
              [
                extent.width - padding.right,
                extent.height - padding.bottom - 1,
              ],
            ]
      )
      .filter(() => mode === "select")
      .on("brush end", handleBrushSelection);
    brushRef.current = brush;

    const svg = d3.select(svgRef.current).on("click", handleEventClick);

    // remove axis(es)
    svg.selectAll("g.axis").remove();

    // set x-axis
    const timeFmt = d3.utcFormat("%H:%M:%S");
    const xAxis = d3
      .axisBottom(scales.current?.x)
      .tickFormat(timeFmt)
      .ticks(extent.width / 150);

    svg
      .append("g")
      .attr("class", "axis x-axis")
      .attr("transform", `translate(0, ${extent.height - padding.bottom})`)
      .call(xAxis);

    // set y-axis
    svg
      .append("g")
      .attr("class", "axis y-axis")
      .attr(
        "transform",
        `translate(${padding.left - lolipopRadius}, ${-lolipopRadius})`
      )
      .call(
        d3
          .axisLeft(scales.current?.y)
          .tickFormat((d) => Math.abs(d))
          .ticks(3)
      )
      .append("text")
      .text(Z.yAxisLabel)
      .style("text-transform", "uppercase")
      .style("letter-spacing", "4px")
      .style("text-anchor", "middle")
      .attr("class", "axis-label")
      .attr("fill", "currentColor")
      .attr("transform", "rotate(-90)")
      .attr("x", -extent.height / 2)
      .attr("y", -26);

    // set up zoom indicator
    if (zoomIndicator) {
      const zi = svg.selectAll("g.zoom-indicator").data([1]);
      const ziEnter = zi
        .enter()
        .append("g")
        .attr("class", "zoom-indicator")
        .style("cursor", "default")
        .style("pointer-events", "none");
      ziEnter.append("rect").attr("class", "zoom-track");
      ziEnter.append("rect").attr("class", "zoom-thumb");
      const ziUpdate = zi
        .merge(ziEnter)
        .attr("transform", `translate(0,${extent.height - zoomIndicator})`);
      ziUpdate
        .select("rect.zoom-track")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", extent.width)
        .attr("height", zoomIndicator)
        .attr("fill", "lightgray");
      ziUpdate
        .select("rect.zoom-thumb")
        .attr("x", -(currTransform?.x || 0) / (currTransform?.k || 1))
        .attr("y", 0)
        // .attr('rx', zoomIndicator / 2)
        // .attr('ry', zoomIndicator / 2)
        .attr("width", Math.max(extent.width / (currTransform?.k || 1), 10))
        .attr("height", zoomIndicator)
        .attr("fill", "gray");
    }

    // set up a clipping area
    svg
      .selectAll("clipPath")
      .data([1])
      .enter()
      .append("clipPath")
      .attr("id", "clip-path")
      .append("rect");
    svg
      .select("#clip-path rect")
      .attr("x", padding.left)
      .attr("y", padding.top - lolipopRadius)
      .attr(
        "width",
        extent.width - (padding.left + padding.right) + lolipopRadius
      )
      .attr(
        "height",
        extent.height - (padding.top + padding.bottom) + lolipopRadius
      );

    const events = svg
      .selectAll("g.events")
      .data([null])
      .join("g")
      .attr("class", "events")
      .attr("clip-path", "url(#clip-path)");

    // build join sets
    const joinSet = events.selectAll("g.action").data(items, (d) => d.id);

    // add new groups
    const enterSet = joinSet
      .enter()
      .append("g")
      .attr("class", "action")
      .attr("id", (e) => e.id);

    // add bars to new groups
    enterSet
      .append("line")
      .attr("class", "bar")
      .attr("stroke-width", barWidth)
      .attr("y1", scales.current.y(0))
      .attr("y2", scales.current.y(0));
    enterSet
      .append("circle")
      .attr("class", "lolipop")
      .attr("r", lolipopRadius)
      .attr("cy", scales.current.yC(0));

    // remove redundant groups
    joinSet.exit().remove();

    // update groups
    const updateSet = joinSet
      .merge(enterSet)
      .attr("transform", (d) => `translate(${scales.current?.x(time(d))},0)`);
    // update bars
    updateSet
      .select("line.bar")
      .classed("dimmed", (d) => d.dimmed)
      // .transition(transition)
      .attr("x1", 0)
      .attr("y1", scales.current.y(0))
      .attr("x2", 0)
      .attr("y2", (d) => scales.current?.y(Z.value(d)))
      .attr("stroke", "#eeeeee")
      .attr("stroke-opacity", (d) => (d.dimmed ? dimmedOpacity : 1));
    // update lolipops
    updateSet
      .select("circle.lolipop")
      .classed("dimmed", (d) => d.dimmed)
      .on("click", handleEventClick)
      // .transition(transition)
      .attr("cx", 0)
      .attr("cy", (d) => scales.current?.yC(Z.value(d)))
      .attr("fill", (d) => Z.color(d))
      .attr("fill-opacity", (d) => (d.dimmed ? dimmedOpacity : 1))
      .attr("stroke", "transparent");

    const br = svg.selectAll("g.brush").data([1]);
    const brEnter = br.enter().append("g").attr("class", "brush");
    br.merge(brEnter).call(brush);
  }, [
    handleEventClick,
    items,
    extent,
    containerRef,
    currTransform,
    handleBrushSelection,
    mode,
    barWidth,
    lolipopRadius,
    Z,
    time,
    transitionDuration,
    zoomIndicator,
    dimmedOpacity,
  ]);

  return (
    <D3Chart
      {...rest}
      ref={containerRef}
      className="timeline-chart"
      disabled={!items || items.length === 0}
      tabIndex="-1"
    >
      <p className="chart-title">
        {strings.sessiondetails_timeline_chart_title}
      </p>
      <svg
        ref={svgRef}
        width={extent?.width}
        height={extent?.height}
        tabIndex="0"
      />
      <div className="chart-toolbar">
        <button
          onClick={() =>
            setMode((prev) => (prev === "zoom" ? "select" : "zoom"))
          }
        >
          {mode === "zoom"
            ? strings.sessiondetails_timeline_chart_btn_select
            : strings.sessiondetails_timeline_chart_btn_zoom}
        </button>
        {mode === "zoom" && (
          <button onClick={resetZoom} disabled={currTransform?.k === 1}>
            {strings.sessiondetails_timeline_chart_btn_resetzoom}
          </button>
        )}
        {mode === "select" && (
          <button
            onClick={() => setBrushSelection(null)}
            disabled={!brushSelection}
          >
            {strings.sessiondetails_timeline_chart_btn_resetselection}
          </button>
        )}
        {children}
      </div>
    </D3Chart>
  );
}
