import { useEffect, useMemo, useRef, useState } from "react";

import { styled } from "@mui/material";
import { Chart } from "chart.js";

import ScenariosAPI from "api/scenarios";

import { timeFormatter } from "utils/time-fmt";

import { useReportedFetch } from "hooks/http";
import useBackendEvents from "hooks/useBackendEvents";
import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledSelect = styled("select")`
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 100vh;
  width: 10em;
  outline: none !important;
  margin-left: auto;
  font-size: 9pt;
`;

const StyledDailyEventsContainer = styled("div")`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .chart-grid {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr 3em;
  }

  canvas {
    position: absolute;
  }
`;

const dailyEventsBackendEvents = ["jobs"];

export const DailyEventsCollected = (props) => {
  const strings = useLocalizedStrings();
  const { scenario, DownloadComponent } = props;
  const [period, setPeriod] = useState(30);
  const canvasRef = useRef();
  const chart = useRef();
  const [backendEvent] = useBackendEvents(dailyEventsBackendEvents, [scenario]);

  const [daily, , isLoading] = useReportedFetch(
    ScenariosAPI.getDailyEvents(scenario, period).url,
    [scenario, period, backendEvent]
  );

  const processed = useMemo(() => {
    if (!daily) {
      return null;
    }
    return daily.map((d) => ({
      ...d,
      date: d.date.replace(/T.+/, ""),
      num_events: parseInt(d.num_events),
    }));
  }, [daily]);

  useEffect(() => {
    if (!processed || isLoading) {
      return;
    }
    chart.current = new Chart(canvasRef.current, {
      type: "bar",
      options: {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: strings.dashboard_daily_events_x_title,
              align: "center",
              font: {
                size: 14,
              },
            },
            ticks: {
              font: {
                size: 10,
              },
            },
          },
          y: {
            // type: 'logarithmic',
            title: {
              display: true,
              text: strings.dashboard_daily_events_y_title,
              align: "center",
              font: {
                size: 14,
              },
            },
            min: 0,
            suggestedMax: 10,
            ticks: {
              format: { compactDisplay: "short", notation: "compact" },
            },
          },
        },
      },
      data: {
        labels: processed?.map((d) =>
          timeFormatter(d.date, { includeTime: false })
        ),
        datasets: [
          {
            label: "Daily",
            backgroundColor: "MediumAquaMarine",
            data: processed ? processed.map((d) => d.num_events) : [],
          },
        ],
      },
    });
    return () => {
      chart.current?.destroy();
    };
  }, [processed, isLoading, strings]);

  if (isLoading) {
    return <DownloadComponent />;
  }

  return (
    <StyledDailyEventsContainer {...props}>
      <div className="chart-grid">
        <div className="chart-wrapper">
          <canvas ref={canvasRef} />
        </div>
        <StyledSelect
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          <option value="30">
            {strings.formatString(
              strings.dashboard_daily_events_last_x_days,
              30
            )}
          </option>
          <option value="60">
            {strings.formatString(
              strings.dashboard_daily_events_last_x_days,
              60
            )}
          </option>
          <option value="365">
            {strings.formatString(
              strings.dashboard_daily_events_last_x_days,
              365
            )}
          </option>
        </StyledSelect>
      </div>
    </StyledDailyEventsContainer>
  );
};
