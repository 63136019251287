import { create } from "zustand";

import { createMessage } from "utils/createMessage";

export const useMessagesStore = create((set, get) => ({
  messages: [],
  pushMessage: (level, text, noAutoClose) => {
    set((state) => {
      const last = state.messages.length
        ? state.messages[state.messages.length - 1]
        : {};

      if (level === last.level && text === last.text) {
        return state.messages;
      }
      return {
        messages: [...state.messages, createMessage(level, text, noAutoClose)],
      };
    });
  },
  deleteMessage: (id) =>
    set((state) => ({ messages: state.messages.filter((m) => m.id !== id) })),
  clearExpiredMessages: () => {
    const messages = get().messages;
    if (!messages.length) {
      return;
    }
    const timeout = 30000;
    const now = Date.now();
    const expiredMessages = messages.filter(
      (m) => m.time && now - m.time >= timeout
    );

    if (expiredMessages.length) {
      set(() => ({
        messages: messages.filter((m) => !m.time || now - m.time < timeout),
      }));
    }
  },
  clearMessages: () => set({ messages: [] }),
}));

setInterval(() => useMessagesStore.getState().clearExpiredMessages(), 1000);
