import { useCallback, useContext, useEffect, useMemo } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import ControlPointDuplicateOutlinedIcon from "@mui/icons-material/ControlPointDuplicateOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Collapse, Typography, styled } from "@mui/material";
import { ROLES } from "@tiq/shared/lib/constants";
import { useHistory } from "react-router-dom";

import ScenariosAPI from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import ActiveJobIndicator from "components/ui/ActiveJobIndicator";
import ApplicationIcon from "components/ui/ApplicationIcon";
import HtmlTooltip from "components/ui/HtmlTooltip";
import RemoteDownloader from "components/ui/RemoteDownloader";

import { timeFormatter } from "utils/time-fmt";

import useApplications from "hooks/useApplications";
import { useCapabilities } from "hooks/useCapabilities";
import { useInView } from "hooks/useInView";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useRoles } from "hooks/useRoles";

import { AlertStatusIcon } from "./AlertStatusIcon";
import ScenarioAlertsButton from "./ScenarioAlertsButton";
import ScenarioInfoIcon from "./ScenarioInfoIcon";
import ScenarioMoreOptions from "./ScenarioMoreOptions";
import { ScoringSystemIcon } from "./ScoringSystemIcon";

const selectedStyle = {
  backgroundColor: "#FFFFFF",
  marginRight: "0",
  paddingRight: "17px",
  borderTopRightRadius: "0",
  borderBottomRightRadius: "0",
};

const StyledPendingScenario = styled("li")(({ theme }) => ({
  backgroundColor: `${theme.palette.sidebar.light}`,
  borderRadius: "10px",
  margin: "0 0.5em 0.5em 0.5em",
  minHeight: "4em",
  transition: "background-color 150ms ease",
  color: "rgba(0, 0, 0, 0.5)",
  fontSize: "1.2rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "&.selected": selectedStyle,
}));

const useScrollIntoView = ({ element, enabled, options }) => {
  useEffect(() => {
    if (!enabled || !element) {
      return;
    }
    element.scrollIntoView({
      behaviour: "instant",
      block: "center",
      ...options,
    });
  }, [options, enabled, element]);
};

const PendingScenario = ({ onClick, selected }) => {
  const strings = useLocalizedStrings();
  const { inView, target } = useInView();

  useScrollIntoView({
    element: target?.current?.parentNode,
    enabled: !inView && selected,
    options: { block: "start" },
  });

  return (
    <StyledPendingScenario
      ref={target}
      onClick={onClick}
      className={selected && "selected"}
    >
      <Typography variant="h6">{strings.sidebar_creating_label}</Typography>
    </StyledPendingScenario>
  );
};

const StyledScenarioThumbnail = styled("li")(({ theme }) => ({
  backgroundColor: theme.palette.sidebar.light,
  borderRadius: "10px",
  marginBottom: "0.7em",
  marginLeft: "0.6em",
  marginRight: "0.6em",
  minHeight: "5em",
  overflow: "hidden",
  padding: "0.6em",
  position: "relative",
  cursor: "pointer",
  transitionProperty: "background-color, height, opacity",
  transitionDuration: "150ms",
  "&.selected": {
    ...selectedStyle,
    "& .scenario-thumbnail-body": {
      "& .title, .app-icons": {
        opacity: "1",
      },
    },
  },
  "& .scenario-thumbnail-body": {
    fontFamily: "inherit",
    gap: "7px",
    maxWidth: "100%",
    "& .scenario-details": {
      display: "flex",
      alignItems: "flex-start",
      padding: "0.4em",
      wordBreak: "break-word",
      "& .info": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "right",
        marginRight: "auto",
        minHeight: "4em",
        "& .created": {
          fontSize: "70%",
          letterSpacing: "1px",
          opacity: "0.6",
          marginTop: "auto",
        },
      },
      "& .details-icons": {
        display: "flex",
        justifyContent: "flex-end",
        flexShrink: "0",
        gap: "0.4em",
        width: "50px",
        padding: "4px 1px",
      },
    },
    "& .title": {
      display: "flex",
      opacity: "0.8",
      gap: "0.5em",
      padding: "0em 0.4em 0.4em 0.4em",
      alignItems: "flex-start",
      color: theme.palette.primary.charcoal,
      justifyContent: "space-between",
      letterSpacing: "2px",
      "& .info-icons-container": {
        alignItems: "center",
        display: "flex",
      },
    },
    "& .app-icons": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      borderRadius: "10px",
      overflow: "hidden",
      padding: "10px",
      display: "flex",
      opacity: "0.8",
      gap: "5px",
      alignItems: "center",
      justifyContent: "flex-start",
      "& .app-icons-list-container": {
        minHeight: "24px",
        alignItems: "center",
        display: "flex",
        flexGrow: "1",
        flexWrap: "wrap",
        gap: "3px",
        justifyContent: "flex-start",
      },
    },
  },
}));

const ScenarioThumbnail = ({
  id,
  onClone,
  onDelete,
  scenario,
  selected,
  someoneIsDragging,
}) => {
  const { selectScenario } = useContext(ScenariosContext);
  const [apps] = useApplications();
  const history = useHistory();
  const caps = useCapabilities();
  const strings = useLocalizedStrings();
  const { inView, target } = useInView({ enabled: scenario?.initialized });
  const acmScenarios = caps({ "acm.scenarios": { write: true } });
  const acmUserMessages = caps({ "acm.user_messages": { write: true } });
  const acmSourcesWrite = caps({ "acm.sources": { write: true } });
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: id,
    disabled: !caps({ "acm.scenarios": { write: true } }),
  });

  const { hasRoles } = useRoles();

  const style = {
    transform: CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }),
    transition,
  };

  useScrollIntoView({ element: target.current, enabled: !inView && selected });

  const handleClick = useCallback(
    (source) => {
      if (!hasRoles([ROLES.ADMIN])) {
        return false;
      }
      history.push({
        pathname: `/scenarios/${scenario?.id}/sources`,
        state: {
          source: source?.id,
        },
      });
    },
    [hasRoles, history, scenario?.id]
  );

  const sourceApps = useMemo(() => {
    if (!apps) {
      return null;
    }

    if (!scenario?.sources?.length) {
      return (
        <Typography style={{ color: "rgba(0, 0, 0, 0.5)" }} variant="caption">
          {strings.sidebar_no_source}
        </Typography>
      );
    }

    return scenario?.sources
      .map((s) => {
        const app = apps.find((a) => a.connector === s.connector);
        return {
          ...s,
          icon_data: app?.icon_data,
          icon_content_type: app?.icon_content_type,
        };
      })
      .map((s) => (
        <ApplicationIcon
          app={s}
          key={s.id}
          onClick={(s) => handleClick(s)}
          paused={!s.active}
          size="medium"
          tooltip
        />
      ));
  }, [apps, scenario?.sources, strings.sidebar_no_source, handleClick]);

  const handleScenarioClick = () => {
    if (someoneIsDragging || isDragging) {
      return;
    }
    if (!selected) {
      selectScenario(scenario.id);
    }
  };

  const moreOptionsMenuItems = [
    {
      id: "add-source",
      label: strings.sidebar_tooltip_add_new_source,
      icon: <AddCircleOutlineIcon />,
      handler: () => history.push(`/scenarios/${scenario.id}/sources?new=1`),
      disabled: !acmSourcesWrite,
    },
    {
      id: "clone-scenario",
      label: strings.sidebar_popup_clone,
      icon: <ControlPointDuplicateOutlinedIcon />,
      handler: onClone,
      disabled: !acmScenarios,
    },
    {
      id: "export-scenario",
      label: (
        <RemoteDownloader
          url={ScenariosAPI.exportScenario(scenario.id).url}
          filename={`scenario.${scenario.id}.json`}
        >
          {<>{strings.sidebar_popup_export}</>}
        </RemoteDownloader>
      ),
      icon: <CloudDownloadOutlinedIcon />,
      disabled: !acmScenarios,
    },
    {
      id: "delete-scenario",
      label: strings.sidebar_popup_delete,
      icon: <DeleteOutlineIcon />,
      handler: onDelete,
      disabled: !acmScenarios,
    },
  ];

  if (!scenario.initialized) {
    return (
      <PendingScenario onClick={handleScenarioClick} selected={selected} />
    );
  }

  return (
    <StyledScenarioThumbnail
      hasSourcesWrite={acmSourcesWrite}
      className={selected ? "selected " : ""}
      key={scenario.id}
      id={scenario.id}
      onClick={handleScenarioClick}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div ref={target} className="scenario-thumbnail-body">
        <section className="title">
          <Typography
            variant="body1"
            sx={{ display: "flex", wordBreak: "break-word" }}
          >
            {!scenario?.is_production && (
              <HtmlTooltip title={strings.sidebar_tooltip_in_work}>
                <BuildCircleOutlinedIcon
                  fontSize="medium"
                  sx={{ paddingRight: "2px" }}
                />
              </HtmlTooltip>
            )}
            {scenario.name.toString()}
          </Typography>
          <div className="info-icons-container">
            <ScenarioInfoIcon scenario={scenario} />
            <ScenarioAlertsButton scenario={scenario} />
            <ActiveJobIndicator scenario={scenario.id} />
          </div>
        </section>

        <Collapse in={selected} timeout={"auto"}>
          <hr />
          <section className="scenario-details">
            <div className="info">
              <Typography variant="subtitle2">
                {scenario.description}
              </Typography>
              <span className="created">{timeFormatter(scenario.created)}</span>
            </div>

            <div className="details-icons">
              {scenario.mute_alerts && (
                <AlertStatusIcon scenario={scenario?.id} />
              )}
              <ScoringSystemIcon scoringSystem={scenario.scoring_system} />
            </div>
          </section>
        </Collapse>

        <div className="app-icons">
          <div className="app-icons-list-container">{sourceApps}</div>
          {(acmUserMessages || acmScenarios) && (
            <ScenarioMoreOptions
              menuItems={moreOptionsMenuItems}
              disabled={!selected}
            />
          )}
        </div>
      </div>
    </StyledScenarioThumbnail>
  );
};
export default ScenarioThumbnail;
