import { useContext, useMemo } from "react";

import { styled } from "@mui/material";

import ScenariosContextProvider, {
  ScenariosContext,
} from "contexts/ScenariosContext";

import ErrorBoundary from "components/ErrorBoundary";

import { AnalyticsContentArea } from "./AnalyticsContentArea";
import { Sidebar } from "./Sidebar";

const StyledAlertAnalyticsPage = styled("div")(() => ({
  height: "100%",
  display: "flex",
}));

const AlertAnalytics = () => {
  const { scenarios } = useContext(ScenariosContext);

  const productionScenarios = useMemo(
    () =>
      scenarios
        ?.filter((s) => s.is_production)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [scenarios]
  );

  return (
    <StyledAlertAnalyticsPage>
      <ErrorBoundary>
        <Sidebar scenarios={productionScenarios} />
      </ErrorBoundary>
      <ErrorBoundary>
        <AnalyticsContentArea scenarios={productionScenarios} />
      </ErrorBoundary>
    </StyledAlertAnalyticsPage>
  );
};

export const AlertAnalyticsPage = () => {
  return (
    <ScenariosContextProvider>
      <AlertAnalytics />
    </ScenariosContextProvider>
  );
};
