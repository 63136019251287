import {
  Children,
  isValidElement,
  useCallback,
  useMemo,
  useState,
} from "react";
import { cloneElement } from "react";

import { Collapse, styled } from "@mui/material";
import Paper from "@mui/material/Paper";

const StyledFrame = styled("div")(({ theme }) => ({
  margin: theme.spacing(0.5),
  "& .collapsed-container.collapsible": {
    border: "1px solid #ddd",
    borderRadius: theme.shape.borderRadius,
  },
  "& .collapsed-container:not(.collapsible) .frame-paper": {
    border: "1px solid #ddd",
    borderRadius: theme.shape.borderRadius,
  },
  "& .frame-paper": {
    display: "grid",
    gridTemplateColumns: "100%",
    gridTemplateRows: "min-content 1fr min-content",
    padding: theme.spacing(1),
    position: "relative",
  },
}));

export default function Frame({ children, ...rest }) {
  const [open, setOpen] = useState(!rest?.collapsible);

  const handleToggleCollapse = useCallback(() => {
    rest?.collapsible && setOpen((prev) => !prev);
  }, [rest.collapsible]);

  const childrenWithProps = useMemo(
    () =>
      Children.map(children, (c) => {
        if (isValidElement(c) && rest.collapsible) {
          return cloneElement(c, {
            collapsible: rest.collapsible,
            onToggleCollapse: handleToggleCollapse,
            collapsed: !open,
          });
        }
        return c;
      }),
    [children, handleToggleCollapse, open, rest.collapsible]
  );

  return (
    <StyledFrame>
      <Collapse
        className={`collapsed-container ${
          rest?.collapsible ? "collapsible" : ""
        }`}
        in={open}
        collapsedSize={rest?.collapsible ? "3.6em" : 0}
      >
        <Paper className="frame-paper" elevation={0} {...rest}>
          {childrenWithProps}
        </Paper>
      </Collapse>
    </StyledFrame>
  );
}
