import { useCallback, useEffect, useReducer } from "react";

import { useLocation } from "react-router-dom";

import { useSessionsGetActionsUrlQuery } from "api/sessions";

function eventContains(d, text) {
  if (!text) {
    return true;
  }
  const pat = text.trim().toLowerCase();
  if (!pat.length) {
    return true;
  }
  const target = `
            ${d.action}
            ${d.annotation}
            ${d.info.map((i) => i.description).join("\n")}
	`.toLowerCase();
  return target.includes(pat);
}

const isDimmed = (route, state) => {
  const value = Number(route.relevance.score);
  const min = state.relevanceRange[0];
  const max = state.relevanceRange[1];

  return (
    (route.sensitivity < state.sensitivityRange[0] ||
      route.sensitivity > state.sensitivityRange[1] ||
      route.rarity_score < state.rarityRange[0] ||
      route.rarity_score > state.rarityRange[1] ||
      (state.highlightedEventId && route.id !== state.highlightedEventId) ||
      !state.selectedActions.has(route.action) ||
      (state.selectedRange &&
        (route.time < state.selectedRange[0] ||
          route.time > state.selectedRange[1])) ||
      !eventContains(route, state.searchPattern)) === true ||
    value < min ||
    value >= max
  );
};

export const timelineInitialState = {
  selectedActions: new Set(),
  highlightedEventId: 0,
  rarityRange: [0, 5],
  sensitivityRange: [0, 10],
  relevanceRange: [0, 4],
  colorScheme: "sensitivity",
  searchPattern: "",
  enriched: [],
  showTimeline: true,
  selectedRange: null,
  isPending: true,
  routes: [],
  presentFields: new Set(),
};

export function timelineReducer(state, { type, payload }) {
  let newState;

  if (type === "INIT") {
    if (!state.isPending) {
      return state;
    }
    const routes = payload.routes.map((route) => ({
      ...route,
      rarity_score: +route.rarity_score,
    }));

    const actionKeys = new Set();
    const presentFields = new Set();
    for (const item of routes) {
      const keys = item?.action
        .split("\t")
        .map((t) => t.split("=")[0])
        .filter((t) => !!t);

      for (const key of keys) {
        actionKeys.add(key);
      }
      for (const field of item.tokens_filtered) {
        presentFields.add(field?.key);
      }
    }
    const total = new Set(routes.map((route) => route.action)).size;
    let selectedActions = routes;
    let searchPattern = payload.initialField
      ? `${payload.initialField?.name}=${payload.initialField?.value}`
      : "";
    if (payload.initialAction) {
      selectedActions = routes.filter(
        (r) => r.action === payload.initialAction
      );
    }
    let numericKeys;
    let relevanceRangeMaxValue;
    if (payload.scenarioConfig) {
      numericKeys = Object.keys(payload.scenarioConfig)
        .filter((key) => !isNaN(Number(key)))
        .map(Number);
      relevanceRangeMaxValue = Math.max(...numericKeys) * 10;
    }

    newState = {
      ...timelineInitialState,
      relevanceRange: [0, relevanceRangeMaxValue],
      searchPattern,
      selectedActions: new Set(selectedActions.map((r) => r.action)),
      total,
      routes,
      presentFields,
      actionKeys,
    };
  }

  if (type === "SELECT") {
    if (state.total === state.selectedActions.size) {
      newState = {
        ...state,
        highlightedEventId: 0,
        selectedActions: new Set(
          [...state.selectedActions].filter((route) => route === payload)
        ),
      };
    } else {
      let updatedSelectedActions;

      if (state.selectedActions.has(payload)) {
        updatedSelectedActions = new Set(
          [...state.selectedActions].filter((action) => action !== payload)
        );
      } else {
        updatedSelectedActions = new Set([...state.selectedActions, payload]);
      }

      newState = {
        ...state,
        highlightedEventId: 0,
        selectedActions: updatedSelectedActions,
      };
    }
  }

  if (type === "HIDE") {
    let updatedSelectedActions;

    if (state.selectedActions.has(payload)) {
      updatedSelectedActions = new Set(
        [...state.selectedActions].filter((action) => action !== payload)
      );
    } else {
      updatedSelectedActions = new Set([...state.selectedActions, payload]);
    }

    newState = {
      ...state,
      highlightedEventId: 0,
      selectedActions: updatedSelectedActions,
    };
  }

  if (type === "SET_RANGE") {
    const selectedRange = payload;
    const actions = state.routes
      .filter((d) => d.time >= selectedRange[0] && d.time <= selectedRange[1])
      .map((d) => d.action);
    newState = {
      ...state,
      highlightedEventId: 0,
      selectedRange,
      selectedActions: new Set(actions),
    };
  }

  if (type === "HIGHLIGHT") {
    newState = {
      ...state,
      highlightedEventId: payload,
    };
  }

  if (type === "RESET") {
    newState = {
      ...state,
      highlightedEventId: 0,
      selectedActions: new Set(state.routes.map((route) => route.action)),
      selectedRange: null,
    };
  }

  if (type === "SET_RARITY") {
    newState = {
      ...state,
      rarityRange: payload,
    };
  }

  if (type === "SET_SENSITIVITY") {
    newState = {
      ...state,
      sensitivityRange: payload,
    };
  }
  if (type === "SET_RELEVANCE") {
    newState = {
      ...state,
      relevanceRange: payload,
    };
  }

  if (type === "SET_COLORSCHEME") {
    newState = {
      ...state,
      colorScheme: payload,
    };
  }

  if (type === "SET_SEARCH") {
    newState = {
      ...state,
      searchPattern: payload,
    };
  }

  if (type === "TOGGLE_TIMELINE") {
    newState = {
      ...state,
      showTimeline: !state.showTimeline,
    };
  }

  return {
    ...newState,
    enriched: newState.routes.map((route) => ({
      ...route,
      dimmed: isDimmed(route, newState),
    })),
    isPending: payload?.isPending,
  };
}

export const useTimeline = (scenario, session, scenarioConfig) => {
  const location = useLocation();
  const { data: routes, isPending } = useSessionsGetActionsUrlQuery({
    scenario,
    session,
  });
  const initialAction = location.state?.action;
  const initialField = location.state?.initialField;

  const [state, dispatch] = useReducer(timelineReducer, timelineInitialState);

  // initialize with new data
  // todo:
  // if we can hide the timeline until the routes are loaded
  // we can use an initFn in the useReducer to skip this effect
  useEffect(() => {
    if (isPending) {
      return;
    }
    dispatch({
      type: "INIT",
      payload: {
        routes,
        initialAction,
        initialField,
        isPending,
        scenarioConfig,
      },
    });
  }, [routes, isPending, initialAction, initialField, scenarioConfig]);

  // brush events handling
  const onSelection = useCallback((r) => {
    if (!r) {
      dispatch({ type: "RESET" });
    } else if (r.type === "end") {
      dispatch({ type: "SET_RANGE", payload: r.selection });
    }
  }, []);

  const onBarChartBarClicked = useCallback((_, a) => {
    !a
      ? dispatch({ type: "RESET" })
      : dispatch({ type: "SELECT", payload: a.action });
  }, []);

  const onBarChartBarRightClicked = useCallback((_, a) => {
    dispatch({ type: "HIDE", payload: a?.action });
  }, []);

  const onTimelineLolipopClicked = useCallback((data) => {
    dispatch({ type: "HIGHLIGHT", payload: data?.id || 0 });
  }, []);

  const onToggleTimeline = useCallback(
    () => dispatch({ type: "TOGGLE_TIMELINE" }),
    []
  );

  const onSetSearch = useCallback(
    (payload) => dispatch({ type: "SET_SEARCH", payload }),
    []
  );

  const onSetColorScheme = useCallback(
    (e) => dispatch({ type: "SET_COLORSCHEME", payload: e.target.value }),
    []
  );

  const onSetRarity = useCallback(
    (payload) => dispatch({ type: "SET_RARITY", payload }),
    []
  );

  const onSetSensitivity = useCallback(
    (payload) => dispatch({ type: "SET_SENSITIVITY", payload }),
    []
  );

  const onSetRelevance = useCallback(
    (payload) => dispatch({ type: "SET_RELEVANCE", payload }),
    []
  );

  return {
    state,
    onSelection,
    onBarChartBarClicked,
    onBarChartBarRightClicked,
    onTimelineLolipopClicked,
    onToggleTimeline,
    onSetSearch,
    onSetColorScheme,
    onSetRarity,
    onSetSensitivity,
    onSetRelevance,
  };
};
