import { useCallback, useEffect, useMemo, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  IconButton,
  MenuItem,
  MenuList,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-pro";

import CollectorsAPI, {
  useDataAnalysisColumnsAvailableQuery,
  useDataAnalysisColumnsInfiniteQuery,
} from "api/collectors";
import { useDataAnalysisColumnJobMutation } from "api/scheduler";

import FiltersSection from "components/scenarios/FiltersSection";
import FreeSearchStrip from "components/scenarios/FreeSearchStrip";
import ButtonPopover from "components/ui/ButtonPopover";
import DataGridINF from "components/ui/DataGridINF";
import HtmlTooltip from "components/ui/HtmlTooltip";
import InformationStripItem from "components/ui/InformationStripItem";
import RemoteDownloader from "components/ui/RemoteDownloader";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import FilterOperators from "utils/FilterOperators";
import { inUtc, timeFormatter } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useStateDeep } from "hooks/deepcomp";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import useScenarioJobState from "hooks/useScenarioJobState";

import { DataAnalysisDialog } from "./DataAnalysisDialog";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    name: {
      label: strings.collectors_dataExploration_tab_filters_name,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    unique: {
      label: strings.collectors_dataExploration_tab_filters_unique,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },

    appearances: {
      label: strings.collectors_dataExploration_tab_filters_appearances,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    entropy: {
      label: strings.collectors_dataExploration_tab_filters_entropy,
      type: "number",
      inputProps: { step: "0.001" },
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    types: {
      label: strings.collectors_dataExploration_tab_filters_types,
      type: "select",
      default_value: "string",
      dropdown_options: [
        {
          id: "string",
          label: "String",
          value: "string",
        },
        {
          id: "integer",
          label: "Integer",
          value: "integer",
        },
        {
          id: "date",
          label: "Date",
          value: "date",
        },
        {
          id: "unknown",
          label: "Unknown",
          value: "unknown",
        },
      ],
      operators: [operators["contains"]],
    },
  });

const fields = (strings) => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderHeader: () => null,
  },
  {
    field: "name",
    headerName: strings.collectors_dataExploration_tab_column_field_name,
    sort: "name",
    renderCell: ({ row }) => (
      <HtmlTooltip title={row.name}>
        <Typography className="nameCell">{row.name}</Typography>
      </HtmlTooltip>
    ),
    width: 360,
  },
  {
    field: "unique",
    headerName: strings.collectors_dataExploration_tab_column_unique_values,
    sort: "unique",
    renderCell: ({ row }) => (
      <Typography className="nameCell">{row.unique_display}</Typography>
    ),
    width: 160,
  },
  {
    field: "types",
    headerName: strings.collectors_dataExploration_tab_column_types,
    renderCell: ({ row }) => <Typography>{row.types.join(", ")}</Typography>,
    width: 120,
    sortable: false,
  },
  {
    field: "appearances",
    headerName: strings.collectors_dataExploration_tab_column_appearances,
    sort: "appearances",
    renderCell: ({ row }) => <Typography>{`${row.appearances}%`}</Typography>,
    width: 200,
  },
  {
    field: "entropy",
    headerName: strings.collectors_dataExploration_tab_column_entropy,
    renderCell: ({ row }) => (
      <Typography variant="body1">{row.entropy}</Typography>
    ),
    width: 160,
    sortable: false,
  },
  {
    field: "case_num_variance",
    headerName: strings.collectors_dataExploration_tab_column_case_num_variance,
    renderCell: ({ row }) => (
      <Typography>{`${row.case_num_variance}`}</Typography>
    ),
    width: 160,
    sortable: false,
  },
  {
    field: "top_values",
    headerName: strings.collectors_dataExploration_tab_column_top_value,
    renderCell: ({ row }) => (
      <HtmlTooltip title={row?.top_values[0].value}>
        <Typography className="topValue">{row?.top_values[0].value}</Typography>
      </HtmlTooltip>
    ),
    width: 200,
    sortable: false,
  },
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (_, open) => <RowExpandCollapseButton open={open} />,
    width: 80,
  },
];

const RowExpandCollapseButton = ({ open }) => {
  const strings = useLocalizedStrings();

  return (
    <IconButton
      className={`expandButton +  ${open ? "rotate180" : ""}`}
      title={strings.account_settings_system_log_table_details_title}
      size="small"
    >
      <ExpandMoreIcon />
    </IconButton>
  );
};

const StyledDataAnalysisTab = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  height: "Calc(100vh - 200px)",
  justifyContent: "center",
  width: "100%",

  "& .noData": {
    textAlign: "center",
    whiteSpace: "pre-wrap",
    width: "40em",
  },
}));

const StyledDataAnalysisTable = styled("div")(() => ({
  display: "grid",
  gridTemplateRows: "min-content 1fr",
  height: "calc(100vh - var(--appbar-height) - 100px)",

  "& .dataExplorationToolbar": {
    alignItems: "center",
    display: "flex",
    height: "40px",
  },
  "& .dataExplorationSearchWrapper": {
    display: "flex",
    flexGrow: 1,
    "& .text-search": {
      height: "30px",
    },
  },
  "& .dataExplorationInfoStripWrapper": {
    alignItems: "center",
    display: "flex",
    "& .MuiButtonBase-root": {
      height: "30px !important",
    },
  },
  "& .dataExplorationInfoStrip": {
    borderRight: "1px solid #ddd",
    display: "flex",
  },
  "& .detailsWrapper": {
    display: "flex",
    alignItems: "center",
  },
  "& .expandButton": {
    color: "inherit",
    transform: "rotate(0)",
    transition: "transform 200ms ease",
    "&.rotate180": {
      transform: "rotate(180deg)",
    },
  },
  "& .expandableRow": {
    "& .MuiTableCell-root": {
      "& .MuiTypography-root": {
        transition: "font-size 200ms ease",
      },
    },
  },
  "& .rowExpantion": {
    "& > .MuiTableCell-root": {},
    "&:not(.visible) > .MuiTableCell-root": {
      border: "none",
    },
  },
  "& .systemLogDetails": {
    display: "flex",
    justifyContent: "center",
    padding: 10,
    "& pre": {
      fontFamily: "monospace",
      whiteSpace: "pre",
    },
  },
  "& .topValuesTable": {
    position: "relative !important",
  },

  "& .infobar": {
    fontWeight: "bold",
  },
  "& .topValue": {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
}));

const RowDetails = ({ item }) => {
  return (
    <Table>
      <TableBody>
        <TableRow key={`${item.id}/top_values`}>
          <TableCell style={{ padding: "0.5px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "1em", fontWeight: "bold" }}>
                    <div>#</div>
                  </TableCell>
                  <TableCell style={{ width: "2em", fontWeight: "bold" }}>
                    <div>Appearances</div>
                  </TableCell>
                  <TableCell style={{ width: "8em", fontWeight: "bold" }}>
                    <div>Value</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item.top_values.map(({ value, appearances }, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ border: "none" }}>
                      {index + 1}
                    </TableCell>
                    <TableCell style={{ border: "none" }}>
                      {appearances}
                    </TableCell>
                    <TableCell
                      style={{ wordWrap: "break-word", border: "none" }}
                    >
                      {value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const DataAnalysisTable = ({ collector, run }) => {
  const strings = useLocalizedStrings();
  const [filters, setFilters] = useCurrentUserSettings(
    "dataAnalysis.filters",
    {}
  );
  const [orderBy, setOrderBy] = useCurrentUserSettings(
    "dataAnalysis.orderBy",
    {}
  );
  const [textSearchPattern, setTextSearchPattern] = useState(
    filters?.["name"]?.operand || ""
  );
  const [openFilters, setOpenFilters] = useStateDeep(false);
  const [columnIds, setColumnIds] = useState([]);
  const [explorationDialogOpen, setExplorationDialogOpen] = useState(false);
  const { isRunning } = useScenarioJobState(collector?.id);

  const { data: columnsAvailable } = useDataAnalysisColumnsAvailableQuery({
    collectorId: collector?.id,
    runId: run?.id,
  });
  const { mutate: runColumnJob } = useDataAnalysisColumnJobMutation();
  const { data, isPending, cancel, fetchNextPage } =
    useDataAnalysisColumnsInfiniteQuery({
      id: collector?.id,
      runId: run?.id,
      orderBy,
      filters,
    });

  const noRows = data?.rows?.length === 0;

  const handleResetFilters = () => {
    cancel();
    setFilters({});
    setTextSearchPattern("");
  };

  const handleChangeFilters = (f) => {
    cancel();
    setFilters((prev) => {
      if (prev?.["name"]?.operand && !f?.["name"]?.operand) {
        setTextSearchPattern("");
      }
      return f;
    });
  };

  // apply text-search changes into current filters
  useEffect(() => {
    if (textSearchPattern) {
      setFilters((prev) => {
        const f = {
          ...prev,
          name: {
            operator: "contains",
            operand: textSearchPattern,
          },
        };
        return f;
      });
    } else {
      setFilters((prev) => {
        const o = { ...prev };
        if (o["name"]) {
          delete o["name"];
          return o;
        }
        return prev;
      });
    }
  }, [setFilters, textSearchPattern]);

  const handleGridNextSortDirection = (newSortModel) => {
    cancel();
    let o = {};
    if (newSortModel.length) {
      const s = newSortModel[0].sort === "asc" ? 1 : -1;
      o = { [newSortModel[0].field]: s };
    }
    setOrderBy(o);
  };

  const downloadTableCsvUrl = useMemo(() => {
    const limit = 10_000;
    const fetchUrl = CollectorsAPI.getCollectorDataAnalysisColumns(
      collector?.id,
      run?.id,
      0,
      limit,
      filters,
      orderBy
    );
    fetchUrl.url += "&format=csv";

    return fetchUrl.url;
  }, [collector?.id, run?.id, filters, orderBy]);

  const downloadColumnsCsvUrl = useMemo(() => {
    const limit = 10_000;
    let { url } = CollectorsAPI.getCollectorDataAnalysisColumnValues(
      collector?.id,
      run?.id,
      0,
      limit,
      {},
      {}
    );
    url += "&format=csv";
    return url;
  }, [collector?.id, run?.id]);

  const handleExplore = (type, applyFilter) => {
    runColumnJob(
      {
        collectorId: collector?.id,
        columnIds,
        explorationMode: type,
        filtering: applyFilter,
      },
      {
        onSuccess: () => setColumnIds([]),
      }
    );
  };

  const columns = useMemo(() => fields(strings), [strings]);

  const parsedRows = useMemo(() => {
    if (!data?.rows) {
      return [];
    }
    return data.rows.map((row) => {
      row.isRunning = isRunning;
      return row;
    });
  }, [data?.rows, isRunning]);

  const handleCheckBoxCheck = useCallback(
    (cIds) => {
      setColumnIds(cIds);
    },
    [setColumnIds]
  );

  return (
    <>
      <StyledDataAnalysisTable>
        <div className="dataExplorationToolbar">
          <UiPrimaryButton
            disabled={isRunning || columnIds.length === 0}
            onClick={() => setExplorationDialogOpen(true)}
            style={{
              height: "30px",
              marginLeft: "5px",
            }}
          >
            {strings.collectors_columns_tab_explore_button}
          </UiPrimaryButton>
          <div className="dataExplorationSearchWrapper">
            <FreeSearchStrip
              filters={filters}
              schema={filtersSchema(strings)}
              setFilters={handleChangeFilters}
              openFilters={openFilters}
              setOpenFilters={setOpenFilters}
              searchPattern={textSearchPattern}
              setSearchPattern={setTextSearchPattern}
            />
          </div>
          <div className="dataExplorationInfoStripWrapper">
            <ButtonPopover>
              <MenuList>
                <MenuItem>
                  <RemoteDownloader
                    disabled={noRows}
                    filename={`Data_Exploration_${collector?.id}_${inUtc(
                      new Date(run.created)
                    ).format("YYYY-MM-DD")}.csv`}
                    url={downloadTableCsvUrl}
                  >
                    <a disabled={noRows}>
                      {
                        strings.collectors_dataExploration_tab_button_fields_table_download_csv
                      }
                    </a>
                  </RemoteDownloader>
                </MenuItem>
                <MenuItem>
                  <RemoteDownloader
                    disabled={!columnsAvailable}
                    filename={`Data_Exploration_Fields_${collector?.id}${inUtc(
                      new Date(run.created)
                    ).format("YYYY-MM-DD_HH-mm-ss")}.csv`}
                    url={downloadColumnsCsvUrl}
                    text={
                      strings.collectors_dataExploration_tab_button_values_analysis_download_csv
                    }
                  >
                    <a disabled={!columnsAvailable}>
                      {
                        strings.collectors_dataExploration_tab_button_values_analysis_download_csv
                      }
                    </a>
                  </RemoteDownloader>
                </MenuItem>
              </MenuList>
            </ButtonPopover>

            <div className="dataExplorationInfoStrip">
              <InformationStripItem
                label={strings.collectors_dataExploration_tab_infobar_created}
                style={{
                  background: "inherit",
                  borderBottom: "none",
                  minWidth: "10rem",
                }}
              >
                {timeFormatter(new Date(run.created))}
              </InformationStripItem>
              <InformationStripItem
                label={strings.collectors_dataExploration_tab_infobar_files}
                style={{ background: "inherit", minWidth: "4rem" }}
              >
                {run.files.length}
              </InformationStripItem>
              <InformationStripItem
                label={strings.collectors_dataExploration_tab_infobar_records}
                style={{ background: "inherit", minWidth: "4rem" }}
              >
                {run.records}
              </InformationStripItem>
            </div>
          </div>

          <IconButton
            onClick={handleResetFilters}
            size="small"
            style={{ margin: "0 10px" }}
            title="Reset filters"
          >
            <ClearIcon />
          </IconButton>
        </div>
        <DataGridINF
          RowDetails={RowDetails}
          columns={columns}
          items={parsedRows}
          count={data?.count ? Number(data?.count) : 0}
          loading={isPending}
          fetchNextPage={fetchNextPage}
          orderBy={orderBy}
          onOrderBy={handleGridNextSortDirection}
          handleCheckBoxCheck={handleCheckBoxCheck}
          isRowSelectable={() => !isRunning}
          disableColumnMenu
        />
        <FiltersSection
          filters={filters}
          schema={filtersSchema(strings)}
          setFilters={setFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          top={35}
        />
      </StyledDataAnalysisTable>
      <DataAnalysisDialog
        collector={collector}
        onClose={() => setExplorationDialogOpen(false)}
        onExplore={handleExplore}
        open={explorationDialogOpen}
        key={explorationDialogOpen}
      />
    </>
  );
};

const DataAnalysisTab = ({ collector, run }) => {
  const strings = useLocalizedStrings();
  const { isDataExplorationRunning } = useScenarioJobState(collector?.id);

  if (isDataExplorationRunning && !run?.id) {
    return (
      <StyledDataAnalysisTab>
        <Typography variant="h6" className="noData">
          {strings.collectors_datatab_data_exploration_in_progress_message}
        </Typography>
      </StyledDataAnalysisTab>
    );
  }
  if (!run?.id) {
    return (
      <StyledDataAnalysisTab>
        <Typography variant="h6" className="noData">
          {strings.collectors_datatab_no_result_message}
        </Typography>
      </StyledDataAnalysisTab>
    );
  }

  return <DataAnalysisTable collector={collector} run={run} />;
};

export default DataAnalysisTab;
