import { memo, useCallback, useMemo, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, Typography, styled } from "@mui/material";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

import { useCollectorsFileCatalogInfiniteQuery } from "api/collectors";
import { useDataAnalysisJobMutation } from "api/scheduler";

import FiltersSection from "components/scenarios/FiltersSection";
import FreeSearchStrip from "components/scenarios/FreeSearchStrip";
import DataGridINF from "components/ui/DataGridINF";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import FilterOperators from "utils/FilterOperators";
import { inUtc } from "utils/time-fmt";

import { useCurrentUserSettings } from "hooks/currentUserSettings";
import { useStateDeep } from "hooks/deepcomp";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";
import useScenarioJobState from "hooks/useScenarioJobState";

import { DataAnalysisDialog } from "./DataAnalysisDialog";

const operators = FilterOperators;

const filtersSchema = (strings) =>
  Object.entries({
    batch: {
      label: strings.collectors_datatab_column_file_name,
      type: "text",
      operators: [
        operators["eq"],
        operators["contains"],
        operators["not-contains"],
        operators["in"],
        operators["notin"],
        operators["ne"],
      ],
    },
    type: {
      label: strings.collectors_datatab_column_file_type,
      type: "text",
      operators: [operators["eq"], operators["in"], operators["notin"]],
    },
    created: {
      label: strings.collectors_datatab_column_created,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    num_records: {
      label: strings.collectors_datatab_column_num_records,
      type: "number",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    oldest_event_time: {
      label: strings.collectors_datatab_column_oldest_event,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
    most_recent_event_time: {
      label: strings.collectors_datatab_column_newest_event,
      type: "datetime-local",
      operators: [
        operators["lt"],
        operators["le"],
        operators["eq"],
        operators["ne"],
        operators["gt"],
        operators["ge"],
      ],
    },
  });

const dateFormat = (date) => {
  return !date ? "N/A" : inUtc(date).format("MMM D YYYY HH:mm:ss");
};

const showCheckBox = ({ num_records, path, status, type }) => {
  return Number(num_records) > 0 && path && status === "ok" && type === "flat";
};

const CheckboxRenderCell = memo((props) =>
  showCheckBox(props.row)
    ? GRID_CHECKBOX_SELECTION_COL_DEF.renderCell(props)
    : null
);

const fields = (strings) => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    renderCell: (props) => <CheckboxRenderCell {...props} />,
  },
  {
    field: "name",
    headerName: strings.collectors_datatab_column_name,
    renderCell: ({ row }) => (
      <Typography className="nameCell">{row.batch}</Typography>
    ),
    width: 400,
    sortable: false,
  },
  {
    field: "type",
    headerName: strings.collectors_datatab_column_file_type,
    renderCell: ({ row }) => <Typography>{row.type}</Typography>,
    width: 160,
    sortable: false,
  },
  {
    field: "encryption",
    headerName: strings.collectors_dataExploration_tab_column_encyption,
    renderCell: ({ row }) => {
      return (
        <Typography>
          {row.is_encrypted
            ? strings.collectors_dataExploration_tab_column_encyption_applied
            : strings.collectors_dataExploration_tab_column_encyption_not_applied}
        </Typography>
      );
    },
    width: 160,
    sortable: false,
  },
  {
    field: "created",
    headerName: strings.collectors_datatab_column_created,
    renderCell: ({ row }) => <Typography>{dateFormat(row.created)}</Typography>,
    width: 240,
  },
  {
    field: "num_records",
    headerName: strings.collectors_datatab_column_num_records,
    renderCell: ({ row }) => (
      <Typography variant="body1">{+row.num_records}</Typography>
    ),
    width: 200,
  },
  {
    field: "oldest_event_time",
    headerName: strings.collectors_datatab_column_oldest_event,
    renderCell: ({ row }) => (
      <Typography>{dateFormat(row.oldest_event_time)}</Typography>
    ),
    width: 320,
  },
  {
    field: "most_recent_event_time",
    headerName: strings.collectors_datatab_column_newest_event,
    renderCell: ({ row }) => (
      <Typography>{dateFormat(row.most_recent_event_time)}</Typography>
    ),
    width: 320,
  },
  {
    field: "raw_file",
    headerName: strings.collectors_datatab_column_raw_file,
    renderCell: ({ row }) => <Typography>{row.raw_file}</Typography>,
    width: 280,
  },
];

const StyledTableArea = styled("div")(() => ({
  display: "grid",
  gridTemplateColumns: "100%",
  gridTemplateRows: "min-content 1fr",
  height: "calc(100vh - var(--appbar-height) - 100px)",
  position: "relative",
}));

const StyledExploreToolbar = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  height: "40px",
}));

const DataFilesTable = ({ collector }) => {
  const FILE_SELECTION_LIMIT = 50;
  const pageLimit = 20;
  const strings = useLocalizedStrings();
  const [filters, setFilters] = useCurrentUserSettings(
    "dataColumns.filters",
    {}
  );
  const [orderBy, setOrderBy] = useCurrentUserSettings(
    "dataColumns.orderby",
    {}
  );
  const [openFilters, setOpenFilters] = useStateDeep(false);
  const { isRunning } = useScenarioJobState(collector?.id);
  const [selection, setSelection] = useState(new Set());
  const [explorationDialogOpen, setExplorationDialogOpen] = useState(false);
  const { pushMessage } = useMessages();

  const { mutate: runDataAnalysis } = useDataAnalysisJobMutation();
  const { data, fetchNextPage, cancel, isPending } =
    useCollectorsFileCatalogInfiniteQuery({
      id: collector?.id,
      filters,
      orderBy,
      pageLimit,
    });

  const parsedRows = useMemo(() => {
    if (!data?.rows) {
      return [];
    }
    return data.rows.map((row) => {
      row.isRunning = isRunning;
      return row;
    });
  }, [data?.rows, isRunning]);

  const handleGridNextSortDirection = (newSortModel) => {
    cancel();
    let o = {};
    if (newSortModel.length) {
      const s = newSortModel[0].sort === "asc" ? 1 : -1;
      o = { [newSortModel[0].field]: s };
    }
    setOrderBy(o);
  };

  const handleResetFilters = () => {
    cancel();
    setFilters({});
  };

  const handleChangeFilters = (f) => {
    cancel();
    setFilters(f);
  };

  const handleExplore = (type, applyFilter) => {
    if (selection.size > FILE_SELECTION_LIMIT) {
      pushMessage(
        "error",
        strings.formatString(
          strings.collectors_dataExploration_tab_error_too_many_files,
          FILE_SELECTION_LIMIT
        )
      );
      return;
    }
    runDataAnalysis({
      collectorId: collector.id,
      fileIds: selection,
      explorationMode: type,
      filtering: applyFilter,
    });
  };

  const handleCheckBoxCheck = useCallback(
    (fIds) => {
      setSelection(new Set(fIds));
    },
    [setSelection]
  );

  const columns = useMemo(() => fields(strings), [strings]);

  return (
    <StyledTableArea>
      <StyledExploreToolbar>
        <UiPrimaryButton
          disabled={isRunning || data?.rows.length === 0}
          onClick={() => setExplorationDialogOpen(true)}
          style={{ marginLeft: "5px", height: "30px" }}
        >
          {strings.collectors_files_tab_explore_button}
        </UiPrimaryButton>

        <FreeSearchStrip
          hideSearchInput
          filters={filters}
          schema={filtersSchema(strings)}
          setFilters={handleChangeFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          searchPattern={null}
          setSearchPattern={null}
        />
        <IconButton
          size="small"
          onClick={handleResetFilters}
          title="Reset filters"
        >
          <ClearIcon />
        </IconButton>
      </StyledExploreToolbar>
      <DataGridINF
        columns={columns}
        items={parsedRows}
        count={data?.count ? Number(data?.count) : 0}
        loading={isPending}
        fetchNextPage={fetchNextPage}
        orderBy={orderBy}
        onOrderBy={handleGridNextSortDirection}
        handleCheckBoxCheck={handleCheckBoxCheck}
        isRowSelectable={({ row }) => showCheckBox(row) && !isRunning}
        disableMultipleRowSelection={isRunning}
        disableColumnMenu
      />

      <FiltersSection
        filters={filters}
        schema={filtersSchema(strings)}
        setFilters={setFilters}
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
      />
      <DataAnalysisDialog
        collector={collector}
        onClose={() => setExplorationDialogOpen(false)}
        onExplore={handleExplore}
        open={explorationDialogOpen}
        key={explorationDialogOpen}
        sampleExplorationLabel={
          strings.collectors_files_tab_explore_dialog_sample_exploration
        }
        fullExplorationLabel={
          strings.collectors_files_tab_explore_dialog_full_exploration
        }
        filesExplorationTitle={
          strings.collectors_files_tab_explore_dialog_title
        }
        fileSelection={selection.size}
      />
    </StyledTableArea>
  );
};

export default DataFilesTable;
