import { useState } from "react";

import { Slider, useTheme } from "@mui/material";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const getStepValuesAndLabels = (config) => {
  const numericKeys = Object.keys(config)
    .filter((key) => !isNaN(Number(key)))
    .map(Number)
    .sort((a, b) => a - b);

  const maxValue = Math.max(...numericKeys);
  const values = [...numericKeys, maxValue * 10];
  const labels = [...Object.values(config), config.else];

  return { values, labels };
};

export const RelevanceSelector = ({ config, setRelevance }) => {
  const { values: stepValues, labels: stepLabels } =
    getStepValuesAndLabels(config);
  const isMinSelected = stepValues[0] === stepValues[0];
  const strings = useLocalizedStrings();
  const theme = useTheme();

  const [value, setValue] = useState([0, stepValues.length - 1]);

  const handleChange = (_, newValue) => {
    const newRelevance = newValue.map((v) =>
      v === 0 ? 0 : stepValues[v - (isMinSelected ? 1 : 0)]
    );

    newRelevance[1] = stepValues[newValue[1]];

    setValue(newValue);
    setRelevance(newRelevance);
  };

  const valueLabelFormat = (index) => stepLabels[index] || "";

  const marks = stepValues.map((_, index) => ({
    value: index,
    label: stepLabels[index],
  }));

  return (
    <div
      style={{
        padding: "0 8px",
        display: "inline-grid",
        gridTemplateRows: "1em 1em",
        gap: "0.3em",
      }}
    >
      <span style={{ alignSelf: "center" }}>
        {strings.sessiondetails_timeline_relevance_selector}
      </span>

      <Slider
        value={value}
        sx={{
          "&.MuiSlider-root": {
            marginBottom: "0px",
            marginTop: "1px",
          },
          "& .MuiSlider-track, & .MuiSlider-rail": {
            height: 8,
            backgroundImage: `linear-gradient(90deg, ${theme.palette.secondary.purple} 63%, black)`,
            opacity: 1,
            border: 0,
          },
          "& .MuiSlider-thumb": {
            backgroundColor: "RGBA(221, 221, 221, 0)",
            border: "2px solid currentColor",
          },
          "& .MuiSlider-markLabel": {
            display: "none",
          },
        }}
        min={0}
        size="small"
        max={stepValues.length - 1}
        step={1}
        marks={marks}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        onChange={handleChange}
      />
    </div>
  );
};
