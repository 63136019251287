import { styled } from "@mui/material";

const StyledHamburger = styled("div")`
  position: relative;
  width: 1em;
  height: 1em;
  background-color: transparent;
  .icon {
    --width: 3px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    height: var(--width);
    background-color: ${({ theme }) => theme.palette.purple.dark};
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: var(--width);
      background-color: ${({ theme }) => theme.palette.purple.dark};
      transition: transform 150ms ease;
      transform-origin: center;
    }
    &::before {
      top: calc((var(--width) + 2px) * -1);
    }
    &::after {
      top: calc((var(--width) + 2px) * 1);
    }
    &.hovered {
      &::before,
      &::after {
        left: unset;
        right: -5px;
      }
      &::before {
        transform: rotate(45deg) scaleX(80%) translateY(2px);
      }
      &::after {
        transform: rotate(-45deg) scaleX(80%) translateY(-2px);
      }
    }
  }
`;

export default function Hamburger({ hovered }) {
  return (
    <StyledHamburger>
      <div className={`icon ${hovered ? "hovered" : ""}`} />
    </StyledHamburger>
  );
}
