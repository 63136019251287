import { useState } from "react";

import { CloudDownloadOutlined, Delete } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import {
  useAccountFileMutation,
  useDeleteFileMutation,
  useDownloadFileQuery,
  useGetFilesQuery,
} from "api/files";

import Flexbox from "components/ui/Flexbox";
import GeneralizedTable from "components/ui/GeneralizedTable";
import HtmlTooltip from "components/ui/HtmlTooltip";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import NotAllowedMessage from "components/ui/NotAllowedMessage";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import { CheckboxInput } from "components/ui/form/CheckboxInput";
import FileInput from "components/ui/form/FileInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { timeFormatter } from "utils/time-fmt";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import { BaseAccountPage } from "./AccountPageContent";

const columns = (strings) => [
  {
    id: "actions",
    width: "20px",
    render: (f, actions, caps) => {
      const deleteFileTooltipString = () => {
        if (!!f?.sources?.length) {
          const stringType =
            f?.sources?.length > 1
              ? strings.account_settings_files_delete_disabled_tooltip_plural
              : strings.account_settings_files_delete_disabled_tooltip_singular;

          return strings.formatString(stringType, f?.sources?.length);
        }
        return "";
      };

      return (
        <Flexbox sx={{ gap: "5px" }}>
          <IconButton
            size="small"
            disabled={f.secret}
            onClick={() => actions.onDownload(f)}
          >
            <CloudDownloadOutlined />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => actions.onEdit(f)}
            disabled={!caps({ "acm.files": { write: true } })}
          >
            <EditIcon />
          </IconButton>
          <HtmlTooltip title={deleteFileTooltipString()}>
            <div>
              <IconButton
                size="small"
                disabled={!!f?.sources?.length}
                onClick={() => actions.onDelete(f)}
              >
                <Delete />
              </IconButton>
            </div>
          </HtmlTooltip>
        </Flexbox>
      );
    },
  },
  {
    id: "name",
    label: strings.account_settings_files_column_name,
    width: "8em",
    render: (f) => <Typography>{f.name}</Typography>,
  },
  {
    id: "created",
    label: strings.account_settings_files_column_created,
    width: "10em",
    render: (f) => <Typography>{timeFormatter(f.created)}</Typography>,
  },
  {
    id: "updated",
    label: strings.account_settings_files_column_updated,
    width: "10em",
    render: (f) => (
      <Typography>{f.updated ? timeFormatter(f.updated) : ""}</Typography>
    ),
  },
  {
    id: "size",
    label: strings.account_settings_files_column_size,
    width: "10em",
    render: (f) => (
      <Typography>
        {f.size ?? strings.account_settings_files_no_content}
      </Typography>
    ),
  },
  {
    id: "secret",
    label: strings.account_settings_files_secret,
    width: "5em",
    render: (f) => <Typography>{f.secret.toString()}</Typography>,
  },
  {
    id: "used_by",
    label: strings.account_settings_files_column_used,
    width: "20em",
    render: (f) => (
      <Typography whiteSpace={"pre-wrap"}>
        {f.sources.length ? f.sources.join(",\n") : "None"}
      </Typography>
    ),
  },
];

const FilesDialog = ({ open, onClose, fileNames, selectedFile }) => {
  const strings = useLocalizedStrings();
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      name: selectedFile?.name ?? "",
      secret: selectedFile?.secret ?? false,
    },
  });
  const { mutateAsync } = useAccountFileMutation();
  const isEditing = !!selectedFile;
  const submitDisabled = methods.formState.isSubmitting;

  const validateFileSize = ({ size }, isSecret) => {
    const maxSize = isSecret ? 1 * 1024 * 1024 : 50 * 1024 * 1024;
    const maxSizeMB = isSecret ? 1 : 50;

    return size <= maxSize
      ? true
      : strings.formatString(
          strings.account_settings_files_dialog_size_error,
          maxSizeMB
        );
  };

  const title = isEditing
    ? strings.account_settings_files_dialog_edit
    : strings.account_settings_files_dialog_create;

  const onAccept = async (formData) => {
    try {
      await mutateAsync(
        { id: selectedFile?.id, ...formData },
        { onSuccess: () => onClose() }
      );
    } catch {}
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onAccept)}>
          <DialogTitle>{title}</DialogTitle>

          <DialogContent
            sx={{
              width: "450px",
              height: "250px",
              display: "flex",
              placeContent: "center",
              gap: "15px",
              flexDirection: "column",
              paddingTop: "20px",
            }}
          >
            <TextFieldInput
              label={strings.account_settings_files_dialog_file_label}
              name="name"
              required={true}
              rules={{
                pattern: {
                  value: /^[\sA-Za-z0-9]+$/,
                  message: strings.invalid_input,
                },
                validate: {
                  nonEmpty: (v) =>
                    v.trim().length !== 0 || strings.invalid_input,
                  unique: (v) =>
                    !fileNames.includes(v) ||
                    strings.account_settings_files_dialog_name_error,
                },
              }}
            />
            <FileInput
              accept="text/csv"
              name="content"
              required={!isEditing}
              rules={{
                validate: (val, formValues) =>
                  val ? validateFileSize(val, formValues.secret) : true,
              }}
              style={{ width: "400px" }}
            />
            <CheckboxInput
              name="secret"
              checked={false}
              disabled={isEditing}
              label={strings.account_settings_files_dialog_checkbox_label}
            />
          </DialogContent>

          <DialogActions>
            <UiSecondaryButton onClick={() => onClose()}>
              {strings.button_cancel}
            </UiSecondaryButton>
            <UiPrimaryButton type="submit" disabled={submitDisabled}>
              {isEditing ? strings.button_save : strings.button_add}
            </UiPrimaryButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export const AccountFiles = () => {
  const strings = useLocalizedStrings();
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteContext, setDeleteContext] = useState();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const caps = useCapabilities();

  const { data: files } = useGetFilesQuery();
  const { mutate: downloadFile } = useDownloadFileQuery();
  const { mutate: deleteFile } = useDeleteFileMutation();

  const fileNames = files
    ?.map((f) => f.name)
    ?.filter((name) => name !== selectedFile?.name);

  const handleDownload = ({ name, id }) => downloadFile({ name, id });

  const handleDeleteDialogOpen = (file) => {
    setDeleteContext(file);
    setDeleteDialogOpen(true);
  };

  const handleDelete = (ctx) => {
    deleteFile(ctx);
  };

  const handleOpen = () => {
    setSelectedFile();
    setOpenDialog(true);
  };

  const handleClose = () => {
    setSelectedFile();
    setOpenDialog(false);
  };

  const handleEdit = (file) => {
    setSelectedFile(file);
    setOpenDialog(true);
  };

  if (!caps({ "acm.files": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <BaseAccountPage>
      <Flexbox>
        <UiPrimaryButton
          startIcon={<AddCircleIcon />}
          onClick={() => handleOpen()}
          disabled={!caps({ "acm.files": { write: true } })}
        >
          {strings.button_add}
        </UiPrimaryButton>
      </Flexbox>

      <GeneralizedTable
        items={files}
        columns={columns}
        caps={caps}
        strings={strings}
        actions={{
          onDelete: handleDeleteDialogOpen,
          onDownload: handleDownload,
          onEdit: handleEdit,
        }}
      />

      <FilesDialog
        key={openDialog}
        fileNames={fileNames}
        selectedFile={selectedFile}
        open={openDialog}
        onClose={handleClose}
      />

      <MultipleOptionsDialog
        confirmText={strings.button_delete}
        onConfirm={handleDelete}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        context={deleteContext}
        title={strings.account_settings_files_delete_dialog_title}
        text={strings.account_settings_files_delete_dialog_message}
      />
    </BaseAccountPage>
  );
};
