import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import { useMessagesStore } from "stores/useMessagesStore";

const pushMessage = useMessagesStore.getState().pushMessage;

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => pushMessage("error", error.message),
  }),
  defaultOptions: {
    queries: {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage?.length < 20) {
          return;
        }
        return allPages.length + 1;
      },
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: (error) => pushMessage("error", error.message),
    },
  },
});

export const QueryProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);
