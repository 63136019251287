import { forwardRef, useMemo } from "react";

import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import DataLink from "components/ui/DataLink";
import HtmlTooltip from "components/ui/HtmlTooltip";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import { useFieldInsightData } from "./FieldInsightData";
import { SessionPropertiesTooltip } from "./SessionPropertiesTooltip";
import { useSessionSummaryFields } from "./SessionSummaryFields";

const ActionProperty = ({ property, num_values, top_values }) => {
  const strings = useLocalizedStrings();

  let values = top_values.map((v) => `${v.value} (${v.num_values})`).join(",");

  if (num_values > 5) {
    values += "...";
  }

  return (
    <Typography variant="body2">
      {property}
      <span style={{ color: "gray" }}>
        {strings.formatString(
          strings.action_properties_unique_values,
          num_values
        )}
      </span>
      {values}
    </Typography>
  );
};

const ActionProperties = ({ properties }) => {
  const strings = useLocalizedStrings();
  return (
    <div style={{ padding: "2px 5px" }}>
      <Typography variant="body1">{strings.action_properties_title}</Typography>
      <ul style={{ paddingLeft: "10px" }}>
        {properties.map((p) => (
          <li key={p.property} style={{ padding: "5px 2px" }}>
            <ActionProperty
              property={p.property}
              num_values={p.num_values}
              top_values={p.top_values}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const ActionRow = forwardRef(({ value, ...props }, ref) => {
  return (
    <Typography fontSize={14} ref={ref} {...props}>
      {value.replace(/\.$/, "")}
    </Typography>
  );
});

const SingleActionRow = forwardRef(
  ({ row, totals, isPendingTagTotals, ...props }, ref) => {
    const fieldInsightData = useFieldInsightData();
    const strings = useLocalizedStrings();
    let [key, value] = row.e.split("=");

    if (value === undefined) {
      value = key;
      key = strings.tagsview_untitled.toLowerCase();
    }

    const type = "sessions_found";
    const field = fieldInsightData?.[key]?.[type]?.[value]?.[0];

    const titleTooltipContent = useMemo(() => {
      return (
        <SessionPropertiesTooltip
          fieldName={key}
          fromDate={totals?.from_date}
          isPendingTagTotals={isPendingTagTotals}
          toDate={totals?.to_date}
          totalSessions={totals?.num_sessions}
          value={value}
          withoutFirstRow={true}
        />
      );
    }, [
      isPendingTagTotals,
      key,
      totals?.from_date,
      totals?.num_sessions,
      totals?.to_date,
      value,
    ]);

    return field ? (
      <HtmlTooltip title={titleTooltipContent}>
        <Typography
          fontSize={14}
          sx={{
            backgroundColor: "info.dark",
            color: "white",
            padding: "3px",
            width: "fit-content",
          }}
          key={row.e}
          ref={ref}
          {...props}
        >
          {row.e}
        </Typography>
      </HtmlTooltip>
    ) : (
      <Typography fontSize={14} key={row.e} sx={{ width: "fit-content" }}>
        {row.e}
      </Typography>
    );
  }
);

const ActionRows = ({ action, totals, isPendingTagTotals }) => {
  const rows = useMemo(() => {
    const events = action.split("\t");
    const rows = [];
    for (const e of events) {
      if (e !== "") {
        rows.push({ e });
      }
    }
    return rows;
  }, [action]);

  return rows.map((r) => {
    return (
      <SingleActionRow
        row={r}
        key={r.e}
        totals={totals}
        isPendingTagTotals={isPendingTagTotals}
      />
    );
  });
};

export const ActionText = ({
  description,
  action,
  translate,
  properties,
  totals,
  isPendingTagTotals,
}) => {
  const { scenario, session } = useParams();
  const summaryFields = useSessionSummaryFields();
  let actionText = translate ? (
    <ActionRow style={{ cursor: "pointer" }} value={description || action} />
  ) : (
    <ActionRows
      action={action}
      summaryFields={summaryFields}
      totals={totals}
      isPendingTagTotals={isPendingTagTotals}
    />
  );

  let tooltipContent, propertyFields;
  if (properties?.length) {
    tooltipContent = translate ? (
      <ActionProperties properties={properties} />
    ) : null;
    propertyFields = properties.map((p) => p.field);
  }

  let actionDisplay = tooltipContent ? (
    <HtmlTooltip title={tooltipContent}>{actionText}</HtmlTooltip>
  ) : (
    actionText
  );

  if (translate) {
    let pathname = `/scenarios/${scenario}/sessions/${session}/session-timeline`;
    return (
      <DataLink
        to={{ pathname, state: { action, propertyFields, summaryFields } }}
      >
        {actionDisplay}
      </DataLink>
    );
  }

  return actionDisplay;
};
