import { Typography, styled } from "@mui/material";

const StyledFrameTitleText = styled(Typography)(() => ({
  letterSpacing: "2px",
  textTransform: "uppercase",
  fontWeight: "bold",
}));

export default function FrameTitleText({ children }) {
  return (
    <StyledFrameTitleText variant="subtitle2">{children}</StyledFrameTitleText>
  );
}
