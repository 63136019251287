import { useCallback, useContext, useRef, useState } from "react";

import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";

import { queryFetch } from "api/query";
import ScenariosAPI from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import { DeleteButton } from "components/ui/DeleteButton";
import FrameContent from "components/ui/FrameContent";
import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";
import { UiSecondaryButton } from "components/ui/StyledButtons";
import TableCellText from "components/ui/TableCellText";

import { timeFormatter } from "utils/time-fmt";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import CsvSettingsAnalysisEngineForm from "./CsvSettingsAnalysisEngineForm";
import SettingsOption from "./SettingsOption";

const columns = (strings) => [
  {
    id: "actions",
    label: "",
    width: "1em",
    render: (engine, setActive, onDelete) => {
      return (
        <div className="buttons-group">
          <Checkbox
            size="small"
            color="default"
            disableRipple={true}
            checked={engine.active}
            onChange={(e) => setActive?.(e.target.checked)}
          />

          <DeleteButton
            size="small"
            disableRipple={true}
            onClick={() => onDelete?.()}
          />
        </div>
      );
    },
  },
  {
    id: "description",
    label: strings.scenariosettings_analysis_engine_table_description,
    width: "15em",
    render: (engine) => engine.description,
  },
  {
    id: "uploaded",
    label: strings.scenariosettings_analysis_engine_table_uploaded,
    width: "7em",
    render: (engine) => timeFormatter(new Date(engine.uploaded)),
  },
];

const StyledAnalysisEngine = styled(FrameContent)(() => ({
  marginBottom: "1em",
  paddingLeft: "8px",
  paddingRight: "16px",
  "& > .collapse": {
    marginTop: "10px",
    height: 0,
    overflow: "hidden",
    transition: "height 0.3s ease-out",
  },

  "& > .collapse.open": {
    height: "13em",
    flexShrink: "0",
  },
  "& .engineTable": {
    marginBottom: "20px",
  },

  "& .MuiInputLabel-root": {
    top: "-5px",
  },
}));

const useAnalysisEngine = (scenarioId, { enabled = true }) => {
  const strings = useLocalizedStrings();
  const { pushMessage } = useMessages();
  const queryKey = ["scenarios", scenarioId, "settings", "analysisEngine"];

  const { loading, data, refetch } = useQuery({
    queryKey,
    queryFn: () => queryFetch(ScenariosAPI.getAnalysisEngine(scenarioId)),
    enabled: enabled,
  });

  const { mutateAsync: uploadEngine } = useMutation({
    mutationFn: ({ file, description }) =>
      queryFetch(
        ScenariosAPI.postAnalysisEngine(scenarioId, file, description)
      ),
    onSuccess: () => {
      pushMessage(
        "success",
        strings.scenariosettings_analysis_engine_uploaded_successfully_message
      );
      refetch();
    },
  });

  const { mutate: activateEngine } = useMutation({
    mutationFn: ({ active }) =>
      queryFetch(ScenariosAPI.activateAnalysisEngine(scenarioId, active)),
    onSuccess: (_data, { active }) => {
      pushMessage(
        "success",
        strings.formatString(
          strings.scenariosettings_analysis_engine_activation_message,
          active ? "activated" : "dactivated"
        )
      );
      refetch();
    },
  });

  const { mutate: deleteEngine } = useMutation({
    mutationFn: () => queryFetch(ScenariosAPI.deleteAnalysisEngine(scenarioId)),
    onSuccess: () => {
      pushMessage(
        "success",
        strings.scenariosettings_analysis_engine_deleted_successfully_message
      );
      refetch();
    },
  });

  return {
    loading,
    data,
    uploadEngine,
    activateEngine,
    deleteEngine,
  };
};

const SettingsAnalysisEngine = () => {
  const strings = useLocalizedStrings();
  const { selectedScenario } = useContext(ScenariosContext);
  const [showUpload, setShowUpload] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const opened = useRef(false);

  const { data, uploadEngine, activateEngine, deleteEngine } =
    useAnalysisEngine(selectedScenario.id, { enabled: opened.current });

  const handleUpload = async (_sid, file, description) => {
    await uploadEngine({ file, description });
    setShowUpload(false);
  };

  const handleActive = (active) => activateEngine({ active });

  const handleDelete = () => {
    setConfirmDeleteOpen(true);
  };

  const handleConfirmationDeleteAction = useCallback(() => {
    deleteEngine();
  }, [deleteEngine]);

  const handleToggleUploadForm = () => {
    setShowUpload((prev) => !prev);
  };

  const handleExpand = () =>
    setExpanded((prev) => {
      if (!prev && !opened.current) {
        opened.current = !prev;
      }
      return !prev;
    });

  return (
    <SettingsOption
      title={strings.scenariosettings_analysis_engine_title}
      open={expanded}
      onChange={handleExpand}
    >
      <StyledAnalysisEngine>
        <div className={"engineTable"}>
          {data && data?.length !== 0 ? (
            <TableContainer>
              <Table stickyHeader>
                <colgroup>
                  {columns(strings).map((c) => (
                    <col key={c.id} style={{ width: c.width }} />
                  ))}
                </colgroup>
                <TableHead>
                  <TableRow>
                    {columns(strings).map((c) => (
                      <TableCell key={c.id}>
                        <TableCellText variant="body2">{c.label}</TableCellText>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((a) => (
                    <TableRow
                      key={a.uploaded}
                      className={`${a.owner ? "owner" : ""}`}
                    >
                      {columns(strings).map((c) => (
                        <TableCell key={c.id}>
                          {c.render(a, handleActive, handleDelete)}{" "}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1">
              {strings.scenariosettings_analysis_engine_no_file_uploaded}
            </Typography>
          )}
        </div>
        <UiSecondaryButton onClick={handleToggleUploadForm}>
          {strings.scenariosettings_analysis_engine_upload_button}
        </UiSecondaryButton>
        <CsvSettingsAnalysisEngineForm
          open={showUpload}
          setOpen={setShowUpload}
          onAccept={handleUpload}
        />
      </StyledAnalysisEngine>

      <MultipleOptionsDialog
        cancelText={strings.button_no}
        confirmText={strings.button_yes}
        onConfirm={handleConfirmationDeleteAction}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        title={
          strings.scenariosettings_analysis_engine_delete_analysis_engine_title
        }
        text={
          strings.scenariosettings_analysis_engine_delete_analysis_engine_question
        }
      />
    </SettingsOption>
  );
};

export default SettingsAnalysisEngine;
