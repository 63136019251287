import { Badge, styled } from "@mui/material";

import { parseRiskColor } from "utils/parseRiskColor";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const StyledRiskIndicator = styled(Badge)(({ theme, value }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "royalblue",
    textTransform: "capitalize",
  },

  "& .circular_wrapper": {
    alignItems: "center",
    color: "inherit",
    display: "flex",
    fontFamily: "inherit",
    justifyContent: "center",
    position: "relative",
    "& .MuiCircularProgress-root": {
      position: "absolute",
    },
    "& .value": {
      color: value < 0 ? theme.palette.purple.dark : "white",
      fontFamily: "inherit",
      fontWeight: value < 0 ? "unset" : "bold",
      zIndex: 1,
    },
  },
  "& .infoStripCircle": {
    backgroundColor: parseRiskColor(value),
    borderRadius: "100vw",
    color: "white",
    display: "inline-block",
    padding: "0px 0.4em",
    textTransform: "uppercase",
  },
}));

function RiskIndicator({ reevaluated, value, link }) {
  const strings = useLocalizedStrings();
  return (
    <StyledRiskIndicator
      badgeContent={
        <span>{strings.session_info_strip_label_state_rescored}</span>
      }
      className={"badge"}
      color="secondary"
      invisible={!reevaluated}
      overlap="rectangular"
      value={value}
      variant="dot"
    >
      <div className={"circular_wrapper"}>
        <div className={"infoStripCircle"}>
          <span
            className="value"
            style={{ cursor: link ? "pointer" : "default" }}
          >
            {value >= 0 ? `${Math.round(value, 1)}%` : "N/A"}
          </span>
        </div>
      </div>
    </StyledRiskIndicator>
  );
}

export default RiskIndicator;
