import { useState } from "react";

import OpenWithIcon from "@mui/icons-material/OpenWith";
import { Grid, IconButton, styled } from "@mui/material";

import MessagesList from "components/scenarios/diagnostics/MessageList";
import Frame from "components/ui/Frame";
import FrameContent from "components/ui/FrameContent";
import FrameTitle from "components/ui/FrameTitle";
import FrameTitleText from "components/ui/FrameTitleText";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useToggle } from "hooks/useToggle";

import { DailyAlerts } from "./DailyAlerts";
import { DailyEventsCollected } from "./DailyEventsCollected";
import { RecentAlerts } from "./RecentAlerts";
import { SourcesLastStatus } from "./SourcesLastStatus";

const StyledScenarioDashboard = styled("div")(({ theme }) => ({
  fontSize: "10pt",
  height: "calc(100vh - var(--appbar-height))",
  overflowY: "auto",
  padding: "1em",
  position: "relative",

  "& .dashboard-grid": {
    left: "0",
    minHeight: "100%",
    position: "absolute",
    top: "0",
  },

  "& .table-wrapper > table": {
    "& thead > tr > th": {
      backgroundColor: `${theme.palette.sidebar.light}`,
      color: `${theme.palette.purple.dark}`,
      textTransform: "capitalize",
    },
  },
}));

const StyledDownloadIndicator = styled("div")(() => ({
  alignItems: "center",
  backgroundColor: "rgba(0, 0, 0, 0.1)",
  display: "flex",
  fontSize: "1.3rem",
  height: "100%",
  justifyContent: "center",
  position: "relative",
  textTransform: "capitalize",
  width: "100%",
}));

function DownloadIndicator() {
  const strings = useLocalizedStrings();
  return (
    <StyledDownloadIndicator>{strings.text_loading}...</StyledDownloadIndicator>
  );
}

function DashboardFrame({ title, ToolbarComponent, children, ...rest }) {
  return (
    <Frame
      {...rest}
      style={{
        margin: 0,
        padding: "10px",
        height: "35vh",
        minHeight: "19em",
        maxHeight: "25em",
        backgroundColor: "white",
        border: "5px solid #ddd",
        borderRadius: "20px",
      }}
    >
      <FrameTitle>
        <FrameTitleText>{title}</FrameTitleText>
        {ToolbarComponent && (
          <ToolbarComponent style={{ marginLeft: "auto" }} />
        )}
      </FrameTitle>
      <FrameContent>{children}</FrameContent>
    </Frame>
  );
}

const StyledDashboardFrameToolbar = styled("div")(() => ({
  alignItems: "center",
  display: "flex",
  flexGrow: "1",
  gap: "5px",
  justifyContent: "flex-end",
  "& .select-time-period": {
    border: "1px solid #ddd",
    borderRadius: "3px",
    cursor: "pointer",
    outline: "none",
    padding: "0.3rem 1rem",
  },
}));

function ScenarioFrameToolbar(props) {
  const { children, toggleExpanded } = props;
  return (
    <StyledDashboardFrameToolbar>
      {children}
      <IconButton onClick={toggleExpanded} size="small">
        <OpenWithIcon />
      </IconButton>
    </StyledDashboardFrameToolbar>
  );
}

export const ScenarioDashboard = (props) => {
  const [expandRecenAlerts, toggleExpandRecentAlerts] = useToggle();
  const [expandSourcesState, toggleSourcesState] = useToggle();
  const [expandDailyAlerts, toggleDailyAlerts] = useToggle();
  const [expandDailyEvents, toggleDailyEvents] = useToggle();
  const [recentAlertsPeriod, setRecentAlertsPeriod] = useState(1);
  const strings = useLocalizedStrings();
  return (
    <StyledScenarioDashboard>
      <Grid container spacing={1}>
        <Grid
          item
          xs={expandRecenAlerts ? 12 : 6}
          xl={expandRecenAlerts ? 12 : 6}
        >
          <DashboardFrame
            title={strings.dashboard_recent_alerts_title}
            ToolbarComponent={() => (
              <ScenarioFrameToolbar toggleExpanded={toggleExpandRecentAlerts}>
                <select
                  className="select-time-period"
                  value={recentAlertsPeriod}
                  onChange={(e) => setRecentAlertsPeriod(e.target.value)}
                >
                  <option value={1}>
                    {strings.dashboard_recent_alerts_period_24h}
                  </option>
                  <option value={7}>
                    {strings.dashboard_recent_alerts_period_week}
                  </option>
                  <option value={30}>
                    {strings.dashboard_recent_alerts_period_month}
                  </option>
                  <option value={365}>
                    {strings.dashboard_recent_alerts_period_year}
                  </option>
                </select>
              </ScenarioFrameToolbar>
            )}
          >
            <RecentAlerts
              DownloadComponent={DownloadIndicator}
              numDays={recentAlertsPeriod}
              {...props}
            />
          </DashboardFrame>
        </Grid>

        <Grid
          item
          xs={expandSourcesState ? 12 : 6}
          xl={expandSourcesState ? 12 : 6}
        >
          <DashboardFrame
            title={strings.dashboard_last_collection_title}
            ToolbarComponent={() => (
              <ScenarioFrameToolbar toggleExpanded={toggleSourcesState} />
            )}
          >
            <SourcesLastStatus
              DownloadComponent={DownloadIndicator}
              {...props}
            />
          </DashboardFrame>
        </Grid>

        <Grid
          item
          xs={expandDailyAlerts ? 12 : 6}
          xl={expandDailyAlerts ? 12 : 6}
        >
          <DashboardFrame
            title={strings.dashboard_daily_alerts_title}
            ToolbarComponent={() => (
              <ScenarioFrameToolbar toggleExpanded={toggleDailyAlerts} />
            )}
          >
            <DailyAlerts DownloadComponent={DownloadIndicator} {...props} />
          </DashboardFrame>
        </Grid>

        <Grid
          item
          xs={expandDailyEvents ? 12 : 6}
          xl={expandDailyEvents ? 12 : 6}
        >
          <DashboardFrame
            title={strings.dashboard_daily_events_title}
            ToolbarComponent={() => (
              <ScenarioFrameToolbar toggleExpanded={toggleDailyEvents} />
            )}
          >
            <DailyEventsCollected
              DownloadComponent={DownloadIndicator}
              {...props}
            />
          </DashboardFrame>
        </Grid>
        <Grid item xs={12} xl={12}>
          <MessagesList selectedScenarioId={props.scenario} />
        </Grid>
      </Grid>
    </StyledScenarioDashboard>
  );
};
