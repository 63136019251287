import { useCallback, useContext, useMemo, useRef, useState } from "react";
import { useLayoutEffect } from "react";

import { Box, Typography } from "@mui/material";
import {
  DataGridPro,
  GridFooterContainer,
  GridPagination,
  GridToolbarDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid-pro";

import { ScenariosContext } from "contexts/ScenariosContext";
import { TagsVisibilityContext } from "contexts/TagsVisibilityContext";

import HtmlTooltip from "components/ui/HtmlTooltip";
import { PageLoadingSpinner } from "components/ui/PageLoadingSpinner";
import RarityIndicator from "components/ui/RarityIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";

import { isIp } from "utils/isIp";
import { timeFormatterTimezone } from "utils/time-fmt";

import { useOverflowState } from "hooks/useOverflowState";

import IpAddressLink from "./IpAddressLink";

const staticCols = [
  {
    field: "time",
    headerName: "Time",
    width: 180,
    hideable: false,
    display: "flex",
    renderCell: (cell) => (
      <CellValue>{timeFormatterTimezone(cell.value)}</CellValue>
    ),
  },
  {
    field: "relevance",
    headerName: "Relevance",
    width: 100,
    hideable: false,
    sortable: true,
    display: "flex",
    renderCell: (cell) => <CellValue>{cell.value.label}</CellValue>,
    valueGetter: (params) => ({ label: params?.label, score: params?.value }),
    sortComparator: (_v1, _v2, cellParams1, cellParams2) =>
      cellParams1.value.score - cellParams2.value.score,
  },
  {
    field: "sensitivity",
    headerName: "Sensitivity",
    width: 80,
    hideable: false,
    display: "flex",
    renderCell: (cell) => {
      if (!cell.row.match_info) {
        return <SensitivityIndicator value={cell.value} />;
      }
      return (
        <HtmlTooltip
          title={
            <div>
              <h4>Matched by:</h4>
              <pre style={{ fontFamily: "monospace" }}>
                {cell.row.match_info}
              </pre>
            </div>
          }
        >
          <SensitivityIndicator value={cell.value} />
        </HtmlTooltip>
      );
    },
  },
  {
    field: "rarity_score",
    headerName: "Rarity",
    width: 80,
    hideable: false,
    display: "flex",
    renderCell: (cell) => <RarityIndicator value={cell.value} />,
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    hideable: true,
    renderCell: (cell) => <CellValue>{cell.value}</CellValue>,
  },
];

export function getHiddenColumns(
  prevModel = {},
  newModel = {},
  prevHidden = []
) {
  const hiddenColumns = new Set(prevHidden);
  const allCols = Object.keys(newModel).concat(Object.keys(prevModel));
  for (let col of allCols) {
    const prevVisible = prevModel[col] === true || prevModel[col] === undefined;
    const visible = newModel[col] === true || newModel[col] === undefined;
    if (!visible && prevVisible) {
      hiddenColumns.add(col);
      continue;
    }

    if (visible && hiddenColumns.has(col)) {
      hiddenColumns.delete(col);
    }
  }
  return Array.from(hiddenColumns);
}

const useTableState = () => {
  const apiRef = useGridApiRef();
  const { selectedScenario } = useContext(ScenariosContext);
  const [storedColumns, setStoredColumns] = useState();
  const userHiddenColumns = useRef([]);
  const prevState = useRef();
  const storeKey = `timeline-table-${selectedScenario?.id}`;

  if (typeof apiRef.current.exportState === "function" && !prevState.current) {
    prevState.current =
      apiRef.current.exportState().columns.columnVisibilityModel;
  }

  const saveState = useCallback(() => {
    if (!apiRef.current.exportState) {
      return;
    }
    const tableState = apiRef.current.exportState();
    if (userHiddenColumns.current.length) {
      tableState._userHiddenColumns = userHiddenColumns.current;
    }
    const data = JSON.stringify(tableState);
    if (data === "{}") {
      return;
    }
    localStorage.setItem(storeKey, data);
  }, [apiRef, storeKey]);

  const handleVisibilityModelChange = useCallback(
    (newModel) => {
      userHiddenColumns.current = getHiddenColumns(
        prevState.current,
        newModel,
        userHiddenColumns.current
      );
      prevState.current = newModel;
      saveState();
    },
    [saveState]
  );

  useLayoutEffect(() => {
    const dataFromStorage = localStorage.getItem(storeKey);
    const parsedData = dataFromStorage ? JSON.parse(dataFromStorage) : {};
    setStoredColumns(parsedData);
    userHiddenColumns.current = parsedData?._userHiddenColumns || [];
  }, [storeKey]);

  return { storedColumns, saveState, apiRef, handleVisibilityModelChange };
};

const CustomFooter = () => {
  return (
    <GridFooterContainer>
      <GridToolbarDensitySelector />
      <GridPagination />
    </GridFooterContainer>
  );
};

const CellValue = ({ children }) => {
  const { ref, overflow } = useOverflowState();
  return (
    <HtmlTooltip title={children} disableHoverListener={!overflow}>
      <Typography
        variant="body2"
        ref={ref}
        textOverflow="ellipsis"
        overflow="hidden"
      >
        {children}
      </Typography>
    </HtmlTooltip>
  );
};

const defaultProps = {
  sx: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "700",
      textTransform: "capitalize",
    },
    "& .action": {
      color: "steelblue",
    },
    "& .MuiDataGrid-cell": {
      alignItems: "center",
      display: "flex",
      padding: "10px 10px",
    },
  },
  slotProps: {
    panel: {
      sx: {
        "& .MuiDataGrid-panelWrapper": {
          maxHeight: "30vh",
        },
      },
    },
  },
  localeText: { toolbarDensityComfortable: "Wrap" }, // Custom label for comfortable
  slots: {
    footer: CustomFooter,
  },
  getRowHeight: ({ densityFactor }) => {
    if (densityFactor === 1.3) {
      return "auto";
    }
    return densityFactor * 60;
  },
  pageSizeOptions: [100],
};

export const TimelineDataGrid = ({
  items,
  presentFields,
  actionKeys,
  isPending,
  hasRelevanceScore,
}) => {
  const { configuredFields } = useContext(TagsVisibilityContext);
  const { storedColumns, handleVisibilityModelChange, saveState, apiRef } =
    useTableState();

  const dynamicCols = useMemo(
    () =>
      configuredFields.map((field) => {
        return {
          field,
          headerName: field,
          headerClassName: actionKeys?.has(field) ? "action" : "annotation",
          renderCell: (cell) => {
            const ip = isIp(cell?.value);
            return (
              <CellValue>
                {ip ? (
                  <IpAddressLink ip={ip} value={cell?.value} />
                ) : (
                  cell.value
                )}
              </CellValue>
            );
          },
        };
      }),
    [actionKeys, configuredFields]
  );

  const columns = useMemo(() => {
    return staticCols.concat(dynamicCols);
  }, [dynamicCols]);

  const initialState = useMemo(() => {
    if (isPending) {
      return;
    }
    let columnVisibilityModel = {};
    for (const { field } of dynamicCols) {
      columnVisibilityModel[field] = presentFields.has(field);
    }
    for (const { field } of staticCols) {
      columnVisibilityModel[field] = true;
    }
    for (const field of storedColumns?._userHiddenColumns || []) {
      columnVisibilityModel[field] = false;
    }

    columnVisibilityModel.relevance = hasRelevanceScore;

    return {
      columns: {
        ...storedColumns?.columns,
        columnVisibilityModel,
      },
      density: storedColumns?.density,
      pinnedColumns: storedColumns?.pinnedColumns,
      pagination: {
        paginationModel: {
          pageSize: 100,
        },
      },
    };
  }, [isPending, storedColumns, presentFields, hasRelevanceScore, dynamicCols]);

  const rows = useMemo(() => {
    return items.map((i) => {
      const row = i.tokens_filtered.reduce((acc, r) => {
        acc[r.key] = r.value[0];
        return acc;
      }, {});
      row.id = i.id;
      row.sensitivity = i.sensitivity;
      row.rarity_score = i.rarity_score;
      row.time = i.time;
      row.description = i.description.replace(/\.$|\t/g, "");
      row.match_info = i.match_info.match;
      row.relevance = {
        label: i.relevance.level,
        value: i.relevance.score,
      };
      return row;
    });
  }, [items]);

  const loading = !initialState || !columns || !storedColumns;
  if (loading) {
    return <PageLoadingSpinner />;
  }

  return (
    <Box sx={{ position: "relative", width: "100%", overflowX: "scroll" }}>
      <DataGridPro
        {...defaultProps}
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        initialState={initialState}
        loading={loading}
        onColumnVisibilityModelChange={handleVisibilityModelChange}
        onColumnWidthChange={saveState}
        onColumnOrderChange={saveState}
        onPinnedColumnsChange={saveState}
        onDensityChange={saveState}
        disableColumnFilter
        keepColumnPositionIfDraggedOutside
        pagination
        disableRowSelectionOnClick
      />
    </Box>
  );
};
