import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    width: "100%",
  },
  "& .MuiFormControl-root": {
    padding: "0px !important",
  },
  "& .MuiInputBase-input": {
    padding: theme.spacing(3.5),
    fontSize: "inherit",
    backgroundColor: "white",
  },
  "& .MuiInputBase-inputMultiline": {
    padding: theme.spacing(1),
  },
}));

function StdTextField(props) {
  return <StyledTextField variant="outlined" {...props} />;
}

export default StdTextField;
