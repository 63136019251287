import { createContext, useContext } from "react";

import { useStoryTimelineData } from "./useStoryTimelineData";

const StoryContext = createContext({});

export const StoryContextProvider = ({
  selectedActions,
  session,
  scenario,
  children,
}) => {
  const value = useStoryTimelineData({ selectedActions, scenario, session });

  return (
    <StoryContext.Provider value={value}>{children}</StoryContext.Provider>
  );
};

export const useStoryContext = () => {
  return useContext(StoryContext);
};
