import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import BigMessage from "./BigMessage";

function NotImplemented() {
  const strings = useLocalizedStrings();
  return (
    <BigMessage>
      <span>
        <BuildOutlinedIcon />
        &nbsp;
        {strings.text_under_construction}
        &nbsp;
        <BuildOutlinedIcon />
      </span>
    </BigMessage>
  );
}

export default NotImplemented;
