import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Popper } from "@mui/material";
import * as d3 from "d3";

import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useResize } from "hooks/useResize";

import D3Chart from "./styled/D3Chart.styled";

const padding = { left: 5, top: 50, right: 5, bottom: 8 };

function BCTooltip({ children, anchorEl, ...rest }) {
  return (
    <Popper
      anchorEl={anchorEl}
      placement="bottom"
      disablePortal={false}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
        },
      ]}
      {...rest}
    >
      {children}
    </Popper>
  );
}

export default function BarChart({
  children,
  items,
  options,
  onBarClick,
  onBarRightClick,
  tooltipContent: TooltipContent,
  calcColor,
  ...rest
}) {
  const strings = useLocalizedStrings();
  const [rect, containerRef] = useResize();
  const transitionDuration = useMemo(
    () => options?.transitionDuration || 300,
    [options]
  );
  const [hoveredBar, setHoveredBar] = useState(null);
  const svgRef = useRef();

  const getX = useCallback((d) => d[0], []);
  const getY = useCallback((d) => d[1], []);
  const getData = useCallback((d) => d[2], []);
  const getZ = useCallback((d) => getData(d).sensitivity, [getData]);
  const yMax = useMemo(
    () => d3.max(items || [], (d) => getY(d)),
    [items, getY]
  );
  const isDimmed = useCallback((d) => getData(d).dimmed, [getData]);
  const dimmedCount = useMemo(
    () => (items || []).reduce((a, d) => a + (isDimmed(d) ? 1 : 0), 0),
    [items, isDimmed]
  );

  const handleBarClick = useCallback(
    (e, d) => {
      onBarClick?.(e, getData(d));
    },
    [onBarClick, getData]
  );

  const handleBarDblClick = useCallback(
    (e, d) => {
      e.preventDefault();
      onBarRightClick?.(e, getData(d));
    },
    [onBarRightClick, getData]
  );

  const handleBarEntered = useCallback(
    (e, d) => {
      setHoveredBar({ element: e.target, data: getData(d) });
    },
    [setHoveredBar, getData]
  );
  const handleBarLeave = useCallback(() => {
    setHoveredBar(null);
  }, [setHoveredBar]);

  useEffect(() => {
    if (!items || !rect) {
      return;
    }

    const svg = d3.select(containerRef.current).select("svg");

    // X scale
    const x = d3
      .scaleBand()
      .domain(items.map((d) => getX(d)))
      .range([padding.left, rect.width - padding.right])
      .paddingInner(0.1);

    // Y scale
    const y = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([0, rect?.height - padding.bottom - padding.top]);

    const transition = d3
      .transition()
      .duration(transitionDuration)
      .ease(d3.easeCubicOut);

    // bars container
    svg
      .selectAll("g.bars")
      .data([1])
      .join((enter) => {
        return enter.append("g").attr("class", "bars");
      })
      .selectAll("g.bar")
      .data(items, (d) => getX(d).toUpperCase())
      .join((enter) => {
        return enter
          .append("g")
          .attr("class", "bar")
          .style("cursor", "pointer")
          .each((_, i, g) => {
            d3.select(g[i])
              .append("rect")
              .attr("class", "bar-rect")
              .attr("x", 0)
              .attr("y", rect.height - padding.bottom)
              .attr("height", y(getY(0)));
            d3.select(g[i])
              .append("text")
              .attr("class", "bar-text")
              .attr("text-anchor", "middle")
              .attr("y", rect.height - padding.bottom)
              .attr("font-size", ".8em");
            d3.select(g[i])
              .append("rect")
              .attr("class", "bar-hotspot")
              .attr("x", 0)
              .attr("y", padding.top)
              .attr("width", x.bandwidth())
              .attr("height", rect.height - padding.bottom - padding.top)
              .attr("fill", "transparent")
              .on("click", handleBarClick)
              .on("contextmenu", handleBarDblClick)
              .on("mouseenter", handleBarEntered)
              .on("mouseleave", handleBarLeave);
          });
      })
      .attr("transform", (d) => `translate(${x(getX(d))}, 0)`)
      .each((_, i, g) => {
        d3.select(g[i])
          .select("rect.bar-rect")
          .transition(transition)
          // .attr('rx', '10px')
          // .attr('ry', '10px')
          .attr("y", (d) => rect.height - padding.bottom - y(getY(d)))
          .attr("width", x.bandwidth())
          .attr("height", (d) => y(getY(d)))
          .attr("fill", (d) => calcColor(d))
          .attr("opacity", (d) => (isDimmed(d) ? 0.3 : 1));
        d3.select(g[i])
          .select("text.bar-text")
          .transition(transition)
          .attr("y", (d) =>
            Math.max(rect.height - padding.bottom - y(getY(d)) - 5, 12)
          )
          .attr("x", x.bandwidth() / 2)
          .attr("opacity", (d) => (isDimmed(d) ? 0.4 : 1))
          .attr("fill", "currentColor")
          .text((d) => getY(d));
      });
  }, [
    containerRef,
    items,
    rect,
    yMax,
    getX,
    getY,
    getZ,
    isDimmed,
    handleBarEntered,
    handleBarLeave,
    handleBarClick,
    handleBarDblClick,
    transitionDuration,
    calcColor,
  ]);

  return (
    <D3Chart
      {...rest}
      ref={containerRef}
      className="bar-chart"
      disabled={!items || items.length === 0}
    >
      <p className="chart-title">
        {strings.sessiondetails_timeline_hist_chart_title}
      </p>
      <svg ref={svgRef} width={rect?.width} height={rect?.height}></svg>
      <div className="chart-toolbar">
        <button onClick={onBarClick} disabled={dimmedCount === 0}>
          {strings.sessiondetails_timeline_hist_chart_btn_reset}
        </button>
        {children}
      </div>

      <BCTooltip open={!!hoveredBar} anchorEl={() => hoveredBar.element}>
        {hoveredBar ? <TooltipContent data={hoveredBar.data} /> : <div />}
      </BCTooltip>
    </D3Chart>
  );
}
