import Navbar from "./navbar/Navbar";
import Messages from "./ui/Messages";

function Layout(props) {
  return (
    <>
      <Navbar />
      <main className="content">{props.children}</main>
      <Messages />
    </>
  );
}

export default Layout;
