import { useCallback, useMemo } from "react";

import { useJobStore } from "stores/useJobStore";

export default function useScenarioJobState(scenario_id) {
  const isRunning = useJobStore(
    useCallback((state) => state.jobs[scenario_id]?.length > 0, [scenario_id])
  );

  const scenarioJob = useJobStore(
    useCallback((state) => state.jobs[scenario_id]?.[0], [scenario_id])
  );

  const isDataExplorationRunning = useMemo(
    () => isRunning && scenarioJob?.type === "data_analysis",
    [isRunning, scenarioJob?.type]
  );

  return {
    isRunning,
    scenarioJob,
    isDataExplorationRunning,
  };
}

export const useDataExplorationState = () => {
  const isDataExplorationJobActive = useJobStore(
    useCallback((state) => {
      for (const scenario in state.jobs) {
        const analysisJobs = state.jobs[scenario]?.filter(
          (job) => job.type === "data_analysis"
        );
        if (analysisJobs.length) {
          return true;
        }
      }
      return false;
    }, [])
  );

  return {
    isDataExplorationJobActive,
  };
};
