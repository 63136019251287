import { useMemo } from "react";

import { List, ListItem, ListItemText, ListSubheader } from "@mui/material";

import { useSessionDetails } from "components/sessions/SessionDetailsContext";
import RiskIndicator from "components/ui/RiskIndicator";
import SensitivityIndicator from "components/ui/SensitivityIndicator";

import { timeFormatter } from "utils/time-fmt";

export const UserInfoBox = () => {
  const { data } = useSessionDetails();

  const user = useMemo(() => {
    if (!data) {
      return [];
    }
    return Object.entries({
      User: data.orig_app_user_id,
      Trails: data.user_num_profiles,
      "Total Journeys": data.user_total_sessions,
      "Earliest Journey": timeFormatter(data.user_earliest, {
        includeTime: false,
      }),
      "Latest Journey": timeFormatter(data.user_latest, {
        includeTime: false,
      }),
      "Max Risk": <RiskIndicator value={data.user_max_risk * 100} />,
      "Risky Journeys": data.user_num_risky_sessions,
      "Average Sensitivity": (
        <SensitivityIndicator
          value={Math.abs(Math.min(data.user_avg_sensitivity, 0))}
        />
      ),
    });
  }, [data]);

  return (
    <List sx={{ backgroundColor: "ghostwhite", width: "250px" }} dense>
      <ListSubheader
        sx={{
          backgroundColor: "ghostwhite",
          fontSize: "120%",
          color: "inherit",
        }}
      >
        User Details
      </ListSubheader>
      {user.map(([label, value]) => (
        <ListItem key={label}>
          <ListItemText
            sx={{ wordBreak: "break-word" }}
            primary={value}
            secondary={label}
          />
        </ListItem>
      ))}
    </List>
  );
};
