import { useEffect, useState } from "react";

import { styled } from "@mui/material";

import Flexbox from "./Flexbox";

const StyledBarChart = styled(Flexbox)(({ theme }) => ({
  alignItems: "flex-end",
  height: "100%",
  overflowX: "auto",
  overflowY: "hidden",
  paddingBottom: theme.spacing(10),
  width: "100%",
  position: "relative",
  "& .bar": {
    alignItems: "center",
    backgroundColor: "steelblue",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "5px 5px 9px -3px rgba(50, 50, 50, 0.3);",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    marginRight: "10px",
    minHeight: "5px",
    minWidth: "5em",
    position: "relative",
    transition: "all 200ms ease-in-out",
    width: "5em",
    "&.hidden": {
      marginRight: "0px",
      minWidth: 0,
      transform: "scale(0)",
      width: 0,
    },
    "&::before": {
      alignContent: "center",
      alignItems: "center",
      borderRadius: theme.shape.borderRadius,
      bottom: "calc(100% + .3em)",
      content: "attr(data-ylabel)",
      display: "flex",
      fontSize: "80%",
      justifyContent: "center",
      left: 0,
      pointerEvents: "none",
      position: "absolute",
      right: 0,
      width: "100%",
    },
    "&.show-relative::before": {
      content: 'attr(value)" ("attr(data-valuerel)"%)"',
    },
    "&::after": {
      content: "attr(label)",
      fontSize: "8pt",
      left: "50%",
      overflow: "hidden",
      pointerEvents: "none",
      position: "absolute",
      top: "100%",
      transform: "rotate(45deg)",
      transformOrigin: "top left",
      transition: "all 200ms ease",
      whiteSpace: "pre",
    },
    "&.show-story:hover": {
      minWidth: "10em",
      "&::after": {
        backgroundColor: "rgba(255,255,255,.9)",
        border: "1px solid #ddd",
        borderRadius: theme.shape.borderRadius,
        bottom: "2em",
        content: "attr(data-story)",
        cursor: "default",
        fontSize: "inherit",
        left: 0,
        margin: theme.spacing(1),
        maxWidth: "30em",
        padding: theme.spacing(1),
        pointerEvents: "all",
        top: "unset",
        transform: "rotate(0)",
        transformOrigin: "top left",
        zIndex: 999,
      },
    },
  },
}));

function BarChart({ items, showRelative, isBarVisible, showStory, onClick }) {
  const [maxY, setMaxY] = useState(0);
  const [totalY, setTotalY] = useState(0);

  // calculate max Y and total Y whenever items changed
  useEffect(() => {
    setMaxY(items.reduce((a, e) => Math.max(a, e.y), 0));
    setTotalY(items.reduce((a, e) => a + e.y, 0));
  }, [items]);

  return (
    <StyledBarChart>
      {items.map((e) => (
        <div
          className={`bar ${showRelative ? "show-relative" : ""} ${
            !isBarVisible || !isBarVisible(e) ? "hidden" : ""
          } ${showStory ? "show-story" : ""}`}
          key={e.x}
          id={e.x}
          label={e.label}
          data-ylabel={e.ylabel}
          data-story={e.story}
          value={e.y}
          data-valuerel={totalY ? ((e.y * 100) / totalY).toFixed(1) : undefined}
          onClick={(ev) => (onClick ? onClick(ev, e) : {})}
          style={{
            height: `calc(${(e.y * 100) / maxY}% - 2.5em)`,
            backgroundColor: e.color instanceof Function ? e.color(e) : e.color,
          }}
        />
      ))}
    </StyledBarChart>
  );
}

export default BarChart;
