import { styled } from "@mui/material";

const StyledBigMessage = styled("div")(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "100%",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  fontSize: "14pt",
  letterSpacing: 1.6,
}));

function BigMessage({ children, ...rest }) {
  return <StyledBigMessage {...rest}>{children}</StyledBigMessage>;
}

export default BigMessage;
