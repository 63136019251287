import { useCallback, useMemo, useState } from "react";

import { styled } from "@mui/material/styles";
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridFooterContainer,
} from "@mui/x-data-grid-pro";

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  "& .MuiBox-root": {
    position: "relative",
    width: "100%",
    overflowX: "scroll",
    tableLayout: "fixed",
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    overflowY: "auto",
    borderTop: "1px solid #ddd",
  },
}));

const CustomFooter = ({ displayed, total, selected }) => (
  <GridFooterContainer>
    <div style={{ marginLeft: "1em" }}>
      {displayed}/{total} items, {selected} selected
    </div>
  </GridFooterContainer>
);

export default function DataGridINF({
  columns,
  items,
  count,
  loading,
  RowDetails,
  fetchNextPage,
  handleCheckBoxCheck,
  isRowSelectable,
  orderBy,
  onOrderBy,
  disableMultipleRowSelection,
  ...rest
}) {
  const [selectionModel, setSelectionModel] = useState([]);

  const sortModel = useMemo(() => {
    const [field, sortOrder] = Object.entries(orderBy)[0] || [];
    return field ? [{ field, sort: sortOrder === 1 ? "asc" : "desc" }] : [];
  }, [orderBy]);

  const getDetailPanelContent = useCallback(
    ({ row }) => <RowDetails columns={columns} item={row} />,
    [columns]
  );

  const handleSortModelChange = (newSortModel) => {
    onOrderBy(newSortModel);
  };

  const getTogglableColumns = useCallback((columns) => {
    return columns
      .filter(
        (column) =>
          ![
            GRID_CHECKBOX_SELECTION_FIELD,
            GRID_DETAIL_PANEL_TOGGLE_FIELD,
          ].includes(column.field)
      )
      .map((column) => column.field);
  }, []);

  return (
    <StyledDiv>
      <DataGridPro
        columns={columns}
        rows={items || []}
        loading={loading}
        sx={{
          height: "100%",
          width: "100%",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.purple?.dark || "inherit",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              textTransform: "capitalize",
              userSelect: "none",
            },
            "& .MuiDataGrid-sortIcon": {
              fontSize: "1.5rem",
              fill: "tomato",
              margin: 0,
            },
          },
          "& .MuiDataGrid-cell": {
            color: (theme) => theme.palette.table?.main || "inherit",
            padding: "8px",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
          },
        }}
        rowHeight={38}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        sortingMode="server"
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(checkedIDs) => {
          setSelectionModel(checkedIDs);
          handleCheckBoxCheck(checkedIDs);
        }}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnFilter
        keepColumnPositionIfDraggedOutside
        slots={{
          footer: () => (
            <CustomFooter
              displayed={items?.length}
              total={count}
              selected={selectionModel.length}
            />
          ),
        }}
        slotProps={{
          columnsManagement: {
            getTogglableColumns,
          },
        }}
        getDetailPanelContent={RowDetails ? getDetailPanelContent : null}
        getDetailPanelHeight={() => "auto"}
        onRowsScrollEnd={() => !loading && fetchNextPage()}
        isRowSelectable={isRowSelectable}
        disableMultipleRowSelection={disableMultipleRowSelection}
        {...rest}
      />
    </StyledDiv>
  );
}
