import MappingEditorTabs from "./MappingEditorTabs";
import { UniquenessValidationWrapper } from "./UniquenessValidationWrapper";
import BucketingTabComponent from "./tabComponents/bucketing/BucketingTabComponent";
import FilteringTabComponent from "./tabComponents/filtering/FilteringTabComponent";
import MappingTabComponent from "./tabComponents/mapping/MappingTabComponent";
import TransformationsTabComponent from "./tabComponents/transformations/TransformationsTabComponent";

const tabsConfig = [
  {
    label: "Mapping",
    content: <MappingTabComponent />,
  },
  {
    label: "Filtering",
    content: <FilteringTabComponent />,
  },
  {
    label: "Bucketing",
    content: <BucketingTabComponent />,
  },
  {
    label: "Transformations",
    content: <TransformationsTabComponent />,
  },
];

const MappingEditor = ({ isActive }) => {
  return (
    <UniquenessValidationWrapper>
      <MappingEditorTabs tabsConfig={tabsConfig} isActive={isActive} />
    </UniquenessValidationWrapper>
  );
};

export default MappingEditor;
