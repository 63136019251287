import { useMemo } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CloudDownloadOutlined } from "@mui/icons-material";
import ControlPointDuplicateOutlined from "@mui/icons-material/ControlPointDuplicateOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton, styled } from "@mui/material";

import ScenariosAPI from "api/scenarios";

import ScenarioMoreOptions from "components/scenarios/ScenarioMoreOptions";
import ScenarioNewMessagesButton from "components/scenarios/ScenarioNewMessagesButton";
import ActiveJobIndicator from "components/ui/ActiveJobIndicator";
import ApplicationIcon from "components/ui/ApplicationIcon";
import HtmlTooltip from "components/ui/HtmlTooltip";
import RemoteDownloader from "components/ui/RemoteDownloader";

import { timeFormatterTimezone } from "utils/time-fmt";

import { useCapabilities } from "hooks/useCapabilities";
import { useInView } from "hooks/useInView";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import CollectorActivationButton from "./CollectorActivationButton";

const StyledCollectorThumbnail = styled("div")(({ theme }) => ({
  backgroundColor: "#f0eff0",
  borderRadius: "5px",
  color: "inherit",
  cursor: "pointer",
  direction: "ltr",
  display: "flex",
  gap: "10px",
  marginLeft: "8px",
  marginRight: "8px",
  minHeight: "10em",
  padding: "10px",
  transition: "background-color 150ms ease",
  "& .collector-icon": {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
  },
  "& .collector-text": {
    display: "flex",
    flexDirection: "column",
    flexGrow: "1",
    gap: "0.5em",
    overflow: "hidden",
    wordWrap: "break-word",
    "& .collector-name, & .collector-description": {
      fontSize: "inherit",
      fontFamily: "inherit",
    },
    "& .mret": {
      fontSize: "0.6rem",
      opacity: "0.7",

      "& .limit": {
        marginTop: "5px",
      },
    },
  },
  "& .collector-actions-band": {
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    justifyContent: "flex-start",
    padding: "8px",
  },
  "&.selected": {
    backgroundColor: `${theme.palette.primary.contrastText}`,
    borderBottomRightRadius: "0px",
    borderRight: "none",
    borderTopRightRadius: "0px",
    marginRight: "0px",
    overflow: "visible",
    paddingRight: "17px",
  },
}));

const RetentionLimits = ({ dataLimit, periodLimit }) => {
  const strings = useLocalizedStrings();

  const displayedLimits = useMemo(() => {
    if (!dataLimit && !periodLimit) {
      return strings.collectors_info_label_unlimited;
    }
    const data = dataLimit ? dataLimit + " GB" : "";
    const days = periodLimit > 1 ? strings.text_days : strings.text_day;
    const period = periodLimit
      ? `${data ? ", " : ""}${periodLimit} ${days}`
      : "";

    return strings.formatString(strings.collectors_info_label_retention_limit, {
      data,
      period,
    });
  }, [dataLimit, periodLimit, strings]);

  return <p className="mret limit">{displayedLimits}</p>;
};

export const CollectorThumbnail = ({
  collectorInfo,
  setDialogOpen,
  selected,
  setSelected,
  apps,
  onDelete,
  someoneIsDragging,
  draggingDisabled,
}) => {
  const caps = useCapabilities();
  const strings = useLocalizedStrings();
  const writeAllowed = caps({ "acm.collectors": { write: true } });
  const { inView, target } = useInView();

  const app = useMemo(() => {
    if (!collectorInfo || !apps) {
      return null;
    }
    return apps.find((a) => a.connector === collectorInfo.connector);
  }, [apps, collectorInfo]);

  const handleSelectClick = () => {
    if (someoneIsDragging || isDragging) {
      return;
    }
    setSelected(collectorInfo.id);
  };

  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: collectorInfo.id,
    disabled: draggingDisabled || !caps({ "acm.collectors": { write: true } }),
  });
  const style = {
    transform: CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }),
    transition: transition,
  };

  const collectorMoreOptions = useMemo(
    () => [
      {
        id: "clone-collector",
        label: strings.collectors_sidebar_clone,
        icon: <ControlPointDuplicateOutlined />,
        handler: () => setDialogOpen(true),
        disabled: !writeAllowed,
      },
      {
        id: "delete-collector",
        label: strings.collectors_thumbnail_tooltip_delete,
        icon: <DeleteOutlineOutlinedIcon />,
        handler: () => onDelete(collectorInfo),
        disabled: !writeAllowed,
      },
    ],
    [strings, writeAllowed, collectorInfo, setDialogOpen, onDelete]
  );

  return (
    <StyledCollectorThumbnail
      className={`collector-thumbnail ${selected ? "selected" : ""}`}
      onClick={handleSelectClick}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <div ref={target} className="collector-icon">
        <ApplicationIcon app={app} />
      </div>

      <div className="collector-text">
        <p className="collector-name">{collectorInfo.name}</p>
        <hr />
        <p className="collector-description">{collectorInfo.description}</p>
        <div>
          {collectorInfo.oldest_event_time && (
            <p className="mret">
              {timeFormatterTimezone(new Date(collectorInfo.oldest_event_time))}
            </p>
          )}
          {collectorInfo.most_recent_event_time && (
            <p className="mret">
              {timeFormatterTimezone(
                new Date(collectorInfo.most_recent_event_time)
              )}
            </p>
          )}
        </div>
        <RetentionLimits
          dataLimit={collectorInfo.external_data_retention_limit}
          periodLimit={collectorInfo.external_data_retention_period}
        />
      </div>

      <div>
        <ActiveJobIndicator scenario={collectorInfo.id} />
      </div>

      <div className="collector-actions-band">
        <CollectorActivationButton
          collectorId={collectorInfo.id}
          disabled={!writeAllowed}
          inView={inView}
        />
        <HtmlTooltip title={strings.collectors_thumbnail_tooltip_messages}>
          <ScenarioNewMessagesButton
            inView={inView}
            scenarioId={collectorInfo.id}
            notooltip="true"
          />
        </HtmlTooltip>
        <RemoteDownloader
          url={ScenariosAPI.exportScenario(collectorInfo.id).url}
          filename={`collector.${collectorInfo.id}.json`}
        >
          <HtmlTooltip title={strings.sidebar_tooltip_export}>
            <IconButton size="small">
              <CloudDownloadOutlined />
            </IconButton>
          </HtmlTooltip>
        </RemoteDownloader>
        <ScenarioMoreOptions menuItems={collectorMoreOptions} />
      </div>
    </StyledCollectorThumbnail>
  );
};
