import { useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { IconButton } from "@mui/material";
import { isEqual } from "lodash-es";
import { useFieldArray, useFormContext } from "react-hook-form";

import MultipleOptionsDialog from "components/ui/MultipleOptionsDialog";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import TransformationsTabItemComponent from "./TransformationsTabItemComponent";

const TransformationsTabComponent = () => {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const strings = useLocalizedStrings();

  const { trigger, getValues } = useFormContext();

  const name = "options.internal_config.transformations.entries";
  const { fields, append, remove } = useFieldArray({ name });

  const defaultAppendValues = {
    type: "regex_capture",
    input_field: "",
    output_field: "",
    output_column: "location",
    formula: "",
  };

  const handleAppend = () => {
    append(defaultAppendValues);
    trigger("options.internal_config.mapping");
  };

  const handleDelete = (index) => {
    const values = getValues(`${name}.${index}`);

    if (isEqual(values, defaultAppendValues)) {
      remove(index);
      return;
    }

    setDeleteIndex(index);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmationAction = () => {
    remove(deleteIndex);
    trigger("options.internal_config.mapping");
  };

  return (
    <div>
      {fields &&
        fields.map((item, index) => {
          return (
            <TransformationsTabItemComponent
              data={item}
              dataKey={index}
              key={item.id}
              onDelete={handleDelete}
            />
          );
        })}
      <IconButton onClick={handleAppend} size="small">
        <AddCircleOutlineIcon />
      </IconButton>

      <MultipleOptionsDialog
        onConfirm={handleConfirmationAction}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        text={
          strings.sourcedialog_mapping_editor_transformations_tab_delete_entry_question
        }
        title={
          strings.sourcedialog_mapping_editor_transformations_tab_delete_entry_title
        }
      />
    </div>
  );
};

export default TransformationsTabComponent;
