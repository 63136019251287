import { useMemo } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  tableCellClasses,
  tableRowClasses,
  typographyClasses,
} from "@mui/material";
import { useSuspenseQuery } from "@tanstack/react-query";

import { getSessionSummaryQueryOptions } from "api/sessions";

import { TableColumnHeaders } from "components/ui/tables/TableColumnHeader";

import useLocalizedStrings from "hooks/useLocalizedStrings";

const columns = (strings) => [
  {
    id: "action",
    label: "Most Relevant Actions",
    render: (v) => v.description || v.action,
  },
  {
    id: "relevance",
    label: strings.sessiondetails_column_relevance,
    render: (v) => v.level,
  },
  {
    id: "occurrences",
    label: "#",
    render: (v) => v.frequency,
  },
];

const RelevantActionsTable = ({
  selectedActions,
  handleToggleAction,
  columns,
  items,
}) => {
  const strings = useLocalizedStrings();
  return (
    <TableContainer className="table-wrapper">
      <Table stickyHeader>
        <TableColumnHeaders boldHeader={true} columns={columns} />
        <TableBody
          sx={{
            [`& .${tableRowClasses.selected}`]: {
              backgroundColor: "ghostwhite",
            },
          }}
        >
          {items.map((item) => (
            <TableRow
              key={item.id}
              selected={selectedActions.has(item.action)}
              onClick={() => handleToggleAction(item.action)}
              hover
            >
              {columns(strings).map((column) => (
                <TableCell
                  sx={{
                    cursor: "pointer",
                    "& > *": { cursor: "pointer" },
                  }}
                  key={column.id}
                >
                  <Typography>{column.render(item)}</Typography>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const MostRelevantActions = ({
  selectedActions,
  handleToggleAction,
  scenario,
  session,
}) => {
  const { data: summary } = useSuspenseQuery(
    getSessionSummaryQueryOptions({
      scenario,
      session,
      commonness: false,
    })
  );

  const items = useMemo(
    () =>
      summary.relevance.map((r) => {
        r.id = r.action;
        return r;
      }),
    [summary.relevance]
  );

  return (
    <Box
      sx={{
        overflowY: "scroll",
        flexBasis: "100%",
        [`& .${tableCellClasses.root}`]: {
          padding: 0.6,
          [`& .${typographyClasses.root}`]: {
            fontSize: 13,
          },
        },
        [`& .${tableCellClasses.head}`]: {
          backgroundColor: "ghostwhite",
        },
      }}
    >
      <RelevantActionsTable
        selectedActions={selectedActions}
        handleToggleAction={handleToggleAction}
        items={items}
        columns={columns}
      />
    </Box>
  );
};
