import { useCallback, useEffect, useState } from "react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";

import Flexbox from "components/ui/Flexbox";
import { UiSecondaryButton } from "components/ui/StyledButtons";

import useLocalizedStrings from "hooks/useLocalizedStrings";

import SearchFilter from "./SearchFilter";

const StyledFilterSection = styled(Paper)(({ top, theme }) => ({
  backgroundColor: theme.palette.grey.light,
  display: "flex",
  flexDirection: "column",
  height: "calc(98vh - var(--appbar-height))",
  left: 0,
  minWidth: "35em",
  position: "absolute",
  top: top ? `${top}px` : 0,
  zIndex: 10,
  "& .filtersHeader": {
    flexGrow: 0,
    padding: theme.spacing(1),
    position: "relative",
    "& .filtersTitleText": {
      marginRight: "auto",
    },
    "& .MuiButton-root": {
      marginLeft: theme.spacing(1),
    },
  },
  "& .filtersSectionContent": {
    flexDirection: "column",
    flexGrow: 0,
    overflowY: "auto",
    paddingBottom: theme.spacing(2),
    position: "relative",
    "& > *": {
      transform: "scale(80%)",
    },
  },
}));

function FiltersSection({
  filters,
  schema,
  setFilters,
  openFilters,
  setOpenFilters,
  top,
}) {
  const strings = useLocalizedStrings();
  const [localFilters, setLocalFilters] = useState({ ...filters });
  const handleClose = useCallback(() => {
    setOpenFilters(false);
  }, [setOpenFilters]);

  const handleReset = useCallback(() => {
    setLocalFilters({});
  }, [setLocalFilters]);

  const handleSetFilterParams = useCallback(
    (field, params) => {
      setLocalFilters((prev) => ({ ...prev, [field]: params }));
    },
    [setLocalFilters]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setFilters(localFilters);
      setOpenFilters(false);
    },
    [localFilters, setOpenFilters, setFilters]
  );

  useEffect(() => {
    setLocalFilters({ ...filters });
  }, [filters, setLocalFilters]);

  return (
    <form onSubmit={handleSubmit}>
      <Slide in={openFilters} direction="right">
        <StyledFilterSection elevation={20} top={top}>
          <Flexbox className="filtersHeader" justifyContent="flex-start" p={1}>
            <UiSecondaryButton
              className="filtersTitleText"
              size="small"
              variant="text"
              startIcon={<ArrowBackIosIcon />}
              onClick={handleClose}
            >
              {strings.button_hide}
            </UiSecondaryButton>
            <UiSecondaryButton
              size="small"
              variant="text"
              startIcon={<ClearIcon />}
              onClick={handleReset}
            >
              {strings.button_clear}
            </UiSecondaryButton>
            <UiSecondaryButton
              type="submit"
              size="small"
              variant="text"
              startIcon={<SearchIcon />}
            >
              {strings.button_apply}
            </UiSecondaryButton>
          </Flexbox>
          <Flexbox className="filtersSectionContent">
            {schema
              ?.filter(([_k, v]) => v && v.visible !== false)
              .map(([k, v]) => (
                <SearchFilter
                  key={k}
                  target={k}
                  schema={v}
                  filterParams={localFilters[k]}
                  setFilterParams={handleSetFilterParams}
                />
              ))}
          </Flexbox>
        </StyledFilterSection>
      </Slide>
    </form>
  );
}

export default FiltersSection;
