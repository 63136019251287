import { TableCell, TableHead, TableRow } from "@mui/material";

import TableCellText from "components/ui/TableCellText";

import useLocalizedStrings from "hooks/useLocalizedStrings";

export const TableColumnHeaders = ({ columns, compactView, boldHeader }) => {
  const strings = useLocalizedStrings();

  return (
    <>
      <colgroup>
        {columns(strings).map((c) => (
          <col key={c.id} style={{ width: c.width }} />
        ))}
      </colgroup>
      <TableHead>
        <TableRow>
          {columns(strings).map((c) => (
            <TableCell
              key={c.id}
              style={compactView ? { padding: "5px 10px" } : {}}
            >
              <TableCellText
                style={{ fontWeight: boldHeader ? "bold" : "normal" }}
              >
                {c.label}
              </TableCellText>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};
