import ClearIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import { CircularProgress } from "@mui/material";

export default function StatusIcon({ status, ...rest }) {
  if (status === "success") {
    return <CheckCircleIcon {...rest} sx={{ color: "success.main" }} />;
  }
  if (status === "error") {
    return <ErrorIcon {...rest} sx={{ color: "error.main" }} />;
  }
  if (status === "fail") {
    return <ClearIcon {...rest} sx={{ color: "error.main" }} />;
  }
  if (status === "active") {
    return (
      <CircularProgress
        size={20}
        thickness={6}
        sx={{ color: "warning.light" }}
        {...rest}
      />
    );
  }
  if (status === "warning") {
    return <WarningIcon {...rest} sx={{ color: "warning.main" }} />;
  }
  return null;
}
