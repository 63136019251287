import { useCallback } from "react";

import { useOutputFieldsStore } from "stores/useOutputFieldsStore";

const setOutputFieldsSelector = (state) => state.setOutputFields;
const clearOutputFieldsSelector = (state) => state.clearOutputFields;
const removeOutputFieldSelector = (state) => state.removeOutputField;

export const useOutputFields = () => {
  const setOutputFields = useOutputFieldsStore(setOutputFieldsSelector);
  const clearOutputFields = useOutputFieldsStore(clearOutputFieldsSelector);
  const removeOutputField = useOutputFieldsStore(removeOutputFieldSelector);

  const isValid = useCallback((name) => {
    return !useOutputFieldsStore
      .getState()
      .duplicateOutputFields.includes(name);
  }, []);

  return {
    isValid,
    setOutputFields,
    clearOutputFields,
    removeOutputField,
  };
};
