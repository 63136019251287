import { useScenarioDebugInfoDownloadMutation } from "api/scenarios";

import { UiSecondaryButton } from "components/ui/StyledButtons";

import useLocalizedStrings from "hooks/useLocalizedStrings";

export const DebugInfo = ({ scenarioId }) => {
  const strings = useLocalizedStrings();
  const { mutate: downloadDebugInfo, isPending } =
    useScenarioDebugInfoDownloadMutation();

  return (
    <UiSecondaryButton
      onClick={() => downloadDebugInfo({ scenarioId })}
      disabled={isPending}
      style={{ width: "15em", marginBottom: "5px" }}
    >
      {strings.scenariosettings_debug_info}
    </UiSecondaryButton>
  );
};
