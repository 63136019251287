import { diffDays, timeFormatter } from "utils/time-fmt";

export function formatPeriod(from_date, to_date, strings) {
  return from_date && to_date
    ? strings.formatString(
        strings.period_in_days,
        diffDays(from_date, to_date),
        timeFormatter(new Date(from_date), { includeTime: false }),
        timeFormatter(new Date(to_date), { includeTime: false })
      )
    : "";
}
