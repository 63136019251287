import { useMemo } from "react";
import { useContext } from "react";

import { styled } from "@mui/material";

import ScenariosAPI, { scenarioKeys } from "api/scenarios";

import { ScenariosContext } from "contexts/ScenariosContext";

import { HelpIcon } from "components/ui/HelpIcon";
import { InformationPopover } from "components/ui/InformationPopover";
import NotAllowedMessage from "components/ui/NotAllowedMessage";

import { useCapabilities } from "hooks/useCapabilities";
import useFeature from "hooks/useFeature";
import useLocalizedStrings from "hooks/useLocalizedStrings";

import CsvSettings from "./CsvSettings";
import { SettingsAlerters } from "./SettingsAlerters";
import SettingsAnalysisEngine from "./SettingsAnalysisEngine";
import { ReportsAndTools } from "./SettingsExports";
import SettingsGeneral from "./SettingsGeneral";
import { SettingsInsights } from "./SettingsInsights";
import SettingsNotifications from "./SettingsNotifications";
import SettingsParameters from "./SettingsParameters";
import SettingsScheduling from "./SettingsScheduling";

const StyledSettingsContainer = styled("div")`
  position: relative;
  height: calc(95vh - var(--appbar-height));
  min-width: 60em;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  & > * {
    width: 80%;
    margin: 0;
  }
`;

const DistinctUsersPopover = () => {
  const strings = useLocalizedStrings();
  const popupContent = useMemo(
    () => [
      {
        name: (
          <p>
            {"\u2022"}{" "}
            {strings.scenariosettings_distinct_users_popover_malicious_name}
            {" - "}
            {strings.scenariosettings_distinct_users_popover_malicious_value}
          </p>
        ),
      },
      {
        name: (
          <p>
            {"\u2022"}{" "}
            {strings.scenariosettings_distinct_users_popover_suspicious_name}
            {" - "}
            {strings.scenariosettings_distinct_users_popover_suspicious_value}
          </p>
        ),
      },
      {
        name: (
          <p>
            {"\u2022"}{" "}
            {strings.scenariosettings_distinct_users_popover_trusted_name}
            {" - "}
            {strings.scenariosettings_distinct_users_popover_trusted_value}
          </p>
        ),
      },
      {
        name: (
          <p>
            {"\u2022"}{" "}
            {strings.scenariosettings_distinct_users_popover_credible_name}
            {" - "}
            {strings.scenariosettings_distinct_users_popover_credible_value}
          </p>
        ),
      },
    ],
    [strings]
  );

  return (
    <InformationPopover title="Distinct user types:" content={popupContent}>
      <HelpIcon />
    </InformationPopover>
  );
};

export default function Settings() {
  const caps = useCapabilities();
  const strings = useLocalizedStrings();
  const allowParametersUi = useFeature("feature/ANALYSIS_PARAMS_UI");
  const { selectedScenario } = useContext(ScenariosContext);

  const fieldsKey = useMemo(
    () =>
      scenarioKeys.fields({
        scenarioId: selectedScenario?.id,
      }),
    [selectedScenario?.id]
  );

  if (!caps({ "acm.scenarios": { read: true } })) {
    return <NotAllowedMessage />;
  }

  return (
    <StyledSettingsContainer>
      <SettingsGeneral />
      <SettingsAlerters />
      {allowParametersUi ? (
        <SettingsParameters />
      ) : (
        <CsvSettings
          title={strings.scenariosettings_parameters_title}
          filePrefix="parameters"
          getUploadUrl={ScenariosAPI.postScenarioParametersUrl}
          getDownloadUrl={ScenariosAPI.getScenarioParametersUrl}
        />
      )}
      <ReportsAndTools />
      <CsvSettings
        title={strings.scenariosettings_descriptions_title}
        filePrefix="descriptions"
        uploadDisabled={!caps({ "acm.descriptions": { write: true } })}
        getUploadUrl={ScenariosAPI.postDescriptionsCsvUrl}
        getDownloadUrl={ScenariosAPI.getDescriptionsCsvUrl}
        getCountUrl={ScenariosAPI.getDescriptionsCount}
      />
      <CsvSettings
        title={strings.scenariosettings_sensitive_events_title}
        filePrefix="sensitive_events"
        getUploadUrl={ScenariosAPI.postSensitiveEventsCsvUrl}
        getDownloadUrl={ScenariosAPI.getSensitiveEventsCsvUrl}
        getCountUrl={ScenariosAPI.getSensitiveEventsCount}
      />
      <CsvSettings
        title={strings.scenariosettings_field_settings_title}
        filePrefix="field_settings"
        getUploadUrl={ScenariosAPI.postFieldSettingsCsvUrl}
        getDownloadUrl={ScenariosAPI.getFieldSettingsCsvUrl}
        getCountUrl={ScenariosAPI.getFieldSettingsCount}
        invalidateKey={fieldsKey}
      />
      <CsvSettings
        title={strings.scenariosettings_distinct_users_title}
        filePrefix="distinct_users"
        getUploadUrl={ScenariosAPI.postSensitiveUsersCsvUrl}
        getDownloadUrl={ScenariosAPI.getSensitiveUsersCsvUrl}
        getCountUrl={ScenariosAPI.getSensitiveUsersCount}
        popover={<DistinctUsersPopover />}
      />
      <CsvSettings
        title={strings.scenariosettings_sensitivity_anomaly_factors}
        filePrefix="sensitivity_anomaly_factors"
        getUploadUrl={ScenariosAPI.postSensitivityAnomalyFactorsCsvUrl}
        getDownloadUrl={ScenariosAPI.getSensitivityAnomalyFactorsCsvUrl}
        getCountUrl={ScenariosAPI.getSensitivityAnomalyFactorsCount}
      />
      <SettingsInsights />
      <SettingsScheduling />
      <SettingsNotifications id={selectedScenario?.id} />
      <SettingsAnalysisEngine />
    </StyledSettingsContainer>
  );
}
