import { forwardRef } from "react";

import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import Checkbox from "@mui/material/Checkbox";

const NicerCheckbox = forwardRef((props, ref) => {
  return (
    <Checkbox
      ret={ref}
      color="default"
      icon={<CheckBoxOutlineBlankOutlinedIcon />}
      checkedIcon={<CheckBoxOutlinedIcon />}
      {...props}
    />
  );
});

export default NicerCheckbox;
