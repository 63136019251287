import { Clear } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useController } from "react-hook-form";

export const ClearableInput = ({
  name,
  rules,
  helperText,
  required,
  control,
  defaultValue,
  onClear,
  options,
  ...props
}) => {
  const { field, fieldState } = useController({
    control,
    name,
    defaultValue: defaultValue ?? "",
    rules: {
      pattern: {
        value: required ? /(?!\s*$).+/ : false, // regex doesn't allow value to be empty or whitespace values ('  ')
        message: "This field is required",
      },
      ...rules,
      required: {
        value: required ?? false,
        message: "This field is required",
      },
    },
    ...options,
  });

  return (
    <TextField
      sx={{
        width: "100%",
        "& .MuiInputBase-input": {
          height: "56px",
        },
      }}
      {...field}
      variant="outlined"
      error={fieldState.invalid}
      helperText={helperText ?? fieldState?.error?.message ?? " "}
      value={field.value || ""}
      required={required}
      {...props}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onClear} size="large">
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        },

        htmlInput: {
          required: "",
        },
      }}
    />
  );
};
