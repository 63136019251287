import { useRef } from "react";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

import { UiSecondaryButton } from "components/ui/StyledButtons";

const MapingConfigLocalDownloader = ({ filename, onClick }) => {
  const ref = useRef();

  const handleClick = () => {
    const data = onClick();
    const formattedData = [
      JSON.stringify(data.options.internal_config, null, 4),
    ];
    const blob = new Blob(formattedData, { type: "application/json" });
    const objectURL = URL.createObjectURL(blob);
    ref.current.href = objectURL;
    ref.current.download = filename;
    ref.current.click();
  };

  return (
    <>
      {<a ref={ref} />}

      <UiSecondaryButton
        onClick={handleClick}
        startIcon={<CloudDownloadOutlinedIcon />}
        style={{ height: "40px", marginLeft: "10px" }}
      >
        {"download"}
      </UiSecondaryButton>
    </>
  );
};

export default MapingConfigLocalDownloader;
