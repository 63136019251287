import { createContext, useContext, useEffect, useRef } from "react";

import { io } from "socket.io-client";

import { LoginStatusContext } from "./LoginStatusContext";

export const BackendEventsContext = createContext();

export function BackendEventsContextProvider({ children }) {
  const { user } = useContext(LoginStatusContext);
  const socket = useRef(null);

  useEffect(() => {
    if (!user?.token || !user?.account || user?.super_user) {
      if (socket.current) {
        socket.current.disconnect();
        socket.current = null;
      }
      return;
    }

    if (socket.current) {
      return;
    }

    const base =
      import.meta.env.VITE_BASEURL ||
      `${window.location.protocol}//${window.location.host}`;

    socket.current = io(`${base}/${user.account}`, {
      transports: ["websocket"],
      path: "/api/acm/v1/socket",
      auth: { token: user.token },
    });
  }, [user]);

  return (
    <BackendEventsContext.Provider
      value={{
        socket: socket.current,
      }}
    >
      {children}
    </BackendEventsContext.Provider>
  );
}
