import { styled } from "@mui/material";

import FiltersShowHideButton from "components/ui/FiltersShowHideButton";
import Flexbox from "components/ui/Flexbox";
import TextSearchInput from "components/ui/TextSearchInput";

import FilterChips from "./FiltersChips";

const StyledFreeSearchStrip = styled(Flexbox)(({ theme }) => ({
  gap: ".3em",
  padding: theme.spacing(1),
  width: "100%",

  "& .filterChips": {
    marginRight: theme.spacing(0.5),
  },
  "& .filterChip": {
    marginRight: theme.spacing(0.3),
  },
  "& .searchField": {
    flexGrow: 1,
    flexShrink: 1,
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
}));

export default function FreeSearchStrip(props) {
  const {
    children,
    schema,
    filters,
    setFilters,
    openFilters,
    setOpenFilters,
    searchPattern,
    setSearchPattern,
    hideSearchInput,
  } = props;

  return (
    <StyledFreeSearchStrip>
      {setOpenFilters && (
        <FiltersShowHideButton
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        />
      )}
      {children}
      <FilterChips filters={filters} schema={schema} setFilters={setFilters} />
      {!hideSearchInput && (
        <TextSearchInput
          searchPattern={searchPattern || ""}
          setSearchPattern={setSearchPattern}
        />
      )}
    </StyledFreeSearchStrip>
  );
}
