import { useMemo } from "react";

import { useSuspenseQuery } from "@tanstack/react-query";

import {
  getSessionSummaryQueryOptions,
  useSessionsGetActionsSuspenseQuery,
} from "api/sessions";

import { useSessionDetails } from "components/sessions/SessionDetailsContext";

import { useFieldInsights } from "hooks/useFieldInsights";

export const useStoryTimelineData = ({
  selectedActions,
  scenario,
  session,
}) => {
  const { data: routes } = useSessionsGetActionsSuspenseQuery({
    scenario,
    session,
  });
  const { data: summary } = useSuspenseQuery(
    getSessionSummaryQueryOptions({
      scenario,
      session,
      commonness: false,
    })
  );
  const { data: sessionData } = useSessionDetails();

  const fieldInsights = useFieldInsights(sessionData);

  const actionKeys = useMemo(() => {
    const actionKeys = new Set();
    for (const item of routes) {
      const keys = item?.action
        .split("\t")
        .map((t) => t.split("=")[0])
        .filter((t) => !!t);

      for (const key of keys) {
        actionKeys.add(key);
      }
    }
    return actionKeys;
  }, [routes]);

  const timelineData = useMemo(() => {
    const relevantActions = new Set(summary.relevance.map((r) => r.action));

    const timelineData = [];
    let skippedItems = [];
    const addToTimeline = () => {
      timelineData.push({
        skipped: true,
        items: [...skippedItems],
      });
      skippedItems = [];
    };

    const actionsToShow =
      selectedActions.size > 0 ? selectedActions : relevantActions;
    for (let route of routes) {
      if (actionsToShow.has(route.action)) {
        if (skippedItems.length > 0) {
          addToTimeline();
        }
        timelineData.push(route);
        continue;
      }
      if (relevantActions.has(route.action)) {
        route.relevant = true;
      }
      skippedItems.push(route);
    }
    if (skippedItems.length > 0) {
      addToTimeline();
    }
    return timelineData;
  }, [routes, selectedActions, summary.relevance]);

  return useMemo(
    () => ({ timelineData, actionKeys, fieldInsights }),
    [timelineData, actionKeys, fieldInsights]
  );
};
