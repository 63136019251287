import { useEffect, useRef, useState } from "react";

import {
  Checkbox,
  Chip,
  FormControlLabel,
  RadioGroup,
  styled,
} from "@mui/material";

import {
  useScenarioNotificationsMutation,
  useScenarioQuery,
} from "api/scenarios";

import BrandedRadio from "components/ui/BrandedRadio";
import Flexbox from "components/ui/Flexbox";
import FrameActions from "components/ui/FrameActions";
import FrameContent from "components/ui/FrameContent";
import { UiPrimaryButton } from "components/ui/StyledButtons";

import { emailRegex } from "utils/emailRegex";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";

import SettingsOption from "./SettingsOption";

const StyledSettingsNotifications = styled(Flexbox)(() => ({
  gap: "10px",
  paddingRight: "8px",

  "& .chip": {
    "& span": {
      color: "white",
    },
  },
  "& .label": {
    width: "70px",
  },
  "& .notificationsRecipientsChipInput": {
    appearance: "none",
    border: "none",
    borderColor: "#000000de",
    display: "inline-block",
    flex: 1,
    flexWrap: "wrap",
    minHeight: "32px",
    minWidth: "70px",
    overflow: "hidden",
    textOverflow: "elipsis",
    whiteSpace: "nowrap",
    "&:focus": {
      outline: "none",
    },
  },
  "& .notificationsRecipientsChipInputWrapper": {
    display: "inline-flex",
    flex: 1,
    flexWrap: "wrap",
    minWidth: "70px",
  },
  "& .notificationsRecipientsChipWrapper": {
    border: "1px solid #ddd",
    display: "flex",
    flexFlow: "row wrap",
    gap: "0.5em",
    minHeight: "40px",
    padding: "5px",
  },
  "& .notificationsRecipientsErrorMessage": {
    color: "red",
    position: "absolute",
  },
  "& .notificationsRecipientsInnerWrapper": {
    position: "relative",
    width: "100%",
  },
  "& .notificationsRecipientsWrapper": {
    alignItems: "center",
    display: "flex",
    gap: "1em",
    minWidth: "350px",
    width: "100%",
  },
  "& .notificationsTypeWrapper": {
    alignItems: "center",
    display: "flex",
    gap: "1em",
  },
}));

const notificationsFieldNames = {
  notifications_enabled: "notifications_enabled",
  notifications_recipients: "notifications_recipients",
  notifications_type: "notifications_type",
};

const SettingsNotifications = ({ fixedOption = false, id }) => {
  const strings = useLocalizedStrings();

  const { pushMessage } = useMessages();
  const caps = useCapabilities();
  const inputRef = useRef();
  const writeAllowed = caps({ "acm.scenarios": { write: true } });

  const [notificationsState, setNotificationsState] = useState({
    notifications_type: "simple",
  });
  const [errorMessageState, setErrorMessageState] = useState(false);

  const { data: scenario } = useScenarioQuery({
    scenarioId: id,
    includeLogs: false,
  });

  const { mutate: updateScenarioNotifications } =
    useScenarioNotificationsMutation();

  useEffect(() => {
    if (scenario) {
      setNotificationsState({
        notifications_enabled: scenario?.notifications_enabled,
        notifications_recipients: scenario?.notifications_recipients,
        notifications_type: scenario?.notifications_type || "simple",
      });
    }
  }, [id, scenario]);

  const handleDeleteChip = (email) => {
    const updatedReceipientsArray =
      notificationsState.notifications_recipients.filter(
        (recipient) => recipient !== email
      );

    setNotificationsState({
      ...notificationsState,
      notifications_recipients: updatedReceipientsArray,
    });
  };

  const handleSave = () => {
    updateScenarioNotifications(
      {
        scenarioId: id,
        details: notificationsState,
      },
      {
        onSuccess: () => {
          pushMessage(
            "success",
            strings.scenariosettings_notifications_success
          );
        },
      }
    );
  };

  const handleNotificationsEnabledChange = (e) => {
    setNotificationsState((prev) => ({
      ...prev,
      [notificationsFieldNames.notifications_enabled]: e.target.checked,
    }));
  };

  const handleKeyDown = (e) => {
    const { value } = e.target;
    const receipientsArray =
      notificationsState[notificationsFieldNames.notifications_recipients];

    if (e.key === "Enter" || e.type === "blur") {
      if (!!errorMessageState || value.length === 0) {
        return;
      }

      receipientsArray.push(value);
      inputRef.current.value = "";
      setNotificationsState((prev) => ({
        ...prev,
        [notificationsFieldNames.notifications_recipients]: receipientsArray,
      }));
    }

    if (e.key === "Backspace") {
      if (value.length === 0) {
        receipientsArray.pop();
        setNotificationsState((prev) => ({
          ...prev,
          [notificationsFieldNames.notifications_recipients]: receipientsArray,
        }));
        return;
      }

      setErrorMessageState(null);
      return;
    }
  };

  const handleRecipientsChange = (e) => {
    const { value } = e.target;
    const receipientsArray =
      notificationsState[notificationsFieldNames.notifications_recipients];

    if (value.length === 0) {
      setErrorMessageState(null);
      return;
    }

    if (receipientsArray.includes(value)) {
      setErrorMessageState(
        strings.scenariosettings_notifications_recipients_invalid_mail_added_message
      );
      return;
    }

    if (!value.match(emailRegex)) {
      setErrorMessageState(
        strings.scenariosettings_notifications_recipients_invalid_mail_format_message
      );
      return;
    }

    setErrorMessageState(null);
  };

  const handleNotificationsTypeChange = (e) => {
    const { value } = e.target;
    setNotificationsState((prev) => ({
      ...prev,
      [notificationsFieldNames.notifications_type]: value,
    }));
  };

  return (
    <SettingsOption title={strings.scenariosettings_notifications_title}>
      <FrameContent style={{ overflow: "unset" }}>
        <StyledSettingsNotifications
          justifyContent="center"
          width={1}
          flexDirection="column"
          alignItems="start"
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={notificationsState.notifications_enabled || false}
                  disabled={!writeAllowed}
                  name={notificationsFieldNames.notifications_enabled}
                  onChange={handleNotificationsEnabledChange}
                />
              }
              label={strings.scenariosettings_notifications_enabled_label}
            />
          </div>

          <div className="notificationsRecipientsWrapper">
            <label className="label">
              {strings.scenariosettings_notifications_recipients_label}
            </label>
            <div className="notificationsRecipientsInnerWrapper">
              <div className="notificationsRecipientsChipWrapper">
                {notificationsState?.notifications_recipients &&
                  notificationsState?.notifications_recipients.map((email) => (
                    <Chip
                      className="chip"
                      color="primary"
                      disabled={!writeAllowed}
                      key={email}
                      label={email}
                      onDelete={() => handleDeleteChip(email)}
                    />
                  ))}

                <div className="notificationsRecipientsChipInputWrapper">
                  <input
                    className="notificationsRecipientsChipInput"
                    disabled={!writeAllowed}
                    name={notificationsFieldNames.notifications_recipients}
                    onBlur={handleKeyDown}
                    onKeyDown={handleKeyDown}
                    onChange={handleRecipientsChange}
                    placeholder={
                      strings.scenariosettings_notifications_recipients_placeholder
                    }
                    ref={inputRef}
                  />
                </div>
              </div>
              <span className="notificationsRecipientsErrorMessage">
                {errorMessageState ? errorMessageState : null}
              </span>
            </div>
          </div>
          {!fixedOption && (
            <div className="notificationsTypeWrapper">
              <label className="label">
                {strings.scenariosettings_notifications_type_label}
              </label>
              <RadioGroup
                disabled={!writeAllowed}
                name={notificationsFieldNames.notifications_type}
                onChange={handleNotificationsTypeChange}
                row
                value={notificationsState.notifications_type}
              >
                <FormControlLabel
                  control={<BrandedRadio value={"simple"} />}
                  label={strings.scenariosettings_notifications_type_simple}
                />
                <FormControlLabel
                  control={<BrandedRadio value={"detailed"} />}
                  label={strings.scenariosettings_notifications_type_detailed}
                />
              </RadioGroup>
            </div>
          )}
        </StyledSettingsNotifications>
      </FrameContent>
      <FrameActions>
        <UiPrimaryButton
          disabled={
            !writeAllowed ||
            (notificationsState.notifications_enabled &&
              notificationsState.notifications_recipients?.length === 0) ||
            !!errorMessageState
          }
          onClick={handleSave}
        >
          {strings.button_save}
        </UiPrimaryButton>
      </FrameActions>
    </SettingsOption>
  );
};

export default SettingsNotifications;
