import { styled } from "@mui/material";

import Flexbox from "./Flexbox";

const StyledFrameTitle = styled(Flexbox)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  flexGrow: 0,
  "& > *": {
    padding: 0,
  },
}));

export default function FrameTitle(props) {
  const { children, onToggleCollapse, ...rest } = props;

  return (
    <StyledFrameTitle onClick={() => onToggleCollapse?.()} {...rest}>
      {children}
    </StyledFrameTitle>
  );
}
