import { useCallback, useMemo, useState } from "react";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  timelineOppositeContentClasses,
} from "@mui/lab";
import { Box, Collapse } from "@mui/material";

import { parseSensitivityColor } from "utils/parseSensitivityColor";
import { inUtc, timeFormatter } from "utils/time-fmt";

import { ActionDetails } from "./ActionDetails";
import { StoryContextProvider, useStoryContext } from "./StoryTimelineContext";

const formatTime = (dateString, showDate) =>
  showDate
    ? timeFormatter(dateString)
    : inUtc(dateString).format(`HH:mm:ss`).toString();

const TimelineEvent = ({ route, showDate }) => (
  <TimelineItem>
    <TimelineOppositeContent>
      {formatTime(route.time, showDate)}
    </TimelineOppositeContent>
    <TimelineSeparator>
      <TimelineDot
        sx={{
          backgroundColor: parseSensitivityColor(route.sensitivity),
        }}
      />
      <TimelineConnector
        sx={{
          backgroundColor: parseSensitivityColor(route.sensitivity),
          width: "5px",
        }}
      />
    </TimelineSeparator>
    <TimelineContent>
      <ActionDetails action={route} />
    </TimelineContent>
  </TimelineItem>
);

const SkippedEvents = ({ item, showDate }) => {
  const [open, setOpen] = useState(false);

  const handleOpenToggle = useCallback(() => setOpen((prev) => !prev), []);

  const maxSensitivity = useMemo(
    () =>
      item.items.reduce((acc, i) => {
        if (!i.relevant) {
          return acc;
        }
        return i.sensitivity > acc ? i.sensitivity : acc;
      }, 0),
    [item]
  );
  const hasRelevantActions = useMemo(
    () => item.items.some((i) => i.relevant),
    [item]
  );

  const collapseTitle = open
    ? `[ - ] Hide ${item.items.length} events`
    : `[ + ] Show ${item.items.length} events`;

  return (
    <Box>
      <TimelineItem sx={{ minHeight: 1 }}>
        <TimelineOppositeContent>
          {formatTime(item.items[0].time, showDate)}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot
            sx={{
              backgroundColor: hasRelevantActions
                ? parseSensitivityColor(maxSensitivity)
                : "",
            }}
          />
        </TimelineSeparator>
        <TimelineContent sx={{ cursor: "pointer" }} onClick={handleOpenToggle}>
          {collapseTitle}
        </TimelineContent>
      </TimelineItem>
      <Collapse in={open} unmountOnExit timeout={600}>
        {item.items.map((r, idx) => (
          <TimelineItem key={r.time + idx}>
            <TimelineOppositeContent>
              {formatTime(r.time)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  backgroundColor: r.relevant
                    ? parseSensitivityColor(r.sensitivity)
                    : "",
                }}
              />
              <TimelineConnector
                sx={{
                  backgroundColor: r.relevant
                    ? parseSensitivityColor(r.sensitivity)
                    : "",
                  width: "5px",
                }}
              />
            </TimelineSeparator>
            <TimelineContent sx={{ backgroundColor: "ghostwhite" }}>
              <ActionDetails action={r} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Collapse>
    </Box>
  );
};

const StoryTimelineContent = () => {
  const { timelineData } = useStoryContext();

  return (
    <Timeline
      sx={{
        px: 0,
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.14,
          minWidth: "205px",
          color: "text.secondary",
        },
      }}
    >
      {timelineData.map((r, idx) =>
        r.skipped ? (
          <SkippedEvents item={r} key={idx} showDate={idx === 0} />
        ) : (
          <TimelineEvent route={r} key={r.id} showDate={idx === 0} />
        )
      )}
    </Timeline>
  );
};

export const StoryTimeline = ({ selectedActions, scenario, session }) => {
  return (
    <StoryContextProvider
      selectedActions={selectedActions}
      scenario={scenario}
      session={session}
    >
      <StoryTimelineContent />
    </StoryContextProvider>
  );
};
