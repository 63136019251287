import { ButtonGroup, styled } from "@mui/material";

import { UiSecondaryButton } from "./StyledButtons";

const StyledButtonsGroup = styled(ButtonGroup)(({ theme }) => ({
  "& .MuiButton-root": {
    color: `${theme.palette.grey.dark}`,
    borderColor: `${theme.palette.purple.light}`,
    "&:hover": {
      background: `${theme.palette.button.secondary.hoverLight}`,
    },
  },
}));

export const StyledFilterButtonsGroup = ({
  buttons,
  buttonsStyle,
  onClick,
  size = "medium",
  ...rest
}) => {
  return (
    <StyledButtonsGroup size={size} {...rest}>
      {Object.entries(buttons).map(([k, v]) => (
        <UiSecondaryButton
          key={k}
          onClick={(e) => onClick(e, k)}
          style={buttonsStyle}
          value={k}
          variant="outlined"
        >
          {v.label || k}
        </UiSecondaryButton>
      ))}
    </StyledButtonsGroup>
  );
};
