import { useContext, useEffect, useState } from "react";

import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import ReportsAPI, {
  useDownloadReportQuery,
  useReportsGetQuery,
} from "api/reports";
import ScenariosAPI, { useScenarioQuery } from "api/scenarios";
import SessionsAPI from "api/sessions";

import { ScenariosContext } from "contexts/ScenariosContext";

import { ScenarioComparisonTool } from "components/scenarios/ScenarioComparisonTool";
import { SensitivityCoverageTool } from "components/scenarios/SensitivityCoverageTool";
import FrameContent from "components/ui/FrameContent";
import LocalDownloader from "components/ui/LocalDownloader";
import RemoteDownloader from "components/ui/RemoteDownloader";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import { TextFieldInput } from "components/ui/form/TextFieldInput";

import { inUtc } from "utils/time-fmt";

import { useCapabilities } from "hooks/useCapabilities";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import useScenarioJobState from "hooks/useScenarioJobState";

import { DebugInfo } from "./DebugInfo";
import { SessionEvaluationImporter } from "./SessionEvaluationImporter";
import SettingsOption from "./SettingsOption";

const dataExportsHealth = ({ strings, lastJobLogs }) => [
  {
    id: "perf-counters",
    label: strings.scenariosettings_exports_label_perfcounters,
    url: (scenarioID) => ScenariosAPI.getPerfCountersCsvUrl(scenarioID).url,
    filename: (scenarioID) => `perf-counters.${scenarioID}.csv`,
    caps: { "analysis-model.counters": { read: true } },
  },
  {
    id: "operational-info",
    label: strings.scenariosettings_exports_label_scenario_statistics,
    url: (scenarioID) => ScenariosAPI.getOperationalInfoCsvUrl(scenarioID).url,
    filename: (scenarioID) =>
      `scenario_stats_${inUtc(new Date()).format(
        "YYYY-MM-DD"
      )}.${scenarioID}.csv`,
    disabled: !lastJobLogs?.length,
  },
];

const dataExportTool = ({ strings }) => [
  {
    id: "export-session-evaluation",
    label: strings.scenariosettings_exports_label_export_session_evaluation,
    url: (scenarioID) =>
      SessionsAPI.getSessionsEvaluationsJsonUrl(scenarioID).url,
    filename: (scenarioID) => `session-evaluations.${scenarioID}.json`,
  },
  {
    id: "action_config",
    label: strings.scenariosettings_action_info_title,
    url: (scenarioID) => ScenariosAPI.getActionInfoCsvUrl(scenarioID).url,
    filename: (scenarioID) => `action_config.${scenarioID}.csv`,
  },
];

const SettingsExportGroup = ({ buttons, title, style, children }) => {
  const { selectedScenario } = useContext(ScenariosContext);
  const caps = useCapabilities();
  return (
    <div style={style}>
      <Typography variant="body1">{title}:</Typography>
      <div style={{ paddingLeft: 20, paddingTop: 10 }}>
        {buttons
          .filter((e) => !e.caps || caps(e.caps))
          .map((e) =>
            e.inputs?.length ? (
              <ConfirmableReport
                key={e.id}
                sid={selectedScenario.id}
                rid={e.id}
                disabled={e.disabled}
                name={e.filename(selectedScenario.id)}
                label={e.label}
                inputs={e.inputs}
              />
            ) : (
              <RemoteDownloader
                disabled={e.disabled}
                filename={e.filename(selectedScenario.id)}
                key={e.id}
                url={e.url(selectedScenario.id)}
              >
                <UiSecondaryButton
                  disabled={e.disabled}
                  style={{
                    width: "15em",
                    marginBottom: "5px",
                  }}
                >
                  {e.label}
                </UiSecondaryButton>
              </RemoteDownloader>
            )
          )}
        {children}
      </div>
    </div>
  );
};

const ConfirmableReport = ({ sid, rid, disabled, name, label, inputs }) => {
  const strings = useLocalizedStrings();
  const { mutateAsync: downloadReport } = useDownloadReportQuery();

  const methods = useForm({
    defaultValues: inputs.reduce(
      (acc, input) => ({ ...acc, [input.name]: input.default }),
      {}
    ),
    mode: "onChange",
  });
  const { handleSubmit, reset } = methods;
  const isSubmitting = methods.formState.isSubmitting;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const onSubmit = async (formData) => {
    await downloadReport({ sid, rid, name, params: formData });
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, reset]);

  return (
    <div>
      <UiSecondaryButton
        key={rid}
        disabled={disabled}
        onClick={handleOpen}
        style={{
          width: "15em",
          marginBottom: "5px",
        }}
      >
        {label}
      </UiSecondaryButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit((formData) => onSubmit(formData))}>
          <DialogTitle>
            {`${strings.scenariosettings_exports_inputs_dialog_title} - ${label}`}
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              paddingTop: "10px",
            }}
          >
            <FormProvider {...methods}>
              {inputs.map((i) => (
                <TextFieldInput
                  key={i.name}
                  name={i.name}
                  label={i.label}
                  defaultValue={i.default}
                  required
                />
              ))}
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <UiSecondaryButton onClick={() => setOpen(false)}>
              {strings.button_cancel}
            </UiSecondaryButton>
            <UiPrimaryButton type="submit" disabled={isSubmitting}>
              <>
                {strings.button_run}
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    sx={{ position: "absolute", color: "purple.main" }}
                  />
                )}
              </>
            </UiPrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export const ReportsAndTools = () => {
  const { selectedScenario } = useContext(ScenariosContext);
  const strings = useLocalizedStrings();
  const { isRunning } = useScenarioJobState(selectedScenario.id);
  const { data: scenario, refetch } = useScenarioQuery({
    scenarioId: selectedScenario?.id,
    includeLogs: true,
  });

  const lastJobLogs = scenario?.job_log;
  useEffect(() => {
    !isRunning && refetch({ cancelRefetch: false });
  }, [isRunning, refetch]);

  const { data: reports } = useReportsGetQuery();
  const dynamicReports = reports
    ? reports.map((r) => ({
        id: r.id,
        label: r.display_name,
        url: (scenarioID) =>
          ReportsAPI.getScenarioReportById(scenarioID, r.id).url,
        filename: (scenarioID) => `${r.prefix}.${scenarioID}.csv`,
        caps: { "analysis-model.reports": { read: true } },
        inputs: r.inputs,
      }))
    : [];

  return (
    <SettingsOption title={strings.scenariosettings_exports_title}>
      <FrameContent>
        <div style={{ display: "flex", flexDirection: "row", gap: "4em" }}>
          <SettingsExportGroup
            buttons={dynamicReports}
            title={strings.scenariosettings_exports_group_title_model}
          />
          <SettingsExportGroup
            buttons={dataExportTool({
              strings,
              selectedScenario,
              lastJobLogs,
            })}
            title={strings.scenariosettings_exports_group_title_tools}
            style={{ gap: "5em" }}
          >
            <SessionEvaluationImporter />
            <ScenarioComparisonTool />
            <SensitivityCoverageTool />
          </SettingsExportGroup>
        </div>

        <SettingsExportGroup
          buttons={dataExportsHealth({
            strings,
            lastJobLogs,
          })}
          title={strings.scenariosettings_exports_group_title_health}
          style={{ paddingTop: 10 }}
        />
        <LocalDownloader
          data={lastJobLogs || ""}
          filename={`job-log.${selectedScenario.id}.txt`}
          mimetype="text/plain"
          style={{ paddingLeft: 20 }}
        >
          <UiSecondaryButton
            style={{ width: "15em", marginBottom: "5px" }}
            disabled={!lastJobLogs?.length}
          >
            {strings.scenariosettings_exports_label_job_log}
          </UiSecondaryButton>
        </LocalDownloader>
        <div style={{ paddingLeft: 20 }}>
          <DebugInfo scenarioId={selectedScenario.id} />
        </div>
      </FrameContent>
    </SettingsOption>
  );
};
