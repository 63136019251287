import { useAnalysisColumnsStore } from "stores/useAnalysisColumnStore";

export const useDataAnalysisColumns = () => {
  const getAnalysisColumns = useAnalysisColumnsStore(
    (state) => state.getAnalysisColumns
  );
  const columns = useAnalysisColumnsStore((state) => state.analysisColumns);

  return {
    getAnalysisColumns,
    columns,
  };
};
