import { create } from "zustand";

const useDeletedScenariosStore = create((set, get) => ({
  deletedScenarios: [],
  markDeletedScenario: (scenarioId) =>
    set((state) => ({
      deletedScenarios: [...state.deletedScenarios, scenarioId],
    })),
  unmarkDeletedScenario: (scenarioId) =>
    set((state) => ({
      ...state.deletedScenarios.filter((sid) => sid !== scenarioId),
    })),
  isDeletedScenario: (scenarioId) =>
    get().deletedScenarios.includes(scenarioId),
}));

export const useDeletedScenario = () => {
  const isDeletedScenario = useDeletedScenariosStore(
    (state) => state.isDeletedScenario
  );
  const markDeletedScenario = useDeletedScenariosStore(
    (state) => state.markDeletedScenario
  );
  const unmarkDeletedScenario = useDeletedScenariosStore(
    (state) => state.unmarkDeletedScenario
  );

  return {
    isDeletedScenario,
    markDeletedScenario,
    unmarkDeletedScenario,
  };
};
