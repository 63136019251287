import { useCallback } from "react";
import { useEffect } from "react";

import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import useBackendEvents from "hooks/useBackendEvents";
import { storageRemoveByPrefix } from "hooks/useStorage";

import API from "./apibase";
import { downloadResponse, queryFetch } from "./query";

class ScenariosAPI extends API {
  static enumScenariosUrl() {
    return { url: `/api/acm/v1/scenarios` };
  }

  static getScenario(scenario, include_logs = false) {
    return {
      url: `/api/acm/v1/scenarios/key/${scenario}?job_log=${!!include_logs}`,
    };
  }

  static updateScenario(scenario, details) {
    return {
      url: `/api/acm/v1/scenarios/key/${scenario}`,
      options: {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(details || {}),
      },
    };
  }

  static updateScenarioNotifications(scenarioId, details) {
    return {
      url: `/api/acm/v1/scenarios/key/${scenarioId}/notifications`,
      options: {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(details || {}),
      },
    };
  }

  static deleteScenario(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}`,
      options: {
        method: "DELETE",
      },
    };
  }

  static createScenario(details) {
    return {
      url: `/api/acm/v1/scenarios`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(details || {}),
      },
    };
  }

  static resetScenario(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/reset`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({}),
      },
    };
  }

  static cloneScenario(sid, name, description) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/clone`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ name, description }),
      },
    };
  }

  static getScenarioParametersUrl(sid) {
    return { url: `/api/acm/v1/scenarios/key/${sid}/parameters` };
  }

  static getScenarioParametersJson(sid) {
    return { url: `/api/acm/v1/scenarios/key/${sid}/parameters?format=json` };
  }

  static updateScenarioParameterUrl(sid, name, values) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/parameter`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ name, values }),
      },
    };
  }

  static postScenarioParametersUrl(sid, file) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/parameters`,
      options: {
        method: "POST",
        headers: {
          "content-type": file.type,
        },
        body: file,
      },
    };
  }

  static getActionInfoCsvUrl(sid) {
    return { url: `/api/acm/v1/scenarios/key/${sid}/action-info?format=csv` };
  }

  static getFieldSettings(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/field-settings`,
    };
  }

  static getFieldSettingsCsvUrl(sid) {
    return {
      url: ScenariosAPI.getFieldSettings(sid).url + "?format=csv",
    };
  }

  static postFieldSettingsCsvUrl(sid, file) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/field-settings`,
      options: {
        method: "POST",
        headers: {
          "content-type": file.type,
        },
        body: file,
      },
    };
  }

  static getFieldSettingsCount(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/field-settings?count=true`,
    };
  }

  static getSensitiveEventsCsvUrl(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitive_events?format=csv`,
    };
  }

  static postSensitiveEventsCsvUrl(sid, file) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitive_events`,
      options: {
        method: "POST",
        headers: {
          "content-type": file.type,
        },
        body: file,
      },
    };
  }

  static getSensitiveEventsCount(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitive_events?count=true`,
    };
  }

  static getSensitiveUsersCsvUrl(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitive_users?format=csv`,
    };
  }

  static postSensitiveUsersCsvUrl(sid, file) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitive_users`,
      options: {
        method: "POST",
        headers: {
          "content-type": file.type,
        },
        body: file,
      },
    };
  }

  static getSensitiveUsersCount(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitive_users?count=true`,
    };
  }

  static getSensitivityAnomalyFactorsCsvUrl(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitivity_anomaly_factors?format=csv`,
    };
  }

  static postSensitivityAnomalyFactorsCsvUrl(sid, file) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitivity_anomaly_factors`,
      options: {
        method: "POST",
        headers: {
          "content-type": file.type,
        },
        body: file,
      },
    };
  }

  static getSensitivityAnomalyFactorsCount(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/sensitivity_anomaly_factors?count=true`,
    };
  }

  static getDescriptionsCsvUrl(sid) {
    return { url: `/api/acm/v1/scenarios/key/${sid}/descriptions?format=csv` };
  }

  static postDescriptionsCsvUrl(sid, file) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/descriptions`,
      options: {
        method: "POST",
        headers: {
          "content-type": file.type,
        },
        body: file,
      },
    };
  }

  static getDescriptionsCount(sid) {
    return { url: `/api/acm/v1/scenarios/key/${sid}/descriptions?count=true` };
  }

  static getAnalysisEngine(sid) {
    return { url: `/api/acm/v1/scenarios/key/${sid}/engine` };
  }
  static postAnalysisEngine(sid, file, description) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/engine?description=${description}`,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/octet-stream",
        },
        body: file,
      },
    };
  }

  static activateAnalysisEngine(sid, activate) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/engine`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          active: activate,
        }),
      },
    };
  }

  static deleteAnalysisEngine(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/engine`,
      options: {
        method: "DELETE",
      },
    };
  }

  static getPerfCountersCsvUrl(sid) {
    return { url: `/api/analysis-model/v1/${sid}/counters` };
  }

  static getDailyEvents(scenario, numDays = 30) {
    return { url: `/api/audit/v1/${scenario}/services/daily/${numDays}` };
  }

  static getOperationalInfoCsvUrl(sid) {
    return { url: `/api/analysis-model/v1/${sid}/reports/scenario_statistics` };
  }

  static getProgress(scenario) {
    return { url: `/api/acm/v1/scenarios/key/${scenario}/progress` };
  }

  static exportScenario(scenario) {
    return { url: `/api/acm/v1/scenarios/key/${scenario}/export` };
  }

  static importScenario(f, scenarioNameOverride) {
    const s = scenarioNameOverride?.replace(/[^a-zA-Z0-9\- ]+/g, "")?.trim();
    const url = s
      ? `/api/acm/v1/scenarios/import?name=${encodeURIComponent(s)}`
      : `/api/acm/v1/scenarios/import`;
    return {
      url,
      options: {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: f,
      },
    };
  }

  static updateScenarioRank(scenarioID, newRank) {
    return {
      url: `/api/acm/v1/scenarios/key/${scenarioID}/rank`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          rank: newRank,
        }),
      },
    };
  }

  static getAlerters(sid) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/alerters`,
    };
  }

  static updateAlerter(sid, body) {
    return {
      url: `/api/acm/v1/scenarios/key/${sid}/alerters`,
      options: {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      },
    };
  }
}

export default ScenariosAPI;

const baseKey = ["scenarios"];
export const scenarioKeys = {
  list: () => [baseKey, "list"],
  get: ({ scenarioId, includeLogs }) => [
    baseKey,
    scenarioId,
    "get",
    includeLogs,
  ],
  perfCounters: ({ scenarioId }) => [baseKey, scenarioId, "perfCounters"],
  clusters: ({ scenarioId }) => [baseKey, scenarioId, "clusters"],
  locationsRarity: ({ scenarioId }) => [baseKey, scenarioId, "locationsRarity"],
  dailyEvents: ({ scenarioId, numDays }) => [
    baseKey,
    scenarioId,
    "dailyEvents",
    numDays,
  ],
  progress: ({ scenarioId }) => [baseKey, scenarioId, "progress"],
  alerters: ({ scenarioId }) => [baseKey, scenarioId, "alerters"],
  export: ({ scenarioId }) => [baseKey, scenarioId, "export"],
  parameters: ({ scenarioId }) => [baseKey, scenarioId, "parameters"],
  fields: ({ scenarioId }) => [baseKey, scenarioId, "fields"],
};

/* ------Scenario CRUD-------- */

export const useScenariosQuery = () => {
  return useQuery({
    queryKey: scenarioKeys.list(),
    queryFn: () => queryFetch(ScenariosAPI.enumScenariosUrl()),
  });
};

export const useScenarioQuery = ({ scenarioId, includeLogs = false }) => {
  return useQuery({
    queryKey: scenarioKeys.get({ scenarioId, includeLogs }),
    queryFn: () =>
      queryFetch(ScenariosAPI.getScenario(scenarioId, includeLogs)),
    select: useCallback((rows) => rows[0], []),
    enabled: !!scenarioId,
  });
};

export const useUpdateScenarioMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, details }) =>
      queryFetch(ScenariosAPI.updateScenario(id, details)),
    onSuccess: (_data, vars) =>
      queryClient.invalidateQueries({
        queryKey: scenarioKeys.get({ scenarioId: vars.id, includeLogs: false }),
      }),
  });
};

export const useScenarioNotificationsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ scenarioId, details }) =>
      queryFetch(ScenariosAPI.updateScenarioNotifications(scenarioId, details)),
    onSuccess: (_data, { scenarioId }) =>
      queryClient.invalidateQueries({
        queryKey: scenarioKeys.get({ scenarioId, includeLogs: false }),
      }),
  });
};

export const useScenarioDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }) => queryFetch(ScenariosAPI.deleteScenario(id)),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: scenarioKeys.list() });
      if (data.length) {
        storageRemoveByPrefix(localStorage, data[0].id);
      }
    },
  });
};

export const useScenarioCreateMutation = () => {
  return useMutation({
    mutationFn: (details) => queryFetch(ScenariosAPI.createScenario(details)),
  });
};

export const useResetScenarioQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (scenarioId) =>
      queryFetch(ScenariosAPI.resetScenario(scenarioId)),
    onSuccess: (_, scenarioId) =>
      queryClient.invalidateQueries({
        queryKey: scenarioKeys.get({ scenarioId }),
      }),
  });
};

export const useScenarioCloneMutation = () => {
  return useMutation({
    mutationFn: ({ id, name, description }) =>
      queryFetch(ScenariosAPI.cloneScenario(id, name, description)),
  });
};

/* ------CSVs-------- */

export const usePerfCountersCsvQuery = ({ scenarioId }) => {
  return useQuery({
    queryKey: scenarioKeys.perfCounters({ scenarioId }),
    queryFn: () => queryFetch(ScenariosAPI.getPerfCountersCsvUrl(scenarioId)),
  });
};

export const useDailyEventsQuery = ({ scenarioId, numDays }) => {
  return useQuery({
    queryKey: scenarioKeys.locationsRarity({ scenarioId, numDays }),
    queryFn: () => queryFetch(ScenariosAPI.getDailyEvents(scenarioId, numDays)),
  });
};

export const useProgressQuery = ({ scenarioId }) => {
  return useQuery({
    queryKey: scenarioKeys.progress({ scenarioId }),
    queryFn: () => queryFetch(ScenariosAPI.getProgress(scenarioId)),
  });
};
const alertersBackendEvents = ["alerters"];
export const useScenarioAlertersQuery = ({ scenarioId }, opts) => {
  const [backendEvents] = useBackendEvents(alertersBackendEvents, [scenarioId]);
  const queryClient = useQueryClient();
  const queryKey = scenarioKeys.alerters({ scenarioId });

  const mutation = useMutation({
    mutationFn: ({ alerter_id, associated }) =>
      queryFetch(
        ScenariosAPI.updateAlerter(scenarioId, { alerter_id, associated })
      ),
    onMutate: async ({ alerter_id, associated }) => {
      await queryClient.cancelQueries({ queryKey });
      const prevAlerters = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (old) =>
        old.map((a) => (a.alerter_id === alerter_id ? { ...a, associated } : a))
      );
      return { prevAlerters };
    },
    onError: (_err, _mutateData, context) => {
      queryClient.setQueryData(baseKey, context.prevAlerters);
    },
  });

  useEffect(() => {
    if (mutation.isPending) {
      return;
    }
    queryClient.invalidateQueries({
      queryKey: scenarioKeys.alerters({ scenarioId }),
    });
  }, [backendEvents, queryClient, mutation.isPending, scenarioId]);

  const { data: alerters, isPending } = useQuery({
    queryKey: scenarioKeys.alerters({ scenarioId }),
    queryFn: ({ signal }) =>
      queryFetch(ScenariosAPI.getAlerters(scenarioId).url, { signal }),
    ...opts,
  });

  return {
    alerters,
    isPending,
    mutation,
  };
};

/* ------Export / Import -------- */

export const useScenarioExportMutation = () => {
  return useMutation({
    mutationFn: ({ scenarioId }) =>
      queryFetch(ScenariosAPI.exportScenario(scenarioId)),
  });
};

export const useScenarioImportMutation = () => {
  return useMutation({
    mutationFn: ({ file, importNameOverride }) =>
      queryFetch(ScenariosAPI.importScenario(file, importNameOverride)),
  });
};

export const useScenarioCompare = () => {
  return useMutation({
    mutationFn: ({ scenarioX, scenarioY, userData }) => {
      const opts = {
        url: `/api/analysis-model/v1/${scenarioX}/reports/scenario_comparison?other=${encodeURIComponent(
          scenarioY
        )}`,
        options: {
          method: "POST",
          headers: {
            "content-type": "text/csv",
          },
          body: new Blob([userData], { type: "text/csv" }),
        },
      };
      return queryFetch(opts);
    },
    onSuccess: (data, { scenarioX, scenarioY }) =>
      downloadResponse({
        data,
        type: "text/csv",
        fileName: `scenario_comparison_${scenarioX}_vs_${scenarioY}.csv`,
      }),
  });
};

export const useScenarioSensitivityCoverage = () => {
  return useMutation({
    mutationFn: ({ scenarioX, sensitiveEvents }) => {
      const opts = {
        url: `/api/analysis-model/v1/${scenarioX}/reports/sensitivity_coverage`,
        options: {
          method: "POST",
          headers: {
            "content-type": "text/csv",
          },
          body: new Blob([sensitiveEvents], { type: "text/csv" }),
        },
      };
      return queryFetch(opts);
    },
    onSuccess: (data, { sensitiveEvents }) => {
      const fileName = `${sensitiveEvents.name.slice(0, -4)}_coverage.csv`;
      downloadResponse({
        data,
        type: "text/csv",
        fileName,
      });
    },
  });
};

export const useScenarioParametersQuery = (scenarioId) => {
  return useQuery({
    queryKey: scenarioKeys.parameters({ scenarioId }),
    queryFn: () =>
      queryFetch(ScenariosAPI.getScenarioParametersJson(scenarioId)),
    select: useCallback(
      (data) => data?.sort((a, b) => b.name.localeCompare(a.name, "en")),
      []
    ),
    placeholderData: keepPreviousData,
    enabled: !!scenarioId,
  });
};

export const useScenarioUpdateParameterMutation = () => {
  return useMutation({
    mutationFn: ({ scenarioId, name, values }) =>
      queryFetch(
        ScenariosAPI.updateScenarioParameterUrl(scenarioId, name, values)
      ),
  });
};

export const useScenarioUploadParameterMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ scenarioId, file }) =>
      queryFetch(ScenariosAPI.postScenarioParametersUrl(scenarioId, file)),
    onSuccess: (_, { scenarioId }) =>
      queryClient.invalidateQueries({
        queryKey: scenarioKeys.parameters({ scenarioId }),
      }),
  });
};

export const useScenarioFieldSettingsQuery = (scenarioId) => {
  return useQuery({
    queryKey: scenarioKeys.fields({ scenarioId }),
    queryFn: () => queryFetch(ScenariosAPI.getFieldSettings(scenarioId)),
    enabled: !!scenarioId,
  });
};

export const useScenarioUpdateRankMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ scenarioId, newRank }) =>
      queryFetch(ScenariosAPI.updateScenarioRank(scenarioId, newRank)),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: scenarioKeys.list() }),
  });
};

export const useScenarioDebugInfoDownloadMutation = () => {
  return useMutation({
    mutationFn: ({ scenarioId }) =>
      queryFetch(`/api/acm/v1/scenarios/key/${scenarioId}/debug-info`),
    onSuccess: (data, { scenarioId }) =>
      downloadResponse({
        data,
        type: "application/zip",
        fileName: `debug-info-${scenarioId}.zip`,
      }),
  });
};
