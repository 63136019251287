import { GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import theme from "themes/MainTheme";

export const StyleProviders = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {/*
       * Mapping editor perf optimization
       * Inject global styles once here, to avoid doing it on every mount
       */}
      <GlobalStyles
        styles={{
          "@keyframes mui-auto-fill": { from: { display: "block" } },
          "@keyframes mui-auto-fill-cancel": {
            from: { display: "block" },
          },
        }}
      />
      {children}
    </ThemeProvider>
  );
};
