import { useCallback, useEffect, useMemo, useState } from "react";

import { MenuItem, styled } from "@mui/material";
import { isString } from "lodash-es";
import { FormProvider, useForm } from "react-hook-form";

import {
  useCollectorsCreateMutation,
  useCollectorsUpdateMutation,
} from "api/collectors";

import MapingConfigLocalDownloader from "components/scenarios/MappingEditor/components/MapingConfigLocalDownloader";
import { EnrichmentTabComponent } from "components/scenarios/MappingEditor/tabComponents/enrichment/EnrichmentTabComponent";
import FilteringTabComponent from "components/scenarios/MappingEditor/tabComponents/filtering/FilteringTabComponent";
import ApplicationIcon from "components/ui/ApplicationIcon";
import FileUpload from "components/ui/FileUpload";
import Flexbox from "components/ui/Flexbox";
import Footnotes from "components/ui/Footnotes";
import {
  UiPrimaryButton,
  UiSecondaryButton,
} from "components/ui/StyledButtons";
import { CheckboxInput } from "components/ui/form/CheckboxInput";
import { ClearableInput } from "components/ui/form/ClearableInput";
import { HiddenInput } from "components/ui/form/HiddenInput";
import { SecretOptionsInput } from "components/ui/form/SecretInput";
import { SelectorInput } from "components/ui/form/SelectorInput";
import { TextFieldInput } from "components/ui/form/TextFieldInput";
import { EditorTab } from "components/ui/navigation/EditorTab";

import { useCapabilities } from "hooks/useCapabilities";
import { useDataAnalysisColumns } from "hooks/useDataAnalysisColumns";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import { useMessages } from "hooks/useMessage";
import useMounted from "hooks/useMounted";

import TabHeader from "./TabHeader";

const StyledConfigureTab = styled("div")`
  padding: 2em;
  padding-bottom: 0;
  overflow-y: auto;
  &.create {
    .form-header {
      color: #dd0100;
    }
  }
  .collector-form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    min-width: 950px;
    height: calc(100vh - 190px);
    padding-bottom: 1em;
    .form-content {
      flex-direction: column;
      gap: 1em;
      flex: 1;
      overflow-y: auto;
      padding: 1em;
    }
    * {
      font-family: inherit;
      line-height: inherit;
    }
    .form-header {
    }
    .collection-activation-checkbox {
      margin-left: 0;
    }
    .collector-options {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5em;
    }
    .form-actions {
      display: flex;
      height: 40px;
      position: relative;
      justify-content: flex-end;
      gap: 10px;
      & > * {
        flex-basis: 8em;
      }
      .footnotes {
        position: absolute;
        max-width: 330px;
        left: 0;
      }
    }
    .MuiMenuItem-root,
    .MuiSelect-selectMenu {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    #data_retention_input {
      ::placeholder {
        opacity: 0.6;
      }
    }
    .MuiInputBase-root {
      background: #fff;
    }
    .MuiOutlinedInput-root textarea {
      padding: 0px;
    }
  }
`;

const CollectorTabs = ({
  enabled,
  filter,
  enrichment,
  transformations,
  selectedTab,
  setSelectedTab,
  errors,
}) => {
  const strings = useLocalizedStrings();

  const filteringErrors = !!errors?.options?.internal_config?.filtering;
  const enrichmentErrors = !!errors?.options?.internal_config?.enrichment;
  const optionErrors = Object.keys(errors?.options || {}).filter(
    (key) => key !== "internal_config"
  );
  const otherErrors = Object.keys(errors || {}).filter(
    (key) => key !== "options"
  );
  const basicErrors = optionErrors.length > 0 || otherErrors.length > 0;

  if (!enabled) {
    return null;
  }

  return (
    <div>
      <EditorTab
        selected={selectedTab === 0}
        hasError={basicErrors}
        onClick={() => setSelectedTab(0)}
      >
        {strings.sourcedialog_tabName_basic_options}
      </EditorTab>
      {filter && (
        <EditorTab
          selected={selectedTab === 1}
          hasError={filteringErrors}
          onClick={() => setSelectedTab(1)}
        >
          Filtering
        </EditorTab>
      )}
      {transformations && (
        <EditorTab
          selected={selectedTab === 2}
          onClick={() => setSelectedTab(2)}
        >
          Transformations
        </EditorTab>
      )}
      {enrichment && (
        <EditorTab
          selected={selectedTab === 3}
          hasError={enrichmentErrors}
          onClick={() => setSelectedTab(3)}
        >
          Enrichment
        </EditorTab>
      )}
    </div>
  );
};

const CollectorMapping = ({
  enabled,
  selectedTab,
  emptyOpSupport,
  numericOpSupport,
  filter,
  enrichment,
  transformations,
  enrichmentFromBucket,
}) => {
  if (!enabled) {
    return null;
  }

  return (
    <>
      {filter && (
        <div
          className="form-content"
          style={{ display: selectedTab === 1 ? "flex" : "none" }}
        >
          <FilteringTabComponent
            emptyOpSupport={emptyOpSupport}
            numericOpSupport={numericOpSupport}
          />
        </div>
      )}
      {transformations && (
        <div
          className="form-content"
          style={{ display: selectedTab === 2 ? "flex" : "none" }}
        >
          <HiddenInput
            name="options.internal_config.transformations.entries.0.type"
            value="python"
          />
          <TextFieldInput
            name="options.internal_config.transformations.entries.0.code"
            placeholder="Python code"
            multiline={true}
            minRows={10}
            maxRows={30}
          />
        </div>
      )}
      {enrichment && (
        <div
          className="form-content"
          style={{ display: selectedTab === 3 ? "flex" : "none" }}
        >
          <EnrichmentTabComponent enrichmentFromBucket={enrichmentFromBucket} />
        </div>
      )}
    </>
  );
};

const ConfigUpload = ({ enabled, getValues, setData }) => {
  const strings = useLocalizedStrings();
  const { pushMessage } = useMessages();
  const supportedMappingTabs = ["filtering", "transformations", "enrichment"];

  const parseAndValidateConfigFile = (jsonFile) => {
    let jsonData;
    try {
      jsonData = JSON.parse(jsonFile);
    } catch (ignore) {
      throw new Error(strings.sourcedialog_file_upload_error_message);
    }
    for (const key in jsonData) {
      if (!supportedMappingTabs.includes(key)) {
        throw new Error(
          strings.sourcedialog_file_upload_collector_error_message
        );
      }
    }
    return jsonData;
  };

  const handleConfigUpload = (file) => {
    const readFile = new FileReader();
    readFile.onload = function (e) {
      try {
        const jsonData = parseAndValidateConfigFile(e.target.result);
        setData(jsonData);
        pushMessage(
          "success",
          strings.sourcedialog_file_upload_success_message
        );
      } catch (e) {
        pushMessage("error", e.message);
      }
    };
    readFile.readAsText(file);
  };

  if (!enabled) {
    return null;
  }

  return (
    <div style={{ flexBasis: "25em" }}>
      <Flexbox style={{ width: "400px" }}>
        <FileUpload accept="application/json" onUpload={handleConfigUpload} />
        <MapingConfigLocalDownloader
          filename={`collector.json`}
          onClick={getValues}
        />
      </Flexbox>
    </div>
  );
};

const DataRetentionControls = ({
  data_retention_period,
  setValue,
  writeAllowed,
}) => {
  const strings = useLocalizedStrings();
  const maxPeriod = 3650;
  const maxDataLimit = 1_000_000;

  return (
    <div
      style={{
        display: "flex",
        gap: "1.5em",
      }}
    >
      <ClearableInput
        id="data_retention_input"
        disabled={!writeAllowed}
        label={strings.collectors_config_label_data_retention_limit}
        name="external_data_retention_limit"
        placeholder="Unlimited"
        autoComplete="off"
        InputLabelProps={{ shrink: true }}
        rules={{
          pattern: {
            value: /^((?=[1-9])\d*)\s*$/,
            message: strings.invalid_input,
          },
          max: {
            value: maxDataLimit,
            message: strings.formatString(
              strings.invalid_max_value,
              Intl.NumberFormat("en-US").format(maxDataLimit)
            ),
          },
        }}
        onClear={() =>
          setValue("external_data_retention_limit", "", {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
      />
      {data_retention_period ? (
        <ClearableInput
          id="data_retention_period"
          disabled={!writeAllowed}
          label={strings.collectors_config_label_data_retention_period}
          name="external_data_retention_period"
          placeholder="Unlimited"
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          rules={{
            pattern: {
              value: /^((?=[1-9])\d*)\s*$/,
              message: strings.invalid_input,
            },
            max: {
              value: maxPeriod,
              message: strings.formatString(
                strings.invalid_max_value,
                Intl.NumberFormat("en-US").format(maxPeriod)
              ),
            },
          }}
          onClear={() =>
            setValue("external_data_retention_period", "", {
              shouldDirty: true,
              shouldValidate: true,
            })
          }
        />
      ) : null}
    </div>
  );
};

const getDefaultValues = (collector) => {
  const newValues = {
    connector: collector?.connector ?? "",
    name: collector?.name ?? "",
    description: collector?.description ?? "",
    options_mode: collector?.options_mode ?? "",
    external_data_retention_limit:
      collector?.external_data_retention_limit ?? "",
    external_data_retention_period: !!collector
      ? collector?.external_data_retention_period ?? ""
      : "365",
    identifier_fields: collector?.identifier_fields ?? "",
    options: {
      ...collector?.options,
      internal_config: collector?.options.internal_config ?? {
        filtering: {
          entries: [],
        },
      },
    },
  };
  return newValues;
};

// NOTE: null collector means we're in 'NEW' mode
const ConfigureTab = ({ collector, files, apps, setSelectedCollector }) => {
  const strings = useLocalizedStrings();
  const { getAnalysisColumns } = useDataAnalysisColumns();
  const { pushMessage } = useMessages();
  const caps = useCapabilities();
  const [selectedTab, setSelectedTab] = useState(0);
  const [uploadedData, setUploadedData] = useState();
  const updateCollectorMutation = useCollectorsUpdateMutation();
  const createCollectorMutation = useCollectorsCreateMutation();

  const mounted = useMounted();
  const methods = useForm({
    mode: "onChange",
    shouldUnregister: true,
    defaultValues: getDefaultValues(collector),
  });

  const {
    formState: { dirtyFields, errors },
  } = methods;

  const currentConnector = methods.watch("connector");
  const currentOptionsMode = methods.watch("options_mode");
  const currentApp = useMemo(
    () => apps.find((a) => a.id === currentConnector),
    [currentConnector, apps]
  );
  const availableModes = Object.entries(currentApp?.options_modes || {});
  const isEditing = !!collector;
  const writeAllowed = caps({ "acm.collectors": { write: true } });

  const {
    external_filter,
    external_transformations,
    enrichment,
    empty_op = false,
    numeric_filtering_ops = false,
    data_retention_period = false,
    duplicate_records_filter,
    enrichment_from_bucket = false,
  } = currentApp?.capabilities ?? {};

  const externalSupport =
    external_filter || external_transformations || enrichment;

  //because useForm cannot keep track of dirty state between file uploads
  const changed = Object.keys(dirtyFields).length !== 0 || !!uploadedData;

  //on Application change, set default mode and options
  useEffect(() => {
    const defaultMode = Object.entries(currentApp?.options_modes || {}).find(
      (om) => om[1].default
    )?.[0];
    const supportedModes = Object.keys(currentApp?.options_modes || {});
    const mode = supportedModes.includes(currentOptionsMode)
      ? currentOptionsMode
      : defaultMode;

    const options = Object.entries(currentApp?.options[mode] || {}).reduce(
      (acc, o) => {
        acc[o[0]] = collector?.options[o[0]] ?? o[1].default;
        return acc;
      },
      {}
    );
    if (mode && supportedModes) {
      methods.setValue("options_mode", mode);
    }
    for (const key in options) {
      methods.setValue(`options.${key}`, options[key]);
    }
    const t = setTimeout(
      () => methods.trigger("options.internal_config.enrichment"),
      100
    );
    return () => clearTimeout(t);
  }, [currentApp, methods, currentOptionsMode, collector]);

  useEffect(() => {
    if (!external_filter || !collector?.id) {
      return;
    }
    getAnalysisColumns(collector, { collector: true });
  }, [collector, getAnalysisColumns, external_filter]);

  //needed to update fields after upload
  useEffect(() => {
    if (!uploadedData) {
      return;
    }
    methods.reset((formData) => {
      if (!formData || !formData.options) {
        return formData;
      }
      formData.options.internal_config = uploadedData;
      return formData;
    });
    let to;
    if (uploadedData) {
      to = setTimeout(() => methods.trigger(), 50);
    }
    return () => clearTimeout(to);
  }, [methods, uploadedData]);

  const handleRevert = useCallback(() => {
    methods.reset(getDefaultValues(collector));
    setUploadedData();
    setSelectedTab(0);
  }, [methods, collector]);

  //reset on collector change
  useEffect(() => {
    handleRevert();
  }, [handleRevert]);

  const onSubmit = (formData) => {
    //trim options, and if in editing mode
    //return the secret placeholders
    //that the backend expects
    const optionParams = currentApp.options[formData.options_mode];
    const optionParamKeys = Object.keys(optionParams);
    const parsedOptions = Object.entries(formData.options).reduce(
      (parsed, [optKey, optVal]) => {
        if (
          optKey === "secrets" ||
          (optKey === "internal_config" && externalSupport)
        ) {
          parsed[optKey] = optVal;
          return parsed;
        }
        if (!optionParamKeys.includes(optKey)) {
          return parsed;
        }

        const { secret = false } = optionParams[optKey];
        const trimmed = typeof optVal === "string" ? optVal?.trim() : optVal;

        parsed[optKey] = isEditing && trimmed === "" && secret ? null : trimmed;
        return parsed;
      },
      {}
    );

    const getTransformedString = (value) => {
      if (isString(value)) {
        if (value === "") {
          return null;
        } else {
          return value.trim();
        }
      } else {
        return value;
      }
    };

    const info = {
      connector: formData.connector?.trim(),
      name: formData.name?.trim(),
      description: formData.description?.trim(),
      external_data_retention_limit: getTransformedString(
        formData.external_data_retention_limit
      ),
      external_data_retention_period: data_retention_period
        ? getTransformedString(formData.external_data_retention_period)
        : null,
      data_sample_size: formData.data_sample_size,
      options: parsedOptions,
      options_mode: formData.options_mode,
      identifier_fields: duplicate_records_filter
        ? getTransformedString(formData.identifier_fields)
        : null,
    };

    if (isEditing) {
      updateCollectorMutation.mutate(
        {
          id: collector.id,
          info,
        },
        {
          onSuccess: () => {
            if (!mounted) {
              return;
            }
            pushMessage(
              "success",
              strings.collectors_config_saved_successfully
            );
            setUploadedData();
          },
        }
      );
    } else {
      createCollectorMutation.mutate(info, {
        onSuccess: (updatedCollector) => {
          if (mounted) {
            setSelectedCollector(updatedCollector?.id);
          }
        },
      });
    }
  };

  const displayedCollectorOptions = useMemo(() => {
    const collectorOptions = Object.entries(
      currentApp?.options?.[currentOptionsMode] || {}
    ).sort((a, b) => a?.[1]?.display_order - b?.[1]?.display_order);

    return collectorOptions.map(([k, v]) => {
      const key = `options.${currentOptionsMode}.${k}`;
      const name = `options.${k}`;

      if (v.datatype === "file") {
        return (
          <SelectorInput
            key={key}
            required={!!v.required}
            name={name}
            label={v.label || ""}
            rules={{
              validate: (val) =>
                files?.some((f) => f.id === val) ||
                "" === val ||
                strings.sourcedialog_mapping_editor_enrichment_tab_invalid_file_error,
            }}
          >
            {!v.required && (
              <MenuItem value="" style={{ fontStyle: "italic" }}>
                {strings.text_none}
              </MenuItem>
            )}
            {files?.map((f) => (
              <MenuItem value={f.id} key={f.id}>
                {f.name}
              </MenuItem>
            ))}
          </SelectorInput>
        );
      }
      if (v.secret) {
        return (
          <SecretOptionsInput
            multiline={true}
            disabled={!writeAllowed}
            autoComplete="off"
            helperText={v.help}
            rules={{
              pattern: {
                value: /^[^\s]+.*/,
                message: strings.invalid_input,
              },
            }}
            key={key}
            autoCorrect="false"
            spellCheck="false"
            name={name}
            label={v.label || ""}
            required={!isEditing && !!v.required}
          />
        );
      }
      if (v.datatype === "bool") {
        return <CheckboxInput key={key} name={name} label={v.label} />;
      }
      return (
        <TextFieldInput
          disabled={!writeAllowed}
          helperText={v.help}
          rules={{
            pattern: {
              value: /^[^\s]+.*/,
              message: strings.invalid_input,
            },
          }}
          key={key}
          name={name}
          label={v.label || ""}
          multiline
          autoCorrect="false"
          spellCheck="false"
          required={!!v.required}
        />
      );
    });
  }, [
    currentOptionsMode,
    currentApp,
    isEditing,
    strings.invalid_input,
    strings.sourcedialog_mapping_editor_enrichment_tab_invalid_file_error,
    strings.text_none,
    writeAllowed,
    files,
  ]);
  const tabHeaderName = isEditing
    ? strings.collectors_config_header_update
    : strings.collectors_config_header_new;

  const footNotes = [
    changed || !collector ? strings.collectors_config_footnote : undefined,
  ];

  const submitDisabled =
    (isEditing && !changed) || !caps({ "acm.collectors": { write: true } });

  return (
    <StyledConfigureTab
      className={`collector-configure-wrapper ${
        collector ? "update" : "create"
      }`}
    >
      <FormProvider {...methods}>
        <form
          className="collector-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <TabHeader collector={collector} name={tabHeaderName} />
          <hr />
          <CollectorTabs
            enabled={externalSupport}
            filter={external_filter}
            enrichment={enrichment}
            transformations={external_transformations}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            errors={errors}
          />
          <div
            className="form-content"
            style={{ display: selectedTab === 0 ? "flex" : "none" }}
          >
            <div className="collector-options">
              <SelectorInput
                name="connector"
                disabled={!writeAllowed || !apps?.length}
                label={strings.collectors_config_label_application}
                required
              >
                {apps.map((a) => (
                  <MenuItem key={a.connector} value={a.connector}>
                    <ApplicationIcon app={a} />
                    <span style={{ paddingLeft: "1em" }}>{a.description}</span>
                  </MenuItem>
                ))}
              </SelectorInput>
              <SelectorInput
                disabled={
                  !writeAllowed ||
                  Object.keys(currentApp?.options_modes || {}).length < 2
                }
                label="Mode"
                name="options_mode"
              >
                {availableModes.map((m) => (
                  <MenuItem key={m[0]} value={m[0]}>
                    <span>{m[1].label}</span>
                  </MenuItem>
                ))}
              </SelectorInput>
            </div>
            <div className="collector-options">
              <TextFieldInput
                disabled={!writeAllowed}
                label={strings.collectors_config_label_name}
                name="name"
                required
              />
              <TextFieldInput
                disabled={!writeAllowed}
                label={strings.collectors_config_label_description}
                name="description"
                required
              />
            </div>
            <div className="collector-options">
              <DataRetentionControls
                data_retention_period={data_retention_period}
                writeAllowed={writeAllowed}
                setValue={methods.setValue}
              />
              {duplicate_records_filter ? (
                <TextFieldInput
                  id="identifier_fields"
                  disabled={!writeAllowed}
                  label={"Remove duplicate records (identifier field)"}
                  name="identifier_fields"
                  placeholder="Comma separated identifier fields"
                  autoComplete="off"
                  InputLabelProps={{ shrink: true }}
                  rules={{
                    pattern: {
                      value: /^([^,]+)(,([^,]+))*$/,
                      message: strings.invalid_input,
                    },
                  }}
                />
              ) : null}
            </div>
            <div className="collector-options">{displayedCollectorOptions}</div>
          </div>
          <CollectorMapping
            enabled={externalSupport}
            emptyOpSupport={empty_op}
            numericOpSupport={numeric_filtering_ops}
            transformations={external_transformations}
            filter={external_filter}
            enrichment={enrichment}
            selectedTab={selectedTab}
            enrichmentFromBucket={enrichment_from_bucket}
          />
          <hr />
          <div className="form-actions">
            <Footnotes notes={footNotes} />
            <ConfigUpload
              enabled={externalSupport}
              setData={setUploadedData}
              getValues={methods.getValues}
            />
            {isEditing && (
              <UiSecondaryButton disabled={!changed} onClick={handleRevert}>
                {strings.collectors_config_label_revert}
              </UiSecondaryButton>
            )}
            <UiPrimaryButton type="submit" disabled={submitDisabled}>
              {collector
                ? strings.collectors_config_label_save
                : strings.collectors_config_label_create}
            </UiPrimaryButton>
          </div>
        </form>
      </FormProvider>
    </StyledConfigureTab>
  );
};

export default ConfigureTab;
