import { useMemo } from "react";

import AuditAPI from "api/audit";

import GeneralizedTable from "components/ui/GeneralizedTable";
import StatusIcon from "components/ui/StatusIcon";

import { timeFormatter } from "utils/time-fmt";

import { useReportedFetch } from "hooks/http";
import useBackendEvents from "hooks/useBackendEvents";
import useLocalizedStrings from "hooks/useLocalizedStrings";
import useScenarioJobState from "hooks/useScenarioJobState";

const columns = (strings) => [
  {
    id: "completed",
    label: "",
    style: {
      width: "3em",
    },
    render: (s) => (
      <StatusIcon
        status={s.completed ? "success" : s.running ? "active" : "error"}
      />
    ),
  },
  {
    id: "time",
    label: strings.dashboard_last_collection_time,
    style: {
      width: "8em",
    },
    render: (s) => timeFormatter(new Date(s?.time)),
  },
  {
    id: "description",
    label: strings.dashboard_last_collection_description,
    style: {
      width: "auto",
      wordBreak: "normal",
      fontWeight: "bold",
    },
    render: (s) => s.description,
  },
  {
    id: "messages",
    label: strings.dashboard_last_collection_message,
    style: {
      width: "auto",
      wordBreak: "normal",
    },
    render: (s) => s.messages || "--",
  },
];

const sourcesBackendEvents = ["audit"];

function useMostRecentAuditCycle(scenario) {
  const strings = useLocalizedStrings();
  const [backendEvent] = useBackendEvents(sourcesBackendEvents, [scenario]);

  const { isRunning } = useScenarioJobState(scenario);

  const [statuses] = useReportedFetch(AuditAPI.getUrl(scenario).url, [
    scenario,
    backendEvent,
  ]);

  const mostRecentCycle = useMemo(() => {
    const validStatuses = statuses?.filter((d) => !!d.run_id);
    if (!validStatuses?.length) {
      return [];
    }
    const run_id = validStatuses[0].run_id;
    return validStatuses
      .filter((d) => d.run_id === run_id)
      .map((d) => {
        const r = {
          orig: d,
          id: d.id,
          time: d.time,
          duration: d.duration,
          messages: d.messages,
          completed: d.completed,
          running: isRunning,
        };
        switch (d.service) {
          case "collector": {
            switch (d.extra?.class) {
              case "collect": {
                r.description = strings.formatString(
                  strings.dashboard_last_collection_collector_collect,
                  d.extra?.num_events,
                  d.extra.source?.toUpperCase()
                );
                break;
              }
              case "load": {
                r.description = strings.formatString(
                  strings.dashboard_last_collection_collector_load,
                  d.extra?.num_events
                );
                break;
              }
              default: {
                r.description = `${strings.dashboard_last_collection_collector_collecting}...`;
                break;
              }
            }
            break;
          }
          case "analysis": {
            r.description = strings.formatString(
              strings.dashboard_last_collection_analysis,
              d.completed ? "" : "..."
            );
            break;
          }
          default: {
            break;
          }
        }
        return r;
      })
      .sort((a, b) => +a.id - +b.id);
  }, [statuses, strings, isRunning]);

  return mostRecentCycle;
}

export const SourcesLastStatus = (props) => {
  const strings = useLocalizedStrings();
  const { scenario } = props;
  const mostRecentCycle = useMostRecentAuditCycle(scenario);

  return (
    <GeneralizedTable
      items={mostRecentCycle}
      columns={columns}
      compactView={true}
      boldHeader={false}
      strings={strings}
    />
  );
};
