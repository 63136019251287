import { useCallback } from "react";

import { useQuery } from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch } from "./query";

export default class RolesAPI extends API {
  static getRoles() {
    return { url: "/api/acm/v1/roles" };
  }
}

const queryKey = ["roles"];

export const useRolesQuery = () => {
  return useQuery({
    queryKey,
    queryFn: () => queryFetch(RolesAPI.getRoles()),
    select: useCallback(
      (roles) =>
        roles.map((r) => ({
          role: r.role,
          displayName: r.role.replace("_", " "),
        })),
      []
    ),
    staleTime: Infinity,
  });
};
