import { useEffect, useRef, useState } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { IconButton, styled } from "@mui/material";

import Flexbox from "./Flexbox";

const StyledFileUpload = styled(Flexbox)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  border: "1px solid #ddd",
  borderRadius: "6px",
  padding: `${0}px ${theme.spacing(1)}`,
  height: "3em",
  flexGrow: 1,
  maxWidth: "47em",
  "& .select-file": {
    flexGrow: 0,
    padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
  },
  "& .filename": {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    flexGrow: 1,
    border: "none",
    outline: "none",
    overflow: "hidden",
    backgroundColor: "transparent",
  },
  "& .upload-file": {
    flexGrow: 0,
    padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(
      0
    )} ${theme.spacing(0)}`,
  },
}));

function FileUpload({
  accept,
  disabled,
  onUpload,
  placeholder = "Choose a file to upload",
  refresh,
  ...rest
}) {
  const [files, setFiles] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    handleClear();
  }, [refresh]);

  const handleChange = (e) => {
    setFiles(e.target.files);
  };

  const handleClear = () => {
    inputRef.current.type = "";
    inputRef.current.type = "file";
    setFiles(null);
  };

  const handleUpload = () => {
    onUpload && files && files[0] && onUpload(files[0]);
    !refresh && handleClear();
  };

  return (
    <StyledFileUpload {...rest}>
      <IconButton
        onClick={() => inputRef.current.click()}
        style={{ flexGrow: 0, padding: 0 }}
        disabled={disabled}
        size="large"
      >
        <InsertDriveFileOutlinedIcon />
      </IconButton>

      <div className="filename" style={{ padding: "0 4px" }}>
        {files && files.length ? files[0].name : placeholder}
      </div>
      <input
        ref={inputRef}
        id="file-selection"
        type="file"
        accept={accept}
        onChange={handleChange}
        style={{ display: "none" }}
        disabled={disabled}
      />

      <IconButton
        className="clear"
        size="small"
        onClick={handleClear}
        disabled={!files || disabled}
      >
        <ClearIcon size="small" />
      </IconButton>

      <IconButton
        onClick={handleUpload}
        style={{ flexGrow: 0, padding: 0 }}
        disabled={!files || disabled}
        size="large"
      >
        <CloudUploadOutlinedIcon />
      </IconButton>
    </StyledFileUpload>
  );
}

export default FileUpload;
