import { useCallback } from "react";

import {
  keepPreviousData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import API from "./apibase";
import { queryFetch, useInfiniteTableContainer } from "./query";

export default class AuditAPI extends API {
  static getUrl(scenario, limit) {
    return { url: `/api/audit/v1/${scenario}/services?limit=${limit || 30}` };
  }

  static getUsage(fromDate, toDate, format = "json") {
    const sFromDate = encodeURIComponent(fromDate.toISOString());
    const sToDate = encodeURIComponent(toDate.toISOString());
    return {
      url: `/api/audit/v1/usage/${sFromDate}/${sToDate}?format=${format}`,
    };
  }

  static getSystemLog({ offset, limit, orderby, filters }) {
    const f = JSON.parse(JSON.stringify(filters));
    return {
      url: super.composeChunkedQueryUrl(
        `/api/audit/v1/systemlog`,
        offset,
        limit,
        f,
        orderby,
        "created",
        "desc"
      ),
    };
  }
}

const baseKey = ["audit"];
const auditKeys = {
  list: ({ scenarioId, limit }) => [baseKey, scenarioId, "list", limit],
  usage: ({ fromDate, toDate, format }) => [
    baseKey,
    "usage",
    fromDate,
    toDate,
    format,
  ],
  systemLog: ({ orderBy, filters, pageLimit }) => [
    baseKey,
    "systemLog",
    orderBy,
    filters,
    pageLimit,
  ],
};

export const useAuditGetUrlQuery = ({ scenarioId, limit }) => {
  return useQuery({
    queryKey: auditKeys.list({ scenarioId, limit }),
    queryFn: () => queryFetch(AuditAPI.getUrl(scenarioId, limit)),
  });
};

export const useAuditGetUsageQuery = ({ fromDate, toDate, format }) => {
  return useQuery({
    queryKey: auditKeys.usage({ fromDate, toDate, format }),
    queryFn: () => queryFetch(AuditAPI.getUsage(fromDate, toDate, format)),
  });
};

export const useAuditGetSystemLogQuery = ({ orderBy, filters, pageLimit }) => {
  const queryClient = useQueryClient();
  const { data, fetchNextPage, isPending, isFetching } = useInfiniteQuery({
    queryKey: auditKeys.systemLog({
      orderBy,
      filters,
      pageLimit,
    }),
    initialPageParam: 1,
    queryFn: ({ pageParam, signal }) =>
      queryFetch(
        AuditAPI.getSystemLog({
          filters,
          limit: pageLimit,
          offset: (pageParam - 1) * pageLimit,
          orderby: orderBy,
        }).url,
        { signal }
      ),
    placeholderData: keepPreviousData,
    select: useCallback((data) => {
      return data.pages.flat().map((s, idx) => ({
        ...s,
        id: s.id + idx,
        index: idx,
      }));
    }, []),
    retry: false,
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < pageLimit ? undefined : pages.length + 1,
  });

  const cancel = useCallback(
    () =>
      queryClient.cancelQueries({
        queryKey: auditKeys.systemLog({
          orderBy,
          filters,
          pageLimit,
        }),
      }),
    [filters, orderBy, pageLimit, queryClient]
  );

  const containerRef = useInfiniteTableContainer(
    !isFetching && data,
    fetchNextPage
  );

  return {
    data,
    cancel,
    containerRef,
    isPending,
    isFetching,
    fetchNextPage,
  };
};
